import {
    CustomAlert,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@enerbit/base";
import { StyledTableHeader } from "../../../../components/StyledTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { SB_EVENTS_TABLE_HEADERS } from "../../common/device";

const Events = () => {
    const events = useSelector((state: RootState) => state.sbEvents.events);
    const error = useSelector((state: RootState) => state.sbEvents.error);
    return (
        <>
            {error ? (
                <CustomAlert
                    text="Error cargando eventos del smartBit"
                    severity="error"
                    onClose={() => {}}
                />
            ) : (
                <TableContainer
                    component={Paper}
                    sx={{ boxShadow: "unset !important" }}
                >
                    <Table>
                        <TableHead>
                            <StyledTableHeader>
                                {SB_EVENTS_TABLE_HEADERS.map((item, index) => (
                                    <TableCell key={`${index + 1}-${item}`}>
                                        {item}
                                    </TableCell>
                                ))}
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody>
                            {events.map((events, index) => (
                                <TableRow
                                    key={index + 1}
                                    sx={{
                                        "& .MuiTableCell-root": {
                                            borderBottom: "unset !important",
                                            padding: "7px !important",
                                        },
                                    }}
                                >
                                    <TableCell>{events.name}</TableCell>
                                    <TableCell>{events.date}</TableCell>
                                    <TableCell>{events.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default Events;

import { Box } from "@enerbit/base";
import { useEffect, useState } from "react";
import OrdersHistory from "./OrdersHistory";
import { StyledTab } from "../../../../components/Tabs/StyledTab";
import { StyledTabs } from "../../../../components/Tabs/StyledTabs";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getOrders } from "../../../../store/thunks/orders";
import TableSkeleton from "../../../../components/Table/TableSkeleton";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const DetailTabs = () => {
    const [value, setValue] = useState<number>(0);

    const { frtDetail } = useSelector((state: RootState) => state.frontiers);
    const { loading } = useSelector((state: RootState) => state.ordersHistory);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(
            getOrders(
                frtDetail?.measurement_point_id || frtDetail?.lead_id || "",
            ),
        );
    }, []);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs value={value} onChange={handleChange}>
                    <StyledTab
                        label={<Box>Historial de órdenes de servicio</Box>}
                        {...a11yProps(0)}
                    />
                </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box mt={3} pb={3}>
                    {loading && <TableSkeleton rows={5} columns={3} />}
                    {!loading && <OrdersHistory />}
                </Box>
            </TabPanel>
        </Box>
    );
};

export default DetailTabs;

import { api } from "@enerbit/base";

export const authenticateFile = async (url: string) => {
    try {
        const { data } = await api.get<{ url: string }>(
            "/conversion/storage/sas",
            {
                params: { url_path: url },
            },
        );

        return data.url;
    } catch (_error) {
        throw new Error("ErrAuthenticatingFile");
    }
};

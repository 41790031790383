import { PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import Index from "../pages/reusable/CreateClient/Index";
import SectionEnki from "../pages/SectionEnki";
import { store } from "../store/store";

export default function Routers() {
  return (
    <>
      <HashRouter basename="/enki">
        <Routes>
          <Route
            path="/"
            element={
              <Provider store={store}>
                <ThemeConfig>
                  <PrivateRoute>
                    <>
                      <section className="Container-root">
                        <SectionEnki />
                      </section>
                    </>
                  </PrivateRoute>
                </ThemeConfig>
              </Provider>
            }
          />
          <Route
            path="create"
            element={
              <Provider store={store}>
                <ThemeConfig>
                  <PrivateRoute>
                    <>
                      <section className="Container-root">
                        <Index />
                      </section>
                    </>
                  </PrivateRoute>
                </ThemeConfig>
              </Provider>
            }
          />
        </Routes>
      </HashRouter>
    </>
  );
}

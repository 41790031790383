import { Box, styled } from "@enerbit/base";

interface Props {
    bgcolor?: string;
}

const ConnectionIcon = styled(Box)<Props>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    borderRadius: "12px",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

export default ConnectionIcon;

import {
    Accordion,
    AccordionSummary,
    Box,
    CircularProgress,
    CustomAlert,
    enerbitColors,
    Grid,
    InputLabel,
    SxProps,
    Typography,
} from "@enerbit/base";
import { SyntheticEvent, useEffect, useState } from "react";
import { Agenda } from "../../../models";
import { searchAgendasService } from "../../../services";
import { agendaStatus } from "../../list/components/AgendaTable";
import StatusTag from "../../../components/container/status-tag";
import AccordionButton from "../../agenda-detail/components/AccordionButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";

interface Props {
    processId: string;
}

const ProcessAgenda = ({ processId }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [agenda, setAgenda] = useState<Agenda | null>(null);
    const [expanded, setExpanded] = useState<number | null>(null);
    const [index, _setIndex] = useState<number>(0);

    const handleChange =
        (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : null);
        };

    const accordionSummaryStyles: SxProps = {
        // background: "#fff",
        borderRadius: expanded === index ? "4px 4px 0 0" : "4px",
        height: "auto !important",
        p: 1,
        border: `1px solid ${enerbitColors.primary[200]}`,
        // minHeight: "70px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
        },
    };

    const accordionDetailsStyles: SxProps = {
        p: 1,
        px: 2,
        border:
            expanded === index
                ? `1px solid ${enerbitColors.primary[200]}`
                : null,
        borderRadius: "0 0 4px 4px",
    };

    const fetchAgenda = async () => {
        setError(false);
        setLoading(true);
        try {
            const agenda = await searchAgendasService({
                page: 0,
                filters: { process_id: processId },
            });
            if (agenda.items.length > 0) {
                setAgenda(agenda.items[0]);
            }
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAgenda();
    }, [processId]);

    const renderStatus = (agenda: Agenda) => {
        const status = agendaStatus[agenda.status];
        return (
            <StatusTag fontcolor={status.font} bgcolor={status.bgcolor}>
                {status.label}
            </StatusTag>
        );
    };

    return (
        <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
                my: 2,
                boxShadow: "unset !important",
                borderRadius: "16px !important",
            }}
        >
            <AccordionSummary
                expandIcon={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        {agenda && !loading && !error && renderStatus(agenda)}
                        <AccordionButton expanded={expanded === index} />
                    </Box>
                }
                sx={accordionSummaryStyles}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                    }}
                >
                    <CalendarMonthIcon color="primary" />
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "16px",
                        }}
                    >
                        Agenda
                    </Typography>
                    {/* {agenda && !loading && !error && renderStatus(agenda)} */}
                </Box>
            </AccordionSummary>
            <AccordionSummary sx={accordionDetailsStyles}>
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {error && (
                    <CustomAlert
                        severity="error"
                        text="Error cargando agenda."
                        onClose={() => setError(false)}
                    />
                )}
                {!loading && !error && agenda && (
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Nombre
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={agenda.name}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={agenda.name} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Número de teléfono
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={`${agenda.phone_country_code} ${agenda.phone_number}`}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton
                                            text={`${agenda.phone_country_code} ${agenda.phone_number}`}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Correo electrónico
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={agenda.email}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={agenda.email} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Fecha agendada
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={moment(agenda.send_date).format(
                                    "YYYY-MM-DD HH:mm",
                                )}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton
                                            text={moment(
                                                agenda.send_date,
                                            ).format("YYYY-MM-DD HH:mm")}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </AccordionSummary>
        </Accordion>
    );
};

export default ProcessAgenda;

{
    /* <SectionContainer>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontSize: "16px",
                    }}
                >
                    Agenda
                </Typography>
                {agenda && !loading && !error && renderStatus(agenda)}
            </Box>
            <Divider sx={{ my: "12px", mx: "-12px" }} />
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    severity="error"
                    text="Error cargando agenda."
                    onClose={() => setError(false)}
                />
            )}
            {!loading && !error && agenda && (
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={12} md={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Nombre
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={agenda.name}
                            InputProps={{
                                endAdornment: <CopyButton text={agenda.name} />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Número de teléfono
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={`${agenda.phone_country_code} ${agenda.phone_number}`}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton
                                        text={`${agenda.phone_country_code} ${agenda.phone_number}`}
                                    />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Correo electrónico
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={agenda.email}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={agenda.email} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Fecha agendada
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={moment(agenda.send_date).format(
                                "YYYY-MM-DD HH:mm",
                            )}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton
                                        text={moment(agenda.send_date).format(
                                            "YYYY-MM-DD HH:mm",
                                        )}
                                    />
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </SectionContainer> */
}

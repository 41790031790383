import {
    Box,
    enerbitColors,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from "@enerbit/base";
import { Dispatch, SetStateAction } from "react";
import { EExtraServices, SolarService } from "../../models";
import CloseIcon from "@mui/icons-material/Close";
import SolarIcon from "../../assets/Solar.gif";
import FileContainer from "../container/FileContainer";
import CustomTextField from "../Inputs/CustomTextField";
import CopyButton from "../../pages/process-detail/components/CopyButton";

interface Props {
    setCurrentExtraService: Dispatch<SetStateAction<EExtraServices | null>>;
    solarService: SolarService;
}

const SolafForm = ({ setCurrentExtraService, solarService }: Props) => {
    const onClose = () => {
        setCurrentExtraService(null);
    };
    return (
        <Box
            sx={{
                backgroundColor: enerbitColors.primary[100],
                p: 2,
                borderRadius: "16px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img
                        src={SolarIcon}
                        alt="solar"
                        style={{ width: "60px", height: "60px" }}
                    />
                    <Typography sx={{ fontWeight: 600 }}>
                        Monitoreo solar
                    </Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    defaultChecked={
                                        solarService.has_solar_system
                                    }
                                    disabled
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: "16px" }}>
                                    ¿Esta propiedad cuenta con sistema solar?
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Grid>

                {solarService.has_solar_system && (
                    <>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="documentation">
                                    ¿Cuenta con el certificado de capacidad de
                                    transporte y la documentación de tu sistema
                                    solar?
                                </FormLabel>
                                <RadioGroup
                                    defaultValue={
                                        solarService.solar_info ? true : false
                                    }
                                >
                                    <FormControlLabel
                                        value={true}
                                        disabled
                                        control={<Radio />}
                                        label="Si, tengo la información"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        disabled
                                        control={<Radio />}
                                        label="No la tengo, ayúdeme con mi instalador solar"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {solarService.solar_info && (
                            <Grid item xs={12}>
                                <Box sx={{ mb: 2 }}>
                                    <FileContainer
                                        name="Certificado de capacidad de transporte"
                                        url={
                                            solarService.solar_info
                                                .transport_capacity_url
                                        }
                                        needAuth
                                    />
                                </Box>
                                <Box>
                                    <FileContainer
                                        name="Documentación del sistema solar"
                                        url={
                                            solarService.solar_info
                                                .solar_system_url
                                        }
                                        needAuth
                                    />
                                </Box>
                            </Grid>
                        )}
                        {solarService.solar_installer && (
                            <>
                                <Grid item xs={12}>
                                    <InputLabel>
                                        Número del proyecto o registro
                                    </InputLabel>
                                    <CustomTextField
                                        value={
                                            solarService.solar_installer
                                                .project_Number
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        solarService
                                                            .solar_installer
                                                            .project_Number
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>
                                        Nombre de la empresa
                                    </InputLabel>
                                    <CustomTextField
                                        value={
                                            solarService.solar_installer
                                                .company_name
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        solarService
                                                            .solar_installer
                                                            .company_name
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Nombre de contacto</InputLabel>
                                    <CustomTextField
                                        value={
                                            solarService.solar_installer
                                                .contact_name
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        solarService
                                                            .solar_installer
                                                            .contact_name
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Celular</InputLabel>
                                    <CustomTextField
                                        value={
                                            solarService.solar_installer
                                                .phone_number
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        solarService
                                                            .solar_installer
                                                            .phone_number
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Correo electrónico</InputLabel>
                                    <CustomTextField
                                        value={
                                            solarService.solar_installer.email
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        solarService
                                                            .solar_installer
                                                            .email
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default SolafForm;

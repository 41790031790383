import {
  AdapterMoment,
  Autocomplete,
  Box,
  Button,
  CustomModalTypes,
  DatePicker,
  FormGroup,
  Grid,
  InfoIcon,
  InputLabel,
  LocalizationProvider,
  TextField,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonthWarningLabel,
  getPlanWarningLabel,
  getTitleLabel,
} from "../../../helpers/LabelUtils/labelsModal";
import { StateStorage } from "../../../models/store/StateStorage";
import { getEnerbitElectricitySupplyService } from "../../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import {
  onEnerbitServiceAgreementChangedPlan,
  onEnerbitStartedAtChanged,
} from "../../../store/slices/planManagementSlice";
import { AppDispatch } from "../../../store/store";
import { CardEnerbitServiceAgreement } from "./CardEnerbitServiceAgreement";
import ConfirmChange from "./ConfirmChange";

export type FormChangeModalProps = {
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

export const FormChangeModal = ({ handleClose }: FormChangeModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const createClient = useRef<CustomModalTypes>(null);

  const {
    startedAt,
    enerbitServiceAgreementPlan,
    paginationEnerbitServiceAgreements,
    isLoadingEnerbitServiceAgreements,
  } = useSelector((state: StateStorage) => state.planManagement);

  const { typeChangePlan } = useSelector((state: StateStorage) => state.usages);

  useEffect(() => {
    dispatch(getEnerbitElectricitySupplyService());
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <FormGroup>
        <InputLabel shrink className="Input-label">
          {getTitleLabel(typeChangePlan)}
        </InputLabel>
        <Autocomplete
          id="enerbitElectricitySupplyServiceId"
          fullWidth
          loading={isLoadingEnerbitServiceAgreements}
          options={paginationEnerbitServiceAgreements?.items ?? []}
          value={enerbitServiceAgreementPlan}
          autoHighlight
          getOptionLabel={(option) => option?.name ?? ""}
          renderOption={(props, option, state) => (
            <li {...props} key={option.id}>
              <CardEnerbitServiceAgreement
                backgroundColor="white"
                enerbitServiceAgreement={option}
              />
            </li>
          )}
          onChange={(_e, value) => {
            if (value) {
              dispatch(onEnerbitServiceAgreementChangedPlan(value));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="enerbitElectricitySupplyServiceId"
              className="TextField-without-border-radius"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            marginTop: "17px",
          }}
        >
          <InfoIcon sx={{ marginRight: "12px" }} color="secondary" />
          <Typography variant="subtitle1" color={enerbitColors.primary.main}>
            {getPlanWarningLabel(typeChangePlan)}
          </Typography>
        </Box>
      </FormGroup>
      {enerbitServiceAgreementPlan && (
        <Box sx={{ marginTop: "44px" }}>
          <CardEnerbitServiceAgreement
            enerbitServiceAgreement={enerbitServiceAgreementPlan}
          />
        </Box>
      )}
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel shrink className="Input-label">
          Mes
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            onChange={(value: moment.Moment | null) => {
              if (value) {
                dispatch(
                  onEnerbitStartedAtChanged(moment(value).startOf("month"))
                );
              }
            }}
            views={["month", "year"]}
            openTo="month"
            value={startedAt}
            minDate={moment().startOf("month")}
          />
        </LocalizationProvider>
        <Box
          sx={{
            display: "flex",
            marginTop: "17px",
          }}
        >
          <InfoIcon sx={{ marginRight: "12px" }} color="secondary" />
          <Typography variant="subtitle1" color={enerbitColors.primary.main}>
            {getMonthWarningLabel(typeChangePlan)}
          </Typography>
        </Box>
      </FormGroup>
      <Grid container spacing={4} sx={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={() => {
              if (handleClose) {
                handleClose({}, "backdropClick");
              }
            }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => {
              if (createClient.current) {
                createClient.current.changeModal();
              }
            }}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <ConfirmChange ref={createClient} />
    </Box>
  );
};

import { Box, Grid, Skeleton, Typography, enerbitColors } from "@enerbit/base";
import GeneralInformation from "./components/GeneralInformation/GeneralInformation";
import Header from "./components/Header/Header";
import MetersTree from "./components/MetersTree/MetersTree";
import GeneralInfomationSkeleton from "./components/GeneralInformation/GeneralInfomationSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { getSmarbitDetail } from "../../store/thunks/generalInfo";
import MetersTreeSkeleton from "./components/MetersTree/MetersTreeSkeleton";
import { getAssociatedMeters } from "../../store/thunks/associatedMeters";
import SignalCoverage from "./components/SignalCoverage/SignalCoverage";
import SignalCoverageSkeleton from "./components/SignalCoverage/SignalCoverageSkeleton";
import { getOrders } from "../../store/thunks/ordersHIstory";
import DetailTabs from "./components/DetailTabs/DetailTabs";
import { getEvents } from "../../store/thunks/sbEvents";
import moment from "moment";
import { getDeviceSignal } from "../../store/thunks/signalCoverage";
import { useParams } from "react-router-dom";
import CustomContainer from "../../components/CustomContainer";

const SmartbitDetail = () => {
    const { id } = useParams<{ id: string }>();
    const loadingGeneralInfo = useSelector(
        (state: RootState) => state.generalInfo.loading,
    );
    const loadingMeterTree = useSelector(
        (state: RootState) => state.associatedMeters.loading,
    );
    const loadingCoverage = useSelector(
        (state: RootState) => state.signalCoverage.loading,
    );
    const { since, until } = useSelector((state: RootState) => state.sbEvents);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!id) return;

        dispatch(getSmarbitDetail(id));
        dispatch(getAssociatedMeters(id));
        dispatch(getOrders(id));
        dispatch(getEvents({ id, since, until }));
        dispatch(getDeviceSignal(id));
    }, []);

    return (
        <div>
            <Box mb={2}>
                <Header />
            </Box>
            <Box my={2}>
                {loadingGeneralInfo ? (
                    <GeneralInfomationSkeleton />
                ) : (
                    <GeneralInformation />
                )}
            </Box>
            {loadingMeterTree ? (
                <Skeleton variant="text" sx={{ fontSize: "22px" }} />
            ) : (
                <Typography
                    sx={{
                        fontSize: "22px",
                        color: enerbitColors.primary.main,
                        my: 2,
                    }}
                >
                    Ramificación de los medidores asociados
                </Typography>
            )}
            <Box>
                <Grid container columnSpacing={2}>
                    <Grid item sm={6}>
                        {loadingMeterTree ? (
                            <MetersTreeSkeleton />
                        ) : (
                            <MetersTree />
                        )}
                    </Grid>
                    <Grid item sm={6}>
                        {loadingCoverage ? (
                            <SignalCoverageSkeleton />
                        ) : (
                            <SignalCoverage />
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box my={2}>
                <DetailTabs />
            </Box>
        </div>
    );
};

export default SmartbitDetail;

import { create } from "zustand";
import { Filters } from "../models";

interface ProcessStore {
    filters: Filters;
    setFilters: (value: Filters) => void;
}

export const useProcessStore = create<ProcessStore>()((set) => ({
    filters: {
        public_id: "",
        entrypoint: "",
        status: "",
        has_mobility_service: false,
        has_solar_service: false,
    },
    setFilters: (value: Filters) => {
        set({ filters: value });
    },
}));

import {
    AdapterMoment,
    Box,
    Button,
    CloseIcon,
    CustomAlert,
    DateTimePicker,
    Grid,
    LoadingButton,
    LocalizationProvider,
    MenuItem,
    Modal,
    Select,
    Typography,
} from "@enerbit/base";
import moment from "moment";
import { useState } from "react";
import { restoreSchedules, restoreSchedulesByDeviceName } from "../../services";
import { RESTORE_SCHEDULES_ERRORS, RESTORE_SCHEDULES_TYPES } from "../../const";
import { RestoreSchedulesTypes } from "../../models/frontiers";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { getRestoreSechedulesStatus } from "../../store/thunks/frontiers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

interface Props {
    open: boolean;
    handleClose: () => void;
    meterId: string;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

const RestoreSchedulesModal = ({ meterId, handleClose, open }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);
    const [since, setSince] = useState<string | null>("");
    const [until, setUntil] = useState<string | null>("");
    const [restoreType, setRestoretype] = useState<RestoreSchedulesTypes>(
        RestoreSchedulesTypes.METER,
    );

    const dispatch = useDispatch<AppDispatch>();

    const onClose = () => {
        setLoading(false);
        setError(undefined);
        setSuccess(false);
        setSince(null);
        setUntil(null);
        handleClose();
    };

    const onRestoreSchedules = async () => {
        if (!since || !until) return;

        setLoading(true);
        setError(undefined);
        setSuccess(false);

        try {
            if (restoreType === RestoreSchedulesTypes.METER) {
                await restoreSchedules(
                    meterId,
                    moment(since).format("YYYY-MM-DDTHH:mm:ssZZ"),
                    moment(until).format("YYYY-MM-DDTHH:mm:ssZZ"),
                );
            } else {
                await restoreSchedulesByDeviceName(
                    meterId,
                    moment(since).format("YYYY-MM-DDTHH:mm:ssZZ"),
                    moment(until).format("YYYY-MM-DDTHH:mm:ssZZ"),
                );
            }
            await dispatch(getRestoreSechedulesStatus(meterId));
            setSuccess(true);
        } catch (error: any) {
            if (error.response.status === 423) {
                const errorStatus: string = `${error.response.status}`;
                setError(RESTORE_SCHEDULES_ERRORS[errorStatus]);
            } else {
                setError(RESTORE_SCHEDULES_ERRORS[400]);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (name: string, value: any) => {
        if (name === "since") {
            setSince(value);
        } else {
            setUntil(value);
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            color={"primary"}
                            sx={{ fontSize: "21px", fontWeight: 700 }}
                        >
                            Recuperar datos
                        </Typography>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            className="back-btn"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box my={2}>
                        <Box>
                            <Typography
                                sx={{
                                    paddingBottom: "10px",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    color: "#3D4350",
                                }}
                            >
                                Realizar la recuperación por
                            </Typography>
                            <Select
                                fullWidth
                                value={restoreType}
                                onChange={(event) =>
                                    setRestoretype(
                                        event.target
                                            .value as RestoreSchedulesTypes,
                                    )
                                }
                                sx={{
                                    "& fieldset": {
                                        borderRadius: "12px !important",
                                    },
                                }}
                            >
                                {RESTORE_SCHEDULES_TYPES.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container spacing={1} py={2}>
                                <Grid item md={6} xs={12}>
                                    <Typography
                                        sx={{
                                            paddingBottom: "10px",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            color: "#3D4350",
                                        }}
                                    >
                                        Fecha inicial
                                    </Typography>
                                    <DateTimePicker
                                        disableFuture
                                        ampm={false}
                                        format="YYYY-MM-DD HH:mm"
                                        value={moment(since)}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: null,
                                            seconds: null,
                                        }}
                                        views={["day", "hours"]}
                                        // maxDateTime={moment().toDate()}
                                        minutesStep={60}
                                        onChange={(value) => {
                                            handleDateChange("since", value);
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                sx: {
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    borderColor:
                                                                        "rgba(0, 0, 0, 0.23)",
                                                                },
                                                        },
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography
                                        sx={{
                                            paddingBottom: "10px",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            color: "#3D4350",
                                        }}
                                    >
                                        Fecha final
                                    </Typography>
                                    <DateTimePicker
                                        disableFuture
                                        ampm={false}
                                        format="YYYY-MM-DD HH:mm"
                                        value={moment(until)}
                                        // maxDateTime={moment().toDate()}
                                        minutesStep={60}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: null,
                                            seconds: null,
                                        }}
                                        views={["day", "hours"]}
                                        onChange={(value) => {
                                            handleDateChange("until", value);
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                sx: {
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    borderColor:
                                                                        "rgba(0, 0, 0, 0.23)",
                                                                },
                                                        },
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </Box>
                    {error && (
                        <Box mb={1}>
                            <CustomAlert
                                severity="error"
                                text={error}
                                onClose={() => setError(undefined)}
                            />
                        </Box>
                    )}
                    {success && (
                        <Box mb={1}>
                            <CustomAlert
                                severity="success"
                                text={
                                    "Se ha enviado el comando de recuperación con éxito."
                                }
                                onClose={() => setSuccess(false)}
                            />
                        </Box>
                    )}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="success"
                        sx={{ color: "white" }}
                        loading={loading}
                        disabled={loading}
                        onClick={onRestoreSchedules}
                    >
                        Recuperar
                    </LoadingButton>
                </Box>
            </Modal>
        </>
    );
};

export default RestoreSchedulesModal;

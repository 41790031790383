import {
    Button,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    enerbitColors,
} from "@enerbit/base";
import { DEVICE_OPERATION_STATUS, TABLE_HEADERS } from "../common";
import { StyledTableHeader } from "../../../components/StyledTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import StatusTag from "../../../components/StatusTag";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import moment from "moment";
import { DeviceConnectionState } from "../../../models/smartbit";
import PaginationContainer from "../../../components/PaginationContainer";
import { setCurrentPage } from "../../../store/slices/generalInfo";
import { listSmartBits } from "../../../store/thunks/generalInfo";
import ConnectionIcon from "../../../components/ConnectionIcon";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SignalWifiBadIcon from "@mui/icons-material/SignalWifiBad";
import { StyledTooltip } from "../../../components/StyledTooltip";

const SmartbitsTable = () => {
    const navigate = useNavigate();
    const { currentPage, totalPages, devices } = useSelector(
        (state: RootState) => state.generalInfo,
    );

    const dispatch = useDispatch<AppDispatch>();

    const goToDetail = (id: string) => {
        navigate(`/${id}`);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        event.preventDefault();
        dispatch(setCurrentPage(value - 1));
        dispatch(listSmartBits());
    };

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{ mt: 1, boxShadow: "unset !important" }}
            >
                <Table>
                    <TableHead>
                        <StyledTableHeader>
                            {TABLE_HEADERS.map((item, index) => (
                                <TableCell
                                    align={
                                        index === TABLE_HEADERS.length - 2
                                            ? "center"
                                            : "left"
                                    }
                                    key={index}
                                >
                                    {item}
                                </TableCell>
                            ))}
                        </StyledTableHeader>
                    </TableHead>
                    <TableBody>
                        {devices.map((device) => (
                            <TableRow
                                key={device.device_id}
                                sx={{
                                    "& .MuiTableCell-root": {
                                        borderBottom: "unset !important",
                                        padding: "7px !important",
                                    },
                                }}
                            >
                                <TableCell>{device.device_id}</TableCell>
                                <TableCell>{device.iccid}</TableCell>
                                <TableCell>{device.operator}</TableCell>
                                <TableCell>
                                    {moment(device.last_activity_time)
                                        .utcOffset("-05:00")
                                        .format("YYYY-MM-DD HH:mm")}
                                </TableCell>
                                <TableCell>
                                    <StatusTag
                                        bgcolor={
                                            device.is_active
                                                ? DEVICE_OPERATION_STATUS.active
                                                      .bgcolor
                                                : DEVICE_OPERATION_STATUS
                                                      .inactive.bgcolor
                                        }
                                        fontcolor={
                                            device.is_active
                                                ? DEVICE_OPERATION_STATUS.active
                                                      .fontcolor
                                                : DEVICE_OPERATION_STATUS
                                                      .inactive.fontcolor
                                        }
                                    >
                                        {device.is_active
                                            ? DEVICE_OPERATION_STATUS.active
                                                  .label
                                            : DEVICE_OPERATION_STATUS.inactive
                                                  .label}
                                    </StatusTag>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <StyledTooltip
                                        title={
                                            device.connection_state ===
                                            DeviceConnectionState.CONNECTED
                                                ? "Conectado"
                                                : "Desconectado"
                                        }
                                    >
                                        <ConnectionIcon
                                            bgcolor={
                                                device.connection_state ===
                                                DeviceConnectionState.DISCONNECTED
                                                    ? enerbitColors.error[100]
                                                    : enerbitColors.success[100]
                                            }
                                        >
                                            {device.connection_state ===
                                            DeviceConnectionState.CONNECTED ? (
                                                <SignalWifi4BarIcon
                                                    sx={{
                                                        color: enerbitColors
                                                            .success.main,
                                                        fontSize: "18px",
                                                    }}
                                                />
                                            ) : (
                                                <SignalWifiBadIcon
                                                    sx={{
                                                        color: enerbitColors
                                                            .error.main,
                                                        fontSize: "18px",
                                                    }}
                                                />
                                            )}
                                        </ConnectionIcon>
                                    </StyledTooltip>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            goToDetail(device.device_id)
                                        }
                                        sx={{
                                            minWidth: "unset !important",
                                            minHeight: "unset !important",
                                            width: "32px",
                                            height: "32px",
                                            borderRadius: "12px",
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationContainer>
                <Stack spacing={2}>
                    <Pagination
                        page={currentPage + 1}
                        count={totalPages}
                        shape="rounded"
                        onChange={handleChange}
                    />
                </Stack>
            </PaginationContainer>
        </>
    );
};

export default SmartbitsTable;

import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setEmptyMessage } from "../slices/reports";
import { XMReportsModel, XMResultModel } from "../../models/reports";
import { getReportAttemps } from "../../services";
import { getLastReportAttemp, sortReportResultsByState } from "../../helpers";
import { IPaginatedResponse } from "../../models/pagination";

export const getAsicReports = createAsyncThunk(
    "reports/getAsicReports",
    async (
        { currentPage, date }: { date: string; currentPage: number },
        thunkApi,
    ) => {
        const { rejectWithValue } = thunkApi;

        const params: Record<string, unknown> = { page: currentPage, size: 10 };

        try {
            const res = await api.get<IPaginatedResponse<XMReportsModel>>(
                `/electricity-supply-service/device/asic-reports/since/${date}/until/${date}/`,
                { params },
            );

            if (res.data.items.length === 0) {
                thunkApi.dispatch(
                    setEmptyMessage(
                        `No se encontraron reportes para la fecha ${date}`,
                    ),
                );
            }

            const data = res.data.items.map(async (item) => {
                const reportAttemps = await getReportAttemps(item.id);
                return {
                    ...item,
                    last_attemp: getLastReportAttemp(reportAttemps),
                };
            });

            const reports = await Promise.all(data);
            return {
                reports,
                totalPages: res.data.pages,
                currentPage: res.data.page,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getReportDetail = createAsyncThunk(
    "reports/getReportDetail",
    async (asicCode: string) => {
        const res = await api.get<XMResultModel>(
            `/electricity-supply-service/device/asic-report/process-result/${asicCode}/xm-soap-service/`,
        );

        const data = {
            ...res.data,
            Results: {
                BorderResult: res.data.Results.BorderResult.sort(
                    sortReportResultsByState,
                ),
            },
        };

        return data;
    },
);

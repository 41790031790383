import {
    Box,
    enerbitColors,
    Grid,
    InputLabel,
    Typography,
} from "@enerbit/base";
import { Process } from "../../../models";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { documentTypesNames } from "../../../common";
import FileContainer from "../../../components/container/FileContainer";
import moment from "moment";

interface Props {
    process: Process;
}

const normalizeDate = (date: string) => {
    return moment(date).format("YYYY-MM-DD");
};

const IdentityForm = ({ process }: Props) => {
    return (
        <Box>
            {(!process.form_data.step2 ||
                Object.entries(process.form_data.step2).length === 0) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step2 &&
                Object.entries(process.form_data.step2).length > 0 && (
                    <Box>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Tipo documento de identidad
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        documentTypesNames[
                                            process.form_data.step2.doc_type
                                        ]
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    documentTypesNames[
                                                        process.form_data.step2
                                                            .doc_type
                                                    ]
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Número documento de identidad
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2
                                            .document_identity.number
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .document_identity
                                                        .number
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Nombres
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2
                                            .document_identity.first_name
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .document_identity
                                                        .first_name
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Apellidos
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        process.form_data.step2
                                            .document_identity.last_name
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .document_identity
                                                        .last_name
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Correo electrónico
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={process.form_data.step2.email}
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .email
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Tipo de persona
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={process.form_data.step2.person_type}
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    process.form_data.step2
                                                        .person_type
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            {process.form_data.step2.document_identity.url && (
                                <Grid item xs={12}>
                                    <Box>
                                        <InputLabel
                                            sx={{ mb: 1, fontSize: "14px" }}
                                        >
                                            Documento de identidad
                                        </InputLabel>
                                        <FileContainer
                                            name="Documento de identidad"
                                            url={
                                                process.form_data.step2
                                                    .document_identity.url
                                            }
                                            needAuth
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}

            {process.form_data.step2 &&
                process.form_data.step2.legal_documents && (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontSize: "16px",
                                    my: 1,
                                }}
                            >
                                Certificado cámara de comercio
                            </Typography>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Fecha
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={normalizeDate(
                                            process.form_data.step2
                                                .legal_documents
                                                .certificate_commerce
                                                .date_issue,
                                        )}
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={normalizeDate(
                                                        process.form_data.step2
                                                            .legal_documents
                                                            .certificate_commerce
                                                            .date_issue,
                                                    )}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <InputLabel
                                            sx={{ mb: 1, fontSize: "14px" }}
                                        >
                                            Documento
                                        </InputLabel>
                                        <FileContainer
                                            name="Certificado cámara de comercio"
                                            url={
                                                process.form_data.step2
                                                    .legal_documents
                                                    .certificate_commerce.url
                                            }
                                            needAuth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontSize: "16px",
                                    my: 1,
                                }}
                            >
                                RUT
                            </Typography>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Dirección
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            process.form_data.step2
                                                .legal_documents.rut.address
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        process.form_data.step2
                                                            .legal_documents.rut
                                                            .address
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Actividad económica
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            process.form_data.step2
                                                .legal_documents.rut
                                                .economic_activity
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        process.form_data.step2
                                                            .legal_documents.rut
                                                            .economic_activity
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        NIT
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            process.form_data.step2
                                                .legal_documents.rut.nit
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        process.form_data.step2
                                                            .legal_documents.rut
                                                            .nit
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Fecha de vigencia
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={normalizeDate(
                                            process.form_data.step2
                                                .legal_documents.rut
                                                .effective_date,
                                        )}
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={normalizeDate(
                                                        process.form_data.step2
                                                            .legal_documents.rut
                                                            .effective_date,
                                                    )}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <InputLabel
                                            sx={{ mb: 1, fontSize: "14px" }}
                                        >
                                            Documento
                                        </InputLabel>
                                        <FileContainer
                                            name="RUT"
                                            url={
                                                process.form_data.step2
                                                    .legal_documents.rut.url
                                            }
                                            needAuth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
        </Box>
    );
};

export default IdentityForm;

import { Box, Grid, LoadingButton, Typography, moment } from "@enerbit/base";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useEffect, useState } from "react";
import type { IDataCertificate } from "../../interfaces";
import { authenticateUrl } from "../../services";
import { getMpByAssignee } from "../../services/assignees";
import { CustomCardNeutral } from "./CardNeutral.styled";

interface IProps {
    name: string;
    certificate: IDataCertificate;
}

const CertificateCards = ({ name, certificate }: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentMp, setCurrentMp] = useState<string>("");

    const getUrl = async () => {
        setIsLoading(true);
        try {
            const { url } = await authenticateUrl(certificate.url);
            window.open(url, "_blank");
        } catch (err) {
            return err;
        } finally {
            setIsLoading(false);
        }
    };

    const getMp = async () => {
        try {
            const res = await getMpByAssignee(certificate?.assignee_id!);
            setCurrentMp(res);
        } catch (_err) {
            setCurrentMp("No registra");
        }
    };

    useEffect(() => {
        getMp();
    }, []);
    return (
        <CustomCardNeutral>
            <Grid container alignItems="center">
                <Grid item>
                    <Box sx={SquareStyle}>
                        <Typography color="inherit" fontWeight="bold">
                            PDF
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box ml={2}>
                        <Typography fontWeight="bold">{name}</Typography>
                        <Typography color="neutral.400">
                            Order: {certificate.order_id}
                        </Typography>
                        <Typography color="neutral.400">
                            ID: {certificate.id}
                        </Typography>
                        <Typography color="neutral.400">
                            Fecha:{" "}
                            {moment(certificate?.date).format("YYYY-MM-DD")}
                        </Typography>
                        <Typography color="neutral.400">
                            Mp:{currentMp || "No registra"}
                        </Typography>
                        {certificate.order && (
                            <Typography color="neutral.400">
                                Actividad: {certificate?.order.order_type?.name}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box display="flex" justifyContent="flex-end">
                        <LoadingButton
                            variant="contained"
                            loading={isLoading}
                            onClick={getUrl}
                            disabled={!certificate.url}
                        >
                            <LaunchOutlinedIcon />
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </CustomCardNeutral>
    );
};

//---------------Styles---------------//

const SquareStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    bgcolor: "error.main",
    px: 1.5,
    py: 2,
    borderRadius: "5px",
    color: "#FFF",
};

export default CertificateCards;

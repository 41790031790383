import { createSlice } from "@reduxjs/toolkit";
import { DeviceSignalState } from "../../models/smartbit";
import { getDeviceSignal } from "../thunks/signalCoverage";

const initialState: DeviceSignalState = {
    loading: false,
    error: false,
    signal: [],
    operator: "",
};

export const signalCoverageSlice = createSlice({
    name: "signalCoverage",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getDeviceSignal.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(getDeviceSignal.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.signal = payload.signal;
            state.operator = payload.operator;
        });
        builder.addCase(getDeviceSignal.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export const {} = signalCoverageSlice.actions;

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
    ArrowForwardIcon,
    Button,
    Checkbox,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
} from "@enerbit/base";
import "./FustigationsTable.css";
import { ChangeEvent, useMemo } from "react";
import { getFustigations } from "../../store/thunks/fustigations";
import PaginationContainer from "../PaginationContainer/PaginationContainer";
import {
    appendFustigation,
    setCurrentPage,
    updateSelectedFustigations,
} from "../../store/slices/fustigations";
import { FailedFustigations } from "../../models/fustigations";
import { navigateToUrl } from "single-spa";

const TABLE_HEADERS: string[] = [
    "Punto de medida",
    "Serial del medidor",
    "Frontera",
    "Estado",
    "Detalle",
];

const FustigationsTable = () => {
    const totalPages = useSelector(
        (state: RootState) => state.fustigations.totalPages,
    );
    const currentPage = useSelector(
        (state: RootState) => state.fustigations.currentPage,
    );
    const fustigations = useSelector(
        (state: RootState) => state.fustigations.fustigations,
    );
    const selectedFustigations = useSelector(
        (state: RootState) => state.fustigations.selectedFustigations,
    );
    const since = useSelector((state: RootState) => state.fustigations.since);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        event.preventDefault();
        dispatch(setCurrentPage(value));
        dispatch(getFustigations({ since }));
    };

    const handleCheck = (
        event: ChangeEvent<HTMLInputElement>,
        fustigation: FailedFustigations,
    ) => {
        if (event.target.checked) {
            const temp = [...selectedFustigations];
            temp.push(fustigation);
            dispatch(updateSelectedFustigations(temp));
        } else {
            const temp = selectedFustigations.filter(
                (item) => item.meter_serial !== fustigation.meter_serial,
            );
            dispatch(updateSelectedFustigations(temp));
        }
    };

    const checkAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            fustigations.map((item) => {
                const exists = selectedFustigations.some(
                    (fustigation) =>
                        fustigation.meter_serial === item.meter_serial,
                );
                if (!exists) {
                    dispatch(appendFustigation(item));
                }
            });
        } else {
            const temp = selectedFustigations.filter(
                (item) =>
                    !fustigations.some(
                        (fustigation) =>
                            fustigation.meter_serial === item.meter_serial,
                    ),
            );
            dispatch(updateSelectedFustigations(temp));
        }
    };

    const checkedAll = useMemo(() => {
        return (
            fustigations.every((item) =>
                selectedFustigations.some(
                    (fustigation) =>
                        fustigation.meter_serial === item.meter_serial,
                ),
            ) && selectedFustigations.length > 0
        );
    }, [fustigations, selectedFustigations]);

    const goToDetail = (serial: string) => {
        navigateToUrl(`/#/reading-records/detail/${serial}`);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    color="secondary"
                                    inputProps={{ "aria-label": "controlled" }}
                                    onChange={checkAll}
                                    checked={checkedAll}
                                />
                            </TableCell>
                            {TABLE_HEADERS.map((item, index) => (
                                <TableCell key={index}>{item}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fustigations.map((fustigation, index) => (
                            <TableRow
                                key={`${index + 1}-${fustigation.measurement_point_id}`}
                            >
                                <TableCell>
                                    <Checkbox
                                        color="secondary"
                                        checked={selectedFustigations.some(
                                            (item) =>
                                                item.meter_serial ===
                                                fustigation.meter_serial,
                                        )}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        onChange={(event) =>
                                            handleCheck(event, fustigation)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    {fustigation.measurement_point_id}
                                </TableCell>
                                <TableCell>
                                    {fustigation.meter_serial}
                                </TableCell>
                                <TableCell>{fustigation.frt_code}</TableCell>
                                <TableCell>
                                    <StatusIndicator
                                        failed={fustigation.judgment_failed}
                                    >
                                        {!fustigation.judgment_failed
                                            ? "Fallida"
                                            : "Exitosa"}
                                    </StatusIndicator>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            goToDetail(fustigation.meter_serial)
                                        }
                                        sx={{
                                            minWidth: "unset !important",
                                            height: "30px",
                                            width: "50px",
                                            borderRadius: "10px !important",
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationContainer>
                <Stack spacing={2}>
                    <Pagination
                        page={currentPage}
                        count={totalPages}
                        shape="rounded"
                        onChange={handleChange}
                    />
                </Stack>
            </PaginationContainer>
        </>
    );
};

export default FustigationsTable;

interface StatusColor {
    failed: boolean;
}

const StatusIndicator = styled("div")<StatusColor>(({ failed }) => ({
    width: "122px",
    height: "27px",
    backgroundColor: failed ? "#D1FADF" : "#FEE4E2",
    color: failed ? "#12B76A" : "#F04438",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
}));

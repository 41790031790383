import React, { useEffect } from "react";
import Searchbar from "./components/Searchbar";
import SmartbitsTable from "./components/SmartbitsTable";
import { Box, Skeleton, Typography, enerbitColors } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import TableSkeleton from "../../components/TableSekeleton";
import { TABLE_HEADERS } from "./common";
import { listSmartBits } from "../../store/thunks/generalInfo";

const SmartbitsList = () => {
    const loading = useSelector(
        (state: RootState) => state.generalInfo.loading,
    );
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(listSmartBits());
    }, []);
    return (
        <div>
            <Typography
                sx={{
                    fontSize: "28px",
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                }}
            >
                smartBits
            </Typography>
            <Box my={2}>
                {loading ? (
                    <Skeleton
                        sx={{
                            borderRadius: "12px",
                            width: "700px",
                            height: "55px",
                        }}
                        variant="rectangular"
                    />
                ) : (
                    <Searchbar />
                )}
            </Box>
            {loading ? (
                <TableSkeleton columns={TABLE_HEADERS.length} rows={12} />
            ) : (
                <SmartbitsTable />
            )}
        </div>
    );
};

export default SmartbitsList;

import { Event } from "../models/events";
import { ISignal } from "../models/smartbit";
import { DEVICE_EVENT_TYPES } from "../pages/SmartbitDetail/common/device";

export const mapSignaNamesToValues = (signalStatus: string) => {
    switch (signalStatus) {
        case "EXCELENTE":
            return 4;
        case "BUENA":
            return 3;
        case "REGULAR":
            return 2;
        case "DEFICIENTE":
            return 1;
        case "MALA":
            return 0;
        default:
            return 0;
    }
};

export const mapValuesToSignalNames = (value: number) => {
    switch (value) {
        case 4:
            return "Excelente";
        case 3:
            return "Buena";
        case 2:
            return "Regular";
        case 1:
            return "Deficiente";
        case 0:
            return "Mala";
        default:
            return "";
    }
};

export const formatDeviceEventMessage = (event: Event): string => {
    const payload = JSON.parse(event.event_payload);
    switch (event.event_types.code) {
        case "0":
            return (
                DEVICE_EVENT_TYPES[event.event_payload] ?? "Evento desconocido"
            );
        case "1":
            return (
                DEVICE_EVENT_TYPES[event.event_types.event] ??
                "Evento desconocido"
            );
        case "2":
            return `Intervalo de mesaje: ${payload.msg_interval}seg`;
        case "3":
            return (
                DEVICE_EVENT_TYPES[event.event_types.event] ??
                "Evento desconocido"
            );
        case "4":
            return `${payload.vbatt}V`;
        default:
            return "Evento desconocido";
    }
};

export const removeDuplicateHours = (data: ISignal[]) => {
    const latestDataPerMinute: Record<string, any> = {};

    data.forEach((entry) => {
        const timeMinute = entry.time_sender.slice(0, 16);
        if (
            !latestDataPerMinute[timeMinute] ||
            entry.time_sender > latestDataPerMinute[timeMinute].time_sender
        ) {
            latestDataPerMinute[timeMinute] = entry;
        }
    });

    const result: ISignal[] = Object.values(latestDataPerMinute);
    return result;
};

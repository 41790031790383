import { Box, Typography } from "@enerbit/base";
import { generationConventions } from "../../const/Conventions";

interface ICardBox {
    title: string;
    description: JSX.Element | string;
    iconStyles: {
        background?: string;
        border?: string;
        borderRadius: string;
        height: string;
        width: string;
    };
    titleColor?: string;
    isTitle?: boolean;
}

const CardBox = ({
    title,
    description,
    iconStyles,
    titleColor,
    isTitle,
}: ICardBox) => (
    <Box sx={{ mb: "16px" }}>
        <Box
            sx={{
                background: "white",
                padding: "16px 12px",
                borderRadius: "10px",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mr: "10px" }}>
                    <Box sx={iconStyles} />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: titleColor,
                            fontWeight: "bold",
                            fontSize: "16px",
                            pl: isTitle ? "10px" : 0,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography>{description}</Typography>
                </Box>
            </Box>
        </Box>
    </Box>
);

const ConventionCardGeneration = () => (
    <div>
        {generationConventions.map((card, index) => (
            <CardBox
                key={index}
                title={card.title}
                description={card.description}
                iconStyles={card.iconStyles}
                titleColor={card.titleColor}
                isTitle={card.isTitle}
            />
        ))}
    </div>
);

export default ConventionCardGeneration;

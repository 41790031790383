import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  CustomAlert,
  Grid,
  IconButton,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { StateStorage } from "../../models/StateStorage";
import { getMarkets } from "../../store/actions/electricity-market-info/electricity-market-info.actions";
import { getEnerbitElectricitySupplyServiceAgreementsId } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import {
  onEnerbitServiceAgreementChangedPlan,
  setResetVarsUpdatePlans,
} from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import DetailsPlanSkeleton from "../components/skeletons/DetailsPlanSkeleton";
import CustomError from "../custom-error/CustomError";
import DetailsPlan from "./DetailsPlan";
import { ModalChangePlan } from "./ModalChangePlan";
import { ServicesPlan } from "./ServicesPlan";

const SectionDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const {
    errorUpdateChangePlan,
    successUpdateChangePlan,
    detailPlanEnergy,
    isLoadingDetailPlanEnergy,
    errorDetailPlanEnergy,
  } = useSelector((state: StateStorage) => state.makitaState);

  const { errorListPlans } = useSelector(
    (state: StateStorage) => state.calculatorState
  );

  useEffect(() => {
    dispatch(onEnerbitServiceAgreementChangedPlan(null));
    dispatch(setResetVarsUpdatePlans());
    dispatch(getMarkets());
    if (id) {
      dispatch(getEnerbitElectricitySupplyServiceAgreementsId(id));
    }
  }, []);

  useEffect(() => {
    if (errorUpdateChangePlan != "" || successUpdateChangePlan != "") {
      setOpen(false);
    }
  }, [errorUpdateChangePlan, successUpdateChangePlan]);

  return (
    <Box className="Container-makita">
      <Grid container spacing={2}>
        {(errorUpdateChangePlan != "" || successUpdateChangePlan != "") && (
          <CustomAlert
            onClose={() => {
              dispatch(setResetVarsUpdatePlans());
            }}
            sx={{ width: "100%", mt: "1rem" }}
            severity={errorUpdateChangePlan != "" ? "error" : "success"}
            text={
              errorUpdateChangePlan != ""
                ? errorUpdateChangePlan
                : successUpdateChangePlan
            }
          />
        )}
        <Grid item xs={12} md={5}>
          <Box className="Title-description-iva" sx={{ mb: "10px" }}>
            <IconButton sx={{ mr: "10px" }} onClick={() => navigate(-1)}>
              <ArrowCircleLeftOutlinedIcon
                sx={{
                  color: enerbitColors.primary.main,
                  fontSize: 38,
                }}
              />
            </IconButton>
            <Box>
              <Typography
                color={enerbitColors.primary.main}
                fontWeight={700}
                fontSize={28}
              >
                Detalle
              </Typography>
            </Box>
          </Box>
          {errorDetailPlanEnergy ? (
            <CustomError message={errorDetailPlanEnergy || "Unknown error"} />
          ) : isLoadingDetailPlanEnergy ? (
            <DetailsPlanSkeleton />
          ) : (
            detailPlanEnergy && (
              <Box className="Container-box-name-plan">
                <DetailsPlan detailPlanEnergy={detailPlanEnergy} />
              </Box>
            )
          )}
        </Grid>

        {isLoadingDetailPlanEnergy
          ? null
          : detailPlanEnergy && (
              <Grid item xs={12} md={7}>
                <ServicesPlan
                  detailPlanEnergy={detailPlanEnergy}
                  openModal={() => setOpen(true)}
                />
              </Grid>
            )}
        <ModalChangePlan
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />
      </Grid>
    </Box>
  );
};

export default SectionDetail;

import Papa from "papaparse";
import { IEnergyAccumulate } from "../models/usages/Usages";

export interface DownloadFileModel {
    data: string;
    fileName: string;
    fileType: string;
}

export const createDownloadLink = ({
    data,
    fileName,
    fileType,
}: DownloadFileModel) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const downloadUsages = (usages: IEnergyAccumulate[]) => {
    const formattedData = usages.map((item) => ({
        "Energía total generada en Wh": item.solar_active_energy_exported + "",
        "Energía consumida de la red en Wh":
            item.conventional_active_energy_imported + "",
        "Energía exportada a la red en Wh":
            item.conventional_active_energy_exported + "",
        "Energía autosonsumida del sistema solar en Wh":
            item.active_energy_selfconsumption + "",
        "Intervalo inicial": item.start + "",
        "intervalo final": item.end + "",
    }));

    if (formattedData.length > 0) {
        const headers = Object.keys(formattedData[0]);
        downloadFileCSV(formattedData, "Reporte de consumos.csv", headers);
    }
};

export const downloadFileCSV = (
    data: unknown[],
    fileName: string,
    headers: string[],
) => {
    const csv = Papa.unparse({
        fields: headers,
        data: data,
    });

    createDownloadLink({
        data: "\uFEFF" + csv,
        fileName,
        fileType: "text/csv;charset=utf-8;",
    });
};

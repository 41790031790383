import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { User } from "../../models/billing/billing";

import { getServerErrorMessage } from "@enerbit/base/common/utils/serverErrorMessages";
import { IBilling, ITotalOwe } from "../../models/interfaces/IBilling";
import {
  ErrorPayload,
  SuccessPayload,
} from "../../models/store/payload-types/payloadTypes";
import { BillingState } from "../../models/store/states/BillingState";
import {
  downloadInvoiceGroup,
  getInvoices,
  getTotalOwed,
} from "../actions/billing/billing.actions";

const initialState: BillingState = {
  billing: {},
  isLoadingInvoices: false,
  billingsArray: [] as User[],
  selectedBilling: {} as User,
  totalOWed: undefined,
  isLoadingTotalOwed: false,
  errorTotalOwed: null,
  isLoadingDocumentGroupDownloaded: false,
  essId: "",
  yearFilter: "",
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setSelectedBilling: (state, action: PayloadAction<User>) => {
      state.selectedBilling = action.payload;
    },
    setEssId: (state, action) => {
      state.essId = action.payload;
    },
    setYearFilter: (state, action: PayloadAction<string>) => {
      state.yearFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.isLoadingInvoices = true;
        state.billing = {};
      })
      .addCase(getInvoices.rejected.type, (state) => {
        state.isLoadingInvoices = false;
      })
      .addCase(
        getInvoices.fulfilled.type,
        (state, action: PayloadAction<IBilling>) => {
          state.isLoadingInvoices = false;
          state.billing = action.payload;
        }
      )
      .addCase(getTotalOwed.pending, (state) => {
        state.isLoadingTotalOwed = true;
        state.errorTotalOwed = null;
      })
      .addCase(
        getTotalOwed.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingTotalOwed = false;
          const customErrorMessages = {
            404: "Error consultando deuda a la fecha",
            500: "Error del servidor. No se encontro deuda a la fecha",
          };
          state.errorTotalOwed = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      )
      .addCase(
        getTotalOwed.fulfilled.type,
        (state, action: PayloadAction<SuccessPayload<ITotalOwe>>) => {
          state.isLoadingTotalOwed = false;
          state.totalOWed = action.payload.data;
        }
      )
      .addCase(downloadInvoiceGroup.pending, (state) => {
        state.isLoadingDocumentGroupDownloaded = true;
      })
      .addCase(downloadInvoiceGroup.rejected.type, (state) => {
        state.isLoadingDocumentGroupDownloaded = false;
      })
      .addCase(
        downloadInvoiceGroup.fulfilled.type,
        (state, _action: PayloadAction<ITotalOwe>) => {
          state.isLoadingDocumentGroupDownloaded = false;
        }
      );
  },
});
export const { setSelectedBilling, setEssId, setYearFilter } =
  billingSlice.actions;
export default billingSlice.reducer;

import { Box, SxProps, Theme, Typography } from "@enerbit/base";
import { convertToEnergy } from "../../classes/Energy";
import { getNetaEnergy } from "../../utils/Usages";

export const Tooltip = ({
    value,
    label,
    color,
}: { value: number; label: string; color: string }) => (
    <>
        <Typography
            sx={{
                color: "#667085",
                fontWeight: "bold",
                lineHeight: "15px",
                textAlign: "center",
            }}
        >
            {Math.round(convertToEnergy({ value, fixedUnit: "kWh" }).value)} kWh
        </Typography>
        <Typography sx={{ color, fontWeight: "bold", lineHeight: "15px" }}>
            {label}
        </Typography>
    </>
);

export const ArrowSection = ({
    arrow,
    isLeft = false,
    value,
    text,
    color,
}: {
    arrow: string;
    isLeft?: boolean;
    value: number;
    text: string | JSX.Element;
    color: string;
}) => (
    <>
        {!isLeft && <img src={arrow} alt="flecha2" />}
        <p
            style={{
                textAlign: "center",
                marginTop: "0px",
            }}
        >
            <Typography
                sx={{
                    color: "#667085",
                    fontWeight: "bold",
                    fontSize: "15px",
                    lineHeight: "8px",
                }}
            >
                {Math.round(convertToEnergy({ value, fixedUnit: "kWh" }).value)}{" "}
                kWh
            </Typography>
            <Typography
                sx={{
                    color: color,
                    fontWeight: "bold",
                    fontSize: "15px",
                }}
            >
                {text}
            </Typography>
        </p>
        {isLeft && <img src={arrow} alt="flecha2" />}
    </>
);

export const WrapperArrow = ({
    children,
    sx,
}: { children: React.ReactNode; sx: SxProps<Theme> }) => (
    <Box
        sx={{
            ...sx,
            display: "flex",
            flex: 3,
            position: "relative",
            top: "-15px",
        }}
    >
        {children}
    </Box>
);

export const ImageCard = ({ image }: { image: string }) => {
    return (
        <Box
            sx={{
                "& img": {
                    animation: "pulse 2s infinite ease-in-out",
                },
                "@keyframes pulse": {
                    "0%, 100%": {
                        transform: "scale(1)",
                    },
                    "50%": {
                        transform: "scale(0.9)",
                    },
                },
            }}
        >
            <img src={image} alt="image" />
        </Box>
    );
};

export const NetaEnergy = ({
    conventionalActiveEnergyImported,
    conventionalActiveEnergyExported,
}: {
    conventionalActiveEnergyImported: number;
    conventionalActiveEnergyExported: number;
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                flex: 1,
            }}
        >
            <p
                style={{
                    textAlign: "center",
                    marginTop: "0px",
                }}
            >
                <Typography
                    sx={{
                        color: "#667085",
                        fontWeight: "bold",
                        fontSize: "15px",
                        lineHeight: "8px",
                    }}
                >
                    {getNetaEnergy(
                        conventionalActiveEnergyImported,
                        conventionalActiveEnergyExported,
                    )}{" "}
                    kWh
                </Typography>
                <Typography
                    sx={{
                        color: "#2d9bb2",
                        fontWeight: "bold",
                        fontSize: "15px",
                    }}
                >
                    Energía Neta
                </Typography>
            </p>
        </Box>
    );
};

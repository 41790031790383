import { ServiceAgreementType } from "./HelpersPlanAdministration";

/**
 * Obtiene la etiqueta correspondiente para un tipo de acuerdo de servicio.
 * @param serviceAgreementType - El tipo de acuerdo de servicio.
 * @returns La etiqueta correspondiente para el tipo de acuerdo de servicio.
 */
export const getLabelServiceAgreementType = (
  serviceAgreementType: ServiceAgreementType
) => {
  switch (serviceAgreementType) {
    case ServiceAgreementType.fijabit:
      return "Fija";

    case ServiceAgreementType.dinabit:
      return "Variable";
  }
};

import { Dispatch, SetStateAction } from "react";
import { EExtraServices } from "../../models";
import { Box, Button, enerbitColors, Typography } from "@enerbit/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SolarIcon from "../../assets/Solar.gif";
import MobilityIcon from "../../assets/Mobility.gif";

const serviceIcons: Record<string, JSX.Element> = {
    solar: (
        <img
            src={SolarIcon}
            alt="solar"
            style={{ width: "50px", height: "50px" }}
        />
    ),
    mobility: (
        <img
            src={MobilityIcon}
            alt="movilidad"
            style={{ width: "50px", height: "50px" }}
        />
    ),
};

interface Props {
    service: { name: string; iconName: string };
    onClick: () => void;
    index: number;
    setCurrentExtraService: Dispatch<SetStateAction<EExtraServices | null>>;
    disabled?: boolean;
}

const ExtraServiceItem = ({ service, onClick, disabled }: Props) => {
    const { iconName, name } = service;
    return (
        <Button
            type="button"
            onClick={onClick}
            fullWidth
            disabled={disabled}
            sx={{
                p: 6,
                borderRadius: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                backgroundColor: disabled ? "#F2F4F7" : "#F9F6FF",
                ":hover": { backgroundColor: "#F9F6FF" },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {serviceIcons[iconName]}
                <Typography
                    sx={{
                        fontWeight: 600,
                        color: disabled ? "98A2B3" : enerbitColors.primary.main,
                    }}
                >
                    {name}
                </Typography>
            </Box>
            {!disabled && (
                <KeyboardArrowDownIcon
                    sx={{ fontSize: "28px", color: enerbitColors.primary.main }}
                />
            )}
        </Button>
    );
};

export default ExtraServiceItem;

import * as d3 from "d3";

export const localeEs = d3.timeFormatLocale({
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
    ],
    shortDays: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ],
    shortMonths: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
    ],
});

export const graphicFiltersByEnergy = [
    {
        isTitle: true,
        key: "",
        darkColor: "#E26A04",
        lightColor: "#e26a05",
        label: "• 1. Energía generada:",
    },
    {
        isTitle: false,
        key: "active_energy_selfconsumption",
        darkColor: "#fa7707",
        lightColor: "#fcc390",
        label: "1.1. Energía autoconsumida",
    },
    {
        isTitle: false,
        key: "conventional_active_energy_imported",
        darkColor: "#ef730b",
        lightColor: "#fb8b2a",
        label: "1.2. Energía exportada",
    },
    {
        isTitle: true,
        key: "",
        darkColor: "",
        lightColor: "#53348d",
        label: "• 2. Energía consumida:",
    },
    {
        isTitle: false,
        key: "active_energy_selfconsumption_negative",
        lightColor: "#d9d2e6",
        darkColor: "#8c78b4",
        label: "2.1. Energía autoconsumida",
    },
    {
        isTitle: false,
        key: "conventional_active_energy_exported",
        lightColor: "#b3a5cc",
        darkColor: "#53358e",
        label: "2.2. Energía consumida de la red",
    },
];

export enum GraphicFiltersByTimestamp {
    HOUR = "hour",
    DAY = "day",
    MONTH = "month_start",
}

export const graphicFiltersByTimestamp = [
    { label: "Hora", value: GraphicFiltersByTimestamp.HOUR },
    { label: "Día", value: GraphicFiltersByTimestamp.DAY },
    { label: "Mes", value: GraphicFiltersByTimestamp.MONTH },
];

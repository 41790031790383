import { Box, enerbitColors, InputLabel, Typography } from "@enerbit/base";
import { Process } from "../../../models";
import FileContainer from "../../../components/container/FileContainer";

interface Props {
    process: Process;
}

const Checkout = ({ process }: Props) => {
    return (
        <Box>
            {!process.form_data.step3 && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step3 &&
                process.form_data.step3.service_documents.length > 0 && (
                    <Box>
                        {process.form_data.step3.service_documents[0]
                            .letter_url && (
                            <Box>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Carta cambio de comercializador
                                </InputLabel>
                                <FileContainer
                                    name="Carta cambio de comercializador"
                                    url={
                                        process.form_data.step3
                                            .service_documents[0].letter_url
                                    }
                                    needAuth
                                />
                            </Box>
                        )}
                        {process.form_data.step3.service_documents[0]
                            .offer_url && (
                            <Box sx={{ mt: 3}}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Oferta comercial
                                </InputLabel>
                                <FileContainer
                                    name="Oferta comercial"
                                    url={
                                        process.form_data.step3
                                            .service_documents[0].offer_url
                                    }
                                    needAuth
                                />
                            </Box>
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default Checkout;

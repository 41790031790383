import { NotiProvider, ThemeConfig, initEnviroment } from "@enerbit/base";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

import { CustomContainer } from "./components/common/Container.styled";
import { Router } from "./router";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

const queryClient = new QueryClient();

interface IError {
    error: { message: string };
    resetErrorBoundary: () => void;
}
// Componente de fallback para errores
const ErrorFallback = ({ error, resetErrorBoundary }: IError) => {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Reintentar</button>
        </div>
    );
};

export default function Root() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ThemeConfig>
                <CustomContainer>
                    <QueryClientProvider client={queryClient}>
                        <NotiProvider>
                            <Router />
                        </NotiProvider>
                    </QueryClientProvider>
                </CustomContainer>
            </ThemeConfig>
        </ErrorBoundary>
    );
}

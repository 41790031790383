import {
    AdapterMoment,
    Box,
    Button,
    CloseIcon,
    CustomAlert,
    DatePicker,
    FormHelperText,
    Grid,
    LoadingButton,
    LocalizationProvider,
    MenuItem,
    Modal,
    Switch,
    Typography,
} from "@enerbit/base";
import { Controller, useForm } from "react-hook-form";
import { CreateGeneratingFrontier } from "../../../../models/frontiers";
import "../../../../styles/indecx.css";
import CustomTextField from "../../../../components/Inputs/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { generatingFrontierFormValidation } from "../../../../common/validations";
import CustomSelect from "../../../../components/Inputs/CustomSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useState } from "react";
import { createGenerationFrontier } from "../../../../services/frontiers";
import moment from "moment";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

interface Props {
    open: boolean;
    handleClose: () => void;
}

const defaultValues = {
    export_energy_power_capacity: 0,
    import_frontier_xm_code: "",
    frontier_xm_autogenerator_code: "",
    export_energy_start_datetime: "",
    back_up_agreement: false,
    back_up_agreement_power_capacity: 0,
    autogenerator_type_code: "",
    export_energy_type_code: "",
    export_energy_generation_type_code: "",
};

const GenerationFrontierModal = ({ open, handleClose }: Props) => {
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm<CreateGeneratingFrontier>({
        defaultValues,
        resolver: yupResolver(generatingFrontierFormValidation),
    });

    const {
        autogeneratorTypes,
        exportEnergyGenerationTypes,
        exportEnergyTypes,
    } = useSelector((state: RootState) => state.generationFrontiers);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const onClose = () => {
        reset();
        handleClose();
    };
    const onSubmit = async (data: CreateGeneratingFrontier) => {
        setError(false);
        setSuccess(false);
        setLoading(true);
        try {
            await createGenerationFrontier(data);
            setSuccess(true);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        color={"primary"}
                        sx={{ fontSize: "21px", fontWeight: 700 }}
                    >
                        Nueva frontera generadora
                    </Typography>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        className="back-btn"
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Box mt={2}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    Capacidad energía exportada
                                </Typography>
                                <Controller
                                    control={control}
                                    name="export_energy_power_capacity"
                                    defaultValue={0}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            error={
                                                !!errors.export_energy_power_capacity
                                            }
                                            helperText={
                                                !!errors.export_energy_power_capacity &&
                                                errors
                                                    .export_energy_power_capacity
                                                    .message
                                            }
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    Fecha inicio de exportación
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <Controller
                                        control={control}
                                        name="export_energy_start_datetime"
                                        defaultValue=""
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                name,
                                                ref,
                                            },
                                        }) => (
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                value={moment(value)}
                                                onChange={onChange}
                                                ref={ref}
                                                name={name}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        placeholder: "",
                                                        sx: {
                                                            "& fieldset": {
                                                                borderRadius:
                                                                    "12px",
                                                            },
                                                            "& .MuiInputBase-root":
                                                                {
                                                                    height: "41px",
                                                                },
                                                        },
                                                        error: false,
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    Código XM frontera de importación
                                </Typography>
                                <Controller
                                    control={control}
                                    name="import_frontier_xm_code"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            error={
                                                !!errors.import_frontier_xm_code
                                            }
                                            helperText={
                                                !!errors.import_frontier_xm_code &&
                                                errors.import_frontier_xm_code
                                                    .message
                                            }
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    Código XM frontera de autogeneración
                                </Typography>
                                <Controller
                                    control={control}
                                    name="frontier_xm_autogenerator_code"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            error={
                                                !!errors.frontier_xm_autogenerator_code
                                            }
                                            helperText={
                                                !!errors.frontier_xm_autogenerator_code &&
                                                errors
                                                    .frontier_xm_autogenerator_code
                                                    .message
                                            }
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography>
                                    ¿Cuenta con contrato de energía de respaldo?
                                </Typography>
                                <Controller
                                    control={control}
                                    name="back_up_agreement"
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Switch {...field} color="secondary" />
                                    )}
                                />
                            </Grid>
                            {watch("back_up_agreement") && (
                                <Grid item md={12} xs={12}>
                                    <Typography>
                                        Capacidad del contrato de energia de
                                        respaldo
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name="back_up_agreement_power_capacity"
                                        defaultValue={0}
                                        render={({ field }) => (
                                            <CustomTextField
                                                {...field}
                                                error={
                                                    !!errors.back_up_agreement_power_capacity
                                                }
                                                helperText={
                                                    !!errors.back_up_agreement_power_capacity &&
                                                    errors
                                                        .back_up_agreement_power_capacity
                                                        .message
                                                }
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item md={12} xs={12}>
                                <Typography>Tipo de autogenerador</Typography>
                                <Controller
                                    control={control}
                                    name="autogenerator_type_code"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <CustomSelect
                                            {...field}
                                            error={
                                                !!errors.autogenerator_type_code
                                            }
                                            fullWidth
                                        >
                                            {autogeneratorTypes.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.sui_code}
                                                >
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    )}
                                />
                                {!!errors.autogenerator_type_code && (
                                    <FormHelperText error sx={{ mx: "14px" }}>
                                        {errors.autogenerator_type_code.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography>
                                    Tipo de energía exportada
                                </Typography>
                                <Controller
                                    control={control}
                                    name="export_energy_type_code"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <CustomSelect
                                            {...field}
                                            error={
                                                !!errors.export_energy_type_code
                                            }
                                            fullWidth
                                        >
                                            {exportEnergyTypes.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.sui_code}
                                                >
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    )}
                                />
                                {!!errors.export_energy_type_code && (
                                    <FormHelperText error sx={{ mx: "14px" }}>
                                        {errors.export_energy_type_code.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography>
                                    Tipo de energía generada
                                </Typography>
                                <Controller
                                    control={control}
                                    name="export_energy_generation_type_code"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <CustomSelect
                                            {...field}
                                            error={
                                                !!errors.export_energy_generation_type_code
                                            }
                                            fullWidth
                                        >
                                            {exportEnergyGenerationTypes.map(
                                                (item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.sui_code}
                                                    >
                                                        {item.description}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </CustomSelect>
                                    )}
                                />
                                {!!errors.export_energy_generation_type_code && (
                                    <FormHelperText error sx={{ mx: "14px" }}>
                                        {
                                            errors
                                                .export_energy_generation_type_code
                                                .message
                                        }
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        {error && (
                            <CustomAlert
                                sx={{ mt: 2 }}
                                severity="error"
                                text="Error creando frontera"
                                onClose={() => setError(false)}
                            />
                        )}
                        {success && (
                            <CustomAlert
                                sx={{ mt: 2 }}
                                severity="success"
                                text="Frontera creada correctamente."
                                onClose={() => setError(false)}
                            />
                        )}
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 2 }}
                            fullWidth
                        >
                            Crear frontera
                        </LoadingButton>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
};

export default GenerationFrontierModal;

import { CompanyConfig } from "../models/company";

interface Market {
    code: string;
    name: string;
}

export const markets: Market[] = [
    { code: "2016", name: "EEPSAESP" },
    { code: "2438", name: "EMCALI EICE ESP" },
    { code: "524", name: "ESSA E.S.P" },
    { code: "2103", name: "CODENSA S.A. ESP" },
    { code: "520", name: "CEDENAR S.A. E.S.P." },
    { code: "604", name: "CENS S.A. ESP" },
    { code: "502", name: "CHEC S.A. E.S.P." },
    { code: "500", name: "EBSA ESP" },
    { code: "523", name: "EDEQ  S.A.E.S.P." },
    { code: "1014", name: "ELECTROHUILA S.A. E.S.P." },
    { code: "694", name: "EMEESA" },
    { code: "48307", name: "AIR-E" },
    { code: "536", name: "CELSIA VALLE DEL CAUCA" },
    { code: "637", name: "CETSA E.S.P." },
    { code: "45298", name: "NEUENERGY" },
    { code: "27691", name: "QIENERGY" },
    { code: "23442", name: "CEO" },
    { code: "564", name: "EPM" },
    { code: "20437", name: "ENERTOTAL" },
    { code: "48305", name: "CARIBEMAR" },
    { code: "26641", name: "RENOVATIO TRADING AMERICAS SAS ESP" },
    { code: "2322", name: "VATIA" },
];

export const COMPANY_CONFIG: Record<string, CompanyConfig> = {
    afinia: {
        aliases: ["afinia", "caribemar", "48305"],
        model_id: "afinia-model-test",
        required_fields: [
            "direccion",
            "niu",
            "nic",
            "uso",
            "ultimo_consumo",
            "medidor",
            "grupo_calidad",
            "circuito",
            "nodo",
            "propiedad_activo",
        ],
    },
    enel: {
        aliases: ["enel", "codensa s.a. esp", "2103"],
        model_id: "enel-model-test",
        required_fields: [
            "direccion",
            "nic",
            "uso",
            "ultimo_consumo",
            "medidor",
            "grupo_calidad",
            "circuito",
            "nodo",
            "propiedad_activo",
        ],
    },
    emcali: {
        aliases: ["emcali", "emcali eice esp", "2438"],
        model_id: "emcali-model-test",
        required_fields: ["uso", "ultimo_consumo", "medidor"],
    },
    aire: {
        aliases: ["aire", "air-e", "48307"],
        model_id: "aire-model",
        required_fields: [
            "direccion",
            "niu",
            "nic",
            "uso",
            "ultimo_consumo",
            "medidor",
            "grupo_calidad",
            "circuito",
            "nodo",
            "propiedad_activo",
        ],
    },
    epm: {
        aliases: ["epm", "564"],
        model_id: "epm-model-test",
        required_fields: [
            "niu",
            "nic",
            "uso",
            "ultimo_consumo",
            "medidor",
            "grupo_calidad",
            "circuito",
            "nodo",
        ],
    },
    vatia: {
        aliases: ["vatia", "2322"],
        model_id: "vatia-model-test",
        required_fields: [
            "direccion",
            "niu",
            "nic",
            "uso",
            "ultimo_consumo",
            "medidor",
            "grupo_calidad",
            "circuito",
            "propiedad_activo",
            "nivel_tension",
        ],
    },
};

import { styled } from "@enerbit/base";
import { Box } from "@mui/system";

const CustomContainer = styled(Box)(() => ({
    padding: "16px",
    backgroundColor: "white",
    // border: "1px solid #E4E7EC",
    borderRadius: "12px",
}));

export default CustomContainer;

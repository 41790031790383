import { Box, Typography, enerbitColors } from "@enerbit/base";

export const ConventionCard = ({
    title,
    body,
    color,
}: { title: string; body: string | JSX.Element; color: string }) => {
    return (
        <Box
            sx={{
                mb: "16px",
            }}
        >
            <Box
                sx={{
                    background: "white",
                    padding: "16px 12px",
                    borderRadius: "10px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: "10px" }}>
                        <Box
                            sx={{
                                background: color,
                                borderRadius: "50px",
                                height: "16px",
                                width: "16px",
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral[600],
                                fontWeight: "bold",
                                fontSize: "16px",
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography>{body}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

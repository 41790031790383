export interface Process {
    id: string;
    public_id: string;
    entrypoint: string;
    status: string;
    form_data: ProcessFormData;
    completed_at: null | string;
    created_at: string;
}

export interface ObjectKeyValue {
    [key: string]: unknown;
}

export interface SearchProcessesParams {
    page: number;
    filters?: ObjectKeyValue;
}

export interface ProcessFormData {
    step1: StepOne | null;
    step2: StepTwo | null;
    step3: StepThree | null;
}

export interface StepOne {
    account_id: string;
    buildings: Building[] | null;
    has_all_documents: boolean;
    role: string;
    terms_accepted: boolean;
    total_buildings: number;
}
export interface StepTwo {
    doc_type: string;
    document_identity: DocumentIdentity;
    email: string;
    legal_documents: null | LegalDocuments;
    person_type: string;
}

export interface StepThree {
    service_documents: ServiceDocument[];
    signature: string;
    terms_accepted: boolean;
}

export interface ServiceDocument {
    letter_url: string | null;
    offer_url: string | null;
}

export interface Building {
    address: string;
    apartments: number | null;
    city: string;
    current_network_operator: string | null;
    extra_service: null | ExtraService;
    has_energy_service: boolean;
    invoice: Invoice;
    name: string | null;
    service: string;
    state: string;
}

export interface Invoice {
    circuit_code: string;
    document_url: null | string;
    last_energy_use: string;
    meter_serial: string;
    multiplier_factor: string;
    nic: string;
    niu: string;
    node: string;
    outgoing_marketer: string;
    property_ownership: string;
    quality_group: string;
    stratum_code: string;
}

export interface DocumentIdentity {
    birth_date: string;
    first_name: string;
    last_name: string;
    number: string;
    url: string;
}

export interface ExtraService {
    solar_service: SolarService | null;
    mobility_service: MobilityService | null;
}

export interface SolarService {
    has_solar_system: boolean;
    solar_installer: SolarInstaller | null;
    solar_info: SolarInfo | null;
}

export interface SolarInstaller {
    project_Number: string;
    company_name: string;
    contact_name: string;
    phone_number: string;
    email: string;
}

export interface SolarInfo {
    transport_capacity_url: string;
    solar_system_url: string;
}

export enum EInvoiceType {
    TOGETHER = "together",
    SEPARATED = "separated",
}

export interface MobilityService {
    invoice_type: EInvoiceType;
    has_charger: boolean;
}

export enum EExtraServices {
    SOLAR = "solar",
    MOBILITY = "mobility",
}

export interface LegalDocuments {
    certificate_commerce: CertificateCommerce;
    rut: Rut;
}

export interface CertificateCommerce {
    date_issue: string;
    url: string;
}

export interface Rut {
    address: string;
    economic_activity: string;
    effective_date: string;
    nit: string;
    url: string;
}

import {
  FormControl,
  MenuItem,
  moment,
  Select,
  SelectChangeEvent,
  styled,
} from "@enerbit/base";
import { Moment } from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDatePicker from "../../../../../../common/custom-date-picker/CustomDatePicker";
import { MomentHelpers } from "../../../../../../helpers/moment/MomentHelpers";
import { setPeriodComparison } from "../../../../../../helpers/usages/utilsPeriodComparison";
import { PayloadGetUsages } from "../../../../../../models/payloads/consumption/PayloadGetUsages";
import { StateStorage } from "../../../../../../models/store/StateStorage";
import {
  getPeriodStr,
  Period,
  periodsTableToSelect,
} from "../../../../../../models/usages/Period";
import { getUsages } from "../../../../../../store/actions/usages/usages.actions";
import {
  onChangeSelectUsagesTable,
  onChangeSinceUsagesTable,
  onChangeUntilUsagesTable,
} from "../../../../../../store/slices/usagesSlice";
import { AppDispatch } from "../../../../../../store/store";

const DropdownOptions = () => {
  const { id: electricitySupplyServiceId } = useParams<string>();
  const { selectUsagesTable, dateRangeTable } = useSelector(
    (state: StateStorage) => state.usages
  );
  //Obtener información del servicio de electricidad
  const { service } = useSelector((state: StateStorage) => state.information);

  //Dispatch para llamar acciones del redux
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectUsagesTable === "Hora") {
      //Si la fecha since seleccionada es igual a la fecha actual, seteear el until en la fecha actual, si no setear el until al dia siguiente a las 00:00:00
      if (moment(dateRangeTable.since).isSame(moment(), "day")) {
        dispatch(onChangeUntilUsagesTable(moment()));
      } else {
        dispatch(
          onChangeUntilUsagesTable(
            MomentHelpers.convertToFirstInstantOfDay(
              moment(dateRangeTable.since).add(1, "day")
            )
          )
        );
      }
    }
  }, [dateRangeTable.since]);

  useEffect(() => {
    if (selectUsagesTable === "Hora") {
      const usageParamsObject: PayloadGetUsages = {
        electricity_supply_service_id: electricitySupplyServiceId ?? "",
        since: moment(dateRangeTable.since),
        until: moment(dateRangeTable.until),
        period_str: getPeriodStr(selectUsagesTable),
      };
      dispatch(getUsages(usageParamsObject));
    }
  }, [dateRangeTable.until]);

  useEffect(() => {
    if (selectUsagesTable !== "Hora") {
      const usageParamsObject: PayloadGetUsages = {
        electricity_supply_service_id: electricitySupplyServiceId ?? "",
        since: setPeriodComparison(
          selectUsagesTable,
          moment(dateRangeTable.since)
        ),
        until: moment(dateRangeTable.until),
        period_str: getPeriodStr(selectUsagesTable),
      };
      const now = moment();
      //Si la fecha until es el mes actual, settear el dia y la hora actual en este campo
      if (selectUsagesTable === "Mes") {
        if (moment(dateRangeTable.until).isSame(now, "month")) {
          usageParamsObject.until = now;
        } else {
          usageParamsObject.until = MomentHelpers.convertToLastInstantOfDay(
            moment(dateRangeTable.until).endOf("month")
          );
        }
      }
      if (selectUsagesTable === "Día") {
        if (moment(dateRangeTable.until).isSame(now, "day")) {
          usageParamsObject.until = now;
        } else {
          usageParamsObject.until = MomentHelpers.convertToLastInstantOfDay(
            moment(dateRangeTable.until)
          );
        }
      }
      dispatch(getUsages(usageParamsObject));
    }
  }, [dateRangeTable]);

  return (
    <DropdownsContainer>
      <FormControl
        sx={{ minWidth: selectUsagesTable !== "Hora" ? "30%" : "50%" }}
      >
        <Select
          placeholder="Mes"
          type="text"
          name="period"
          onChange={({ target }: SelectChangeEvent<Period>) => {
            dispatch(
              onChangeSelectUsagesTable({
                period: target.value as Period,
                minDate: moment(service?.started_at),
              })
            );
          }}
          value={selectUsagesTable}
          sx={{ "& fieldset": { borderRadius: "10px" }, width: "100%" }}
          defaultValue={"Mes"}
        >
          {periodsTableToSelect.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{ minWidth: selectUsagesTable !== "Hora" ? "30%" : "50%" }}
      >
        <CustomDatePicker
          value={dateRangeTable.since}
          isLabel={false}
          icon="calendar"
          typePicker={selectUsagesTable}
          onChange={(selectedSinceDate: moment.Moment | null) => {
            if (selectedSinceDate) {
              const convertTypeMomentSelectedSinceDateValue =
                moment(selectedSinceDate);
              dispatch(
                onChangeSinceUsagesTable(
                  convertTypeMomentSelectedSinceDateValue
                )
              );
            }
          }}
          minDate={moment(service?.started_at)}
          maxDate={
            selectUsagesTable === "Mes"
              ? moment(dateRangeTable.until).subtract(1, "month")
              : selectUsagesTable === "Día"
              ? moment().subtract(1, "day")
              : undefined
          }
        />
      </FormControl>
      {selectUsagesTable !== "Hora" && (
        <FormControl sx={{ minWidth: "30%" }}>
          <CustomDatePicker
            value={dateRangeTable.until}
            isLabel={false}
            icon="clock"
            typePicker={selectUsagesTable}
            onChange={(selectedUntilDate: Moment | null) => {
              if (selectedUntilDate) {
                dispatch(onChangeUntilUsagesTable(selectedUntilDate));
              }
            }}
            minDate={
              selectUsagesTable !== "Mes"
                ? moment(dateRangeTable.since).add(1, "day")
                : selectUsagesTable === "Mes"
                ? moment(dateRangeTable.since).add(1, "month")
                : undefined
            }
          />
        </FormControl>
      )}
    </DropdownsContainer>
  );
};

export default DropdownOptions;

const DropdownsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

import React, { useState } from "react";
import useSWR from "swr";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ProcessTableFilters from "./ProcessTableFilters";
import { Filters, IPaginatedResponse, Process } from "../../../models";
import { Alert, api, Box, Button, Chip, esES, Typography } from "@enerbit/base";
import StatusTag from "../../../components/container/status-tag";
import { StyledDataGrid } from "../../../components/common/StyledDataGrid";
import { CustomPagination } from "../../../components/common/CustomPagination";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { useProcessStore } from "../../../store/process";
import { processStatus, filterNames } from "../../../utils/process";

const fetchProcesses = async (
    url: string,
    page: number,
    filters: Filters,
): Promise<IPaginatedResponse<Process>> => {
    const params = {
        size: 10,
        page,
        sort_created_at: "DESC",
        ...filters,
    };
    const { data } = await api.get<IPaginatedResponse<Process>>(url, {
        params,
    });
    return data;
};

const ProcessTable: React.FC = () => {
    const navigate = useNavigate();
    const { filters, setFilters } = useProcessStore((state) => state);
    const [tempFilters, setTempFilters] = useState<Filters>(filters);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const { data, error, isLoading, mutate } = useSWR<
        IPaginatedResponse<Process>
    >(["/conversion/processes", currentPage, filters], (args) => {
        const [url, page, filter] = args as [string, number, Filters];
        return fetchProcesses(url, page, filter);
    });

    const handlePagination = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentPage(value - 1);
    };

    const handleTempFiltersChange = (key: keyof Filters, value: unknown) => {
        setTempFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const resetFilters = () => {
        const defaultFilters: Filters = {
            public_id: "",
            entrypoint: "",
            status: "",
            has_mobility_service: false,
            has_solar_service: false,
        };
        setTempFilters(defaultFilters);
        setFilters(defaultFilters);
        setCurrentPage(0);
        mutate();
    };

    const handleSearch = () => {
        setFilters(tempFilters);
        setCurrentPage(0);
        mutate();
    };

    const columns: GridColDef[] = [
        {
            field: "public_id",
            headerName: "ID Público",
            flex: 1,
            sortable: false,
        },
        {
            field: "entrypoint",
            headerName: "Número de teléfono",
            flex: 1,
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "Fecha de creación",
            flex: 1,
            valueFormatter: (params) =>
                moment(params.value).format("YYYY-MM-DD HH:mm"),
            sortable: false,
        },
        {
            field: "completed_at",
            headerName: "Fecha de completado",
            flex: 1,
            valueFormatter: (params) =>
                params.value
                    ? moment(params.value).format("YYYY-MM-DD HH:mm")
                    : "Sin completar",
            sortable: false,
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            renderCell: (params) => {
                const status = processStatus[params.value as string];
                return (
                    <StatusTag fontcolor={status.font} bgcolor={status.bgcolor}>
                        {status.label}
                    </StatusTag>
                );
            },
            sortable: false,
        },
        {
            field: "actions",
            headerName: "Detalle",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/process/${params.row.id}`)}
                    size="small"
                >
                    Ver detalle
                </Button>
            ),
            sortable: false,
        },
    ];

    const onDeleteFilter = (key: string) => {
        const newFilters = { ...tempFilters };

        if (typeof tempFilters[key] === "string") {
            newFilters[key] = "";
        } else if (typeof tempFilters[key] === "boolean") {
            newFilters[key] = false;
        }

        setTempFilters(newFilters);
        setFilters(newFilters);
        mutate();
    };

    return (
        <Box>
            <Box sx={{ my: 1 }}>
                <ProcessTableFilters
                    filters={tempFilters}
                    handleFiltersChange={handleTempFiltersChange}
                    resetFilters={resetFilters}
                    handleSearch={handleSearch}
                />
                <Box
                    sx={{
                        mt: 2,
                        py: 1,
                        borderRadius: "4px",
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                    }}
                >
                    <Typography>Filtros seleccionados: </Typography>
                    {Object.keys(tempFilters).map((key) => {
                        const allowedFilters = Object.keys(filterNames);
                        if (allowedFilters.includes(key) && tempFilters[key]) {
                            return (
                                <Chip
                                    key={key}
                                    variant="filled"
                                    label={filterNames[key].formatFn(
                                        tempFilters[key],
                                    )}
                                    onDelete={() => {
                                        onDeleteFilter(key);
                                    }}
                                />
                            );
                        }
                    })}
                </Box>
            </Box>
            {isLoading && <TableSkeleton rows={10} columns={9} />}
            {error && <Alert severity="error">Error listando procesos</Alert>}
            {!isLoading && !error && data && (
                <>
                    <Box sx={{ height: 600, width: "100%", my: 1 }}>
                        <StyledDataGrid
                            sx={{
                                "& .MuiDataGrid-virtualScrollerContent": {
                                    height:
                                        data.items.length > 0
                                            ? "auto!important"
                                            : "100px!important",
                                },
                                "& .MuiDataGrid-row": {
                                    minHeight: "52px!important",
                                    py: 1,
                                },
                            }}
                            getRowHeight={() => "auto"}
                            disableVirtualization
                            paginationMode="server"
                            disableColumnMenu
                            rowSpacingType="border"
                            rows={data.items}
                            getRowId={(row) => row.id}
                            rowHeight={100}
                            columns={columns}
                            pageSize={10}
                            loading={isLoading}
                            disableSelectionOnClick
                            autoHeight
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            pagination
                            components={{
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                pagination: {
                                    count: data.pages,
                                    page: currentPage + 1,
                                    onChange: handlePagination,
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProcessTable;

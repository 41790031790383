import {
    Alert,
    api,
    Box,
    Button,
    enerbitColors,
    esES,
    GridColDef,
} from "@enerbit/base";
import { useState } from "react";
import moment from "moment";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { Agenda, Filters, IPaginatedResponse } from "../../../models";
import AgendaTableFilters from "./AgendaTableFilters";
import StatusTag from "../../../components/container/status-tag";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { StyledDataGrid } from "../../../components/common/StyledDataGrid";
import { CustomPagination } from "../../../components/common/CustomPagination";

export const agendaStatus: Record<
    string,
    { label: string; font?: string; bgcolor?: string }
> = {
    pending: {
        font: enerbitColors.warning.main,
        label: "Pendiente",
        bgcolor: enerbitColors.warning[100],
    },
    delivered: {
        font: enerbitColors.success.main,
        label: "Entregado",
        bgcolor: enerbitColors.success[100],
    },
    expired: {
        font: enerbitColors.neutral.main,
        label: "Vencido",
        bgcolor: enerbitColors.neutral[100],
    },
    failed: {
        font: enerbitColors.error.main,
        label: "Fallido",
        bgcolor: enerbitColors.error[100],
    },
    session_already_active: {
        font: "#3B82F6",
        label: "Sesión activa",
        bgcolor: "#EAF0FA",
    },
    in_progress: {
        font: "#3B82F6",
        label: "En progreso",
        bgcolor: "#EAF0FA",
    },
};

export const agendaTypesToName: Record<string, string> = {
    single_property: "Única propiedad",
    multiple_properties: "Múltiples propiedades",
};

const fetchAgendas = async (
    url: string,
    page: number,
    filters: Filters,
): Promise<IPaginatedResponse<Agenda>> => {
    const params = {
        size: 10,
        page,
        sort_created_at: "DESC",
        ...filters,
    };
    const { data } = await api.get<IPaginatedResponse<Agenda>>(url, {
        params,
    });
    return data;
};

const AgendaTable = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState<Filters>({
        email: "",
        phone_number: "",
        name: "",
        process_id: "",
    });

    const [tempFilters, setTempFilters] = useState<Filters>(filters);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const { data, error, isLoading, mutate } = useSWR<
        IPaginatedResponse<Agenda>
    >(["/conversion/agendas", currentPage, filters], (args) => {
        const [url, page, filter] = args as [string, number, Filters];
        return fetchAgendas(url, page, filter);
    });

    const handlePagination = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentPage(value - 1);
    };

    const handleTempFiltersChange = (key: keyof Filters, value: unknown) => {
        setTempFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const resetFilters = () => {
        const defaultFilters: Filters = {
            email: "",
            phone_number: "",
            name: "",
            process_id: "",
        };
        setTempFilters(defaultFilters);
        setFilters(defaultFilters);
        setCurrentPage(0);
        mutate();
    };

    const handleSearch = () => {
        setFilters(tempFilters);
        setCurrentPage(0);
        mutate();
    };

    const columns: GridColDef[] = [
        { field: "name", headerName: "Nombre", flex: 2, sortable: false },
        { field: "email", headerName: "Correo electrónico", flex: 1, sortable: false },
        {
            field: "phone_number",
            headerName: "Número de teléfono",
            flex: 1,
            renderCell: (params) => (
                <>{`${params.row.phone_country_code} ${params.row.phone_number}`}</>
            ),
            sortable: false
        },
        {
            field: "agenda_type",
            headerName: "Tipo",
            flex: 1,
            valueFormatter: (params) => agendaTypesToName[params.value],
            sortable: false
        },
        {
            field: "send_date",
            flex: 1,
            headerName: "Fecha agendada",
            valueFormatter: (params) =>
                moment(params.value).format("YYYY-MM-DD HH:mm"),
            sortable: false
        },
        {
            field: "sent_date",
            headerName: "Fecha de envío",
            flex: 1,
            valueFormatter: (params) =>
                params.value
                    ? moment(params.value).format("YYYY-MM-DD HH:mm")
                    : "Sin enviar",
            sortable: false
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            renderCell: (params) => {
                const status = agendaStatus[params.value];
                return (
                    <StatusTag fontcolor={status.font} bgcolor={status.bgcolor}>
                        {status.label}
                    </StatusTag>
                );
            },
            sortable: false
        },
        {
            field: "actions",
            headerName: "Detalle",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate(`/agenda/${params.row.id}`)}
                >
                    Ver detalle
                </Button>
            )
        },
    ];

    return (
        <Box>
            <Box sx={{ my: 3 }}>
                <AgendaTableFilters
                    filters={tempFilters}
                    handleFiltersChange={handleTempFiltersChange}
                    resetFilters={resetFilters}
                    handleSearch={handleSearch}
                />
            </Box>
            {isLoading && <TableSkeleton rows={10} columns={9} />}
            {error && <Alert severity="error">Error listando agendas</Alert>}
            {!isLoading && !error && data && (
                <>
                    <Box sx={{ height: 600, width: "100%", my: 3 }}>
                        <StyledDataGrid
                            sx={{
                                "& .MuiDataGrid-virtualScrollerContent": {
                                    height:
                                        data.items.length > 0
                                            ? "auto!important"
                                            : "100px!important",
                                },
                                "& .MuiDataGrid-row": {
                                    minHeight: "52px!important",
                                    py: 1,
                                },
                            }}
                            getRowHeight={() => "auto"}
                            disableVirtualization
                            paginationMode="server"
                            disableColumnMenu
                            rowSpacingType="border"
                            rows={data.items}
                            getRowId={(row) => row.id}
                            rowHeight={100}
                            columns={columns}
                            pageSize={10}
                            loading={isLoading}
                            autoHeight
                            disableSelectionOnClick
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            pagination
                            components={{
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                pagination: {
                                    count: data.pages,
                                    page: currentPage + 1,
                                    onChange: handlePagination,
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AgendaTable;

import { Box, Typography } from "@enerbit/base";
import { IEnergyCard } from "../../models/components/EnergyCard";

export const EnergyCard = ({
    primaryColor,
    secondaryColor,
    backgroundTitle,
    textUnderline,
    textUnderlineNext,
    textUnderlineDown,
    textRight,
    textLeft,
    textRightPercent,
    textLeftPercent,
    percentRight,
    percentLeft,
}: IEnergyCard) => {
    return (
        <Box
            sx={{
                width: "40%",
                border: "1px solid #8C78B4",
                borderRadius: "5px",
                mt: "2rem",
            }}
        >
            <Box
                sx={{
                    background: backgroundTitle,
                    padding: "7px",
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                    }}
                >
                    <span style={{ textDecoration: "underline" }}>
                        {textUnderline}
                    </span>{" "}
                    {textUnderlineNext}
                </Typography>
                <Typography
                    sx={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                    }}
                >
                    {textUnderlineDown}
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: "1.2rem 2rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Box
                        sx={{
                            background: primaryColor,
                            width: percentRight + "%",
                            borderRadius: "50px 0px 0px 50px",
                            height: "1.2rem",
                        }}
                    />
                    <Box sx={{ mr: "3px" }} />
                    <Box
                        sx={{
                            background: secondaryColor,
                            width: percentLeft + "%",
                            borderRadius: "0px 50px 50px 0px",
                            height: "1.2rem",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: "10px",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: "#525a6a",
                                fontWeight: "bold",
                                lineHeight: "15px",
                                textAlign: "center",
                            }}
                        >
                            {textRightPercent}
                        </Typography>
                        <Typography
                            sx={{
                                color: primaryColor,
                                fontWeight: "bold",
                                lineHeight: "15px",
                                textAlign: "center",
                            }}
                        >
                            {textRight}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#525a6a",
                                fontWeight: "bold",
                                lineHeight: "15px",
                                textAlign: "center",
                            }}
                        >
                            {textLeftPercent}
                        </Typography>
                        <Typography
                            sx={{
                                color: secondaryColor,
                                fontWeight: "bold",
                                lineHeight: "15px",
                                textAlign: "center",
                            }}
                        >
                            {textLeft}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
    GetRestoreSchedulesStatusModel,
    SearchFilters,
    SearchModel,
} from "../../models/frontiers";

export const getMissingReadingRecords = createAsyncThunk(
    "frontiers/getMissingReadingRecords",
    async (days: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const currentPage = state.frontiers.currentPage;
        const includeFrt = state.frontiers.includeFrt;

        const res = await api.get(
            `/electricity-supply-service/device/missing-reading-record/?intervals_days_before=${days}&include_NOFRT=${includeFrt}&page=${currentPage}&size=50`,
        );
        return { data: res.data, days };
    },
);

export const searchRecordsBySerial = createAsyncThunk(
    "frontiers/searchRecordsBySerial",
    async ({ days, value, filter }: SearchModel, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const includeFrt = state.frontiers.includeFrt;

        const params: Record<string, any> = {
            intervals_days_before: days,
            include_NOFRT: includeFrt,
        };

        if (filter === SearchFilters.METER_SERIAL) {
            params["meter_serial"] = value;
        }

        if (filter === SearchFilters.FRT_CODE) {
            params["frt_code"] = value;
        }

        const res = await api.get(
            `/electricity-supply-service/device/missing-reading-record/`,
            {
                params,
            },
        );

        return res.data;
    },
);

export const getRestoreSechedulesStatus = createAsyncThunk(
    "frontiers/getRestoreSechedulesStatus",
    async (meterId: string) => {
        const res = await api.get<GetRestoreSchedulesStatusModel>(
            `/electricity-supply-service/device/meters/${meterId}/command-state/`,
        );
        return res.data;
    },
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Filters, Frontier, FrontiersState } from "../../models/frontiers";
import { getFrontier, listFrontiers } from "../thunks/frontiers";

const initialState: FrontiersState = {
    frontiers: [],
    currentPage: 1,
    totalPages: 0,
    error: false,
    loading: false,
    searchValue: "",
    frtGeneralData: [],
    frtDetail: null,
    filters: { frontier_type: "", frontier_status: "", xm_registered_date: "" },
    selectedFrts: [],
    toDownload: [],
};

export const frontiersSlice = createSlice({
    name: "frontiers",
    initialState,
    reducers: {
        setCurrentPage: (state, { payload }: PayloadAction<number>) => {
            state.currentPage = payload;
        },
        setSearchValue: (state, { payload }: PayloadAction<string>) => {
            state.searchValue = payload;
        },
        setFilters: (
            state,
            { payload }: PayloadAction<{ key: keyof Filters; value: any }>,
        ) => {
            state.filters[payload.key] = payload.value;
        },
        resetFilters: (state) => {
            state.filters = {
                frontier_type: "",
                frontier_status: "",
                xm_registered_date: "",
            };
        },
        setSelectedFrts: (state, { payload }: PayloadAction<string[]>) => {
            state.selectedFrts = payload;
        },
        addFrtToDownload: (state, { payload }: PayloadAction<Frontier>) => {
            state.toDownload.push(payload);
        },
        setFrtToDownload: (state, { payload }: PayloadAction<Frontier[]>) => {
            state.frontiers = payload;
        },
        setFrtDetail: (state, { payload }: PayloadAction<Frontier | null>) => {
            state.frtDetail = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listFrontiers.pending, (state) => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(listFrontiers.fulfilled, (state, { payload }) => {
            state.error = false;
            state.loading = false;
            state.frontiers = payload.data;
            state.currentPage = payload.currentPage;
            state.totalPages = payload.totalPages;
        });
        builder.addCase(listFrontiers.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(getFrontier.pending, (state) => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(getFrontier.fulfilled, (state, { payload }) => {
            state.error = false;
            state.loading = false;
            state.frtGeneralData = payload?.data || [];
            state.frtDetail = payload?.frt || null;
        });
        builder.addCase(getFrontier.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export const {
    setCurrentPage,
    setSearchValue,
    setFilters,
    resetFilters,
    setSelectedFrts,
    addFrtToDownload,
    setFrtToDownload,
    setFrtDetail,
} = frontiersSlice.actions;

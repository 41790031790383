import { Box, Skeleton, styled } from "@enerbit/base";

interface SectionCardInformationSkeletonProps {
  backgroundColor?: string;
  numItems?: number;
}

const SectionCardInformationSkeleton = ({
  backgroundColor = "#E0E0E0",
  numItems = 4,
}: SectionCardInformationSkeletonProps) => {
  return (
    <>
      <SectionCardSkeleton backgroundColor={backgroundColor}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton
              variant="text"
              width={200}
              height={30}
              sx={{ marginLeft: "0.5rem" }}
            />
          </Box>
          <Skeleton variant="rectangular" width={25} height={25} />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {Array.from(new Array(numItems)).map((_, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <Skeleton variant="circular" width={20} height={20} />
              <Box sx={{ flex: 1 }}>
                <Skeleton variant="text" width="100%" height={20} />
              </Box>
            </Box>
          ))}
        </Box>
      </SectionCardSkeleton>
    </>
  );
};

const SectionCardSkeleton = styled(Box)<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    backgroundColor: backgroundColor,
    padding: "16px",
    borderRadius: "8px",
    marginBottom: "1rem",
  })
);

export default SectionCardInformationSkeleton;

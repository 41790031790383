import {
    Box,
    enerbitColors,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Switch,
    Typography,
} from "@enerbit/base";
import { Process } from "../../../models";
import ProcessPropertyItem from "./ProcessPropertyItem";
import { generateRandomId } from "../../../utils";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { buildingRoleNames } from "../../../common";
import ProcessAgenda from "./ProcessAgenda";

interface Props {
    process: Process;
}

const PropertyForm = ({ process }: Props) => {
    return (
        <Box>
            {(!process.form_data.step1 ||
                Object.entries(process.form_data.step1).length === 0) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: enerbitColors.primary.main,
                        }}
                    >
                        Todavía no hay información para mostrar en esta sección.
                    </Typography>
                </Box>
            )}
            {process.form_data.step1 &&
                Object.entries(process.form_data.step1).length > 0 && (
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "16px",
                                mt: 1,
                            }}
                        >
                            Información general
                        </Typography>
                        <Box sx={{ my: 1 }}>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    defaultChecked={
                                                        process.form_data.step1
                                                            .has_all_documents
                                                    }
                                                    disabled
                                                />
                                            }
                                            label={
                                                <Typography
                                                    sx={{ fontSize: "16px" }}
                                                >
                                                    ¿Cuenta con todos los
                                                    documentos?
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        N° de propiedades
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            process.form_data.step1
                                                .total_buildings
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={process.form_data.step1.total_buildings.toString()}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Rol en la propiedad
                                    </InputLabel>
                                    <CustomTextField
                                        fullWidth
                                        disabled
                                        value={
                                            buildingRoleNames[
                                                process.form_data.step1.role
                                            ]
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <CopyButton
                                                    text={
                                                        buildingRoleNames[
                                                            process.form_data
                                                                .step1.role
                                                        ]
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {process.form_data.step1.buildings && (
                            <>
                                <Typography
                                    sx={{
                                        color: enerbitColors.primary.main,
                                        fontSize: "16px",
                                        my: 1,
                                    }}
                                >
                                    Lista de propiedades
                                </Typography>
                                <Box>
                                    {process.form_data.step1.buildings.map(
                                        (item, index) => (
                                            <ProcessPropertyItem
                                                property={item}
                                                key={generateRandomId()}
                                                index={index}
                                            />
                                        ),
                                    )}
                                </Box>
                            </>
                        )}
                        {!process.form_data.step1.buildings &&
                            (process.form_data.step1.total_buildings > 3 ||
                                process.form_data.step1.role === "agent") && (
                                <ProcessAgenda processId={process.id} />
                            )}
                    </Box>
                )}
        </Box>
    );
};

export default PropertyForm;

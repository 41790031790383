import { Dispatch, SetStateAction } from "react";
import { EExtraServices, EInvoiceType, MobilityService } from "../../models";
import {
    Box,
    CloseIcon,
    enerbitColors,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from "@enerbit/base";
import MobilityIcon from "../../assets/Mobility.gif";

interface Props {
    setCurrentExtraService: Dispatch<SetStateAction<EExtraServices | null>>;
    mobilityService: MobilityService;
}

const MobilityForm = ({ setCurrentExtraService, mobilityService }: Props) => {
    const onClose = () => {
        setCurrentExtraService(null);
    };

    return (
        <Box
            sx={{
                backgroundColor: enerbitColors.primary[100],
                p: 2,
                borderRadius: "16px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img
                        src={MobilityIcon}
                        alt="solar"
                        style={{ width: "60px", height: "60px" }}
                    />
                    <Typography sx={{ fontWeight: 600 }}>
                        Energía para vehículos eléctricos
                    </Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    defaultChecked={mobilityService.has_charger}
                                    disabled
                                />
                            }
                            label={
                                <Typography sx={{ fontSize: "16px" }}>
                                    ¿Cuenta con cargador de vehículos eléctricos
                                    en uso?
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="documentation">
                            Tipo de factura
                        </FormLabel>
                        <RadioGroup defaultValue={mobilityService.invoice_type}>
                            <FormControlLabel
                                disabled
                                value={EInvoiceType.TOGETHER}
                                control={<Radio />}
                                label="Todo junto, el consumo de hogar y vehículo"
                            />
                            <FormControlLabel
                                disabled
                                value={EInvoiceType.SEPARATED}
                                control={<Radio />}
                                label="Separado el consumo de mi hogar y vehículo"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MobilityForm;

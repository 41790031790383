/**
 * Mensajes de error para los campos de entrada.
 * @property inputRequired - Mensaje de error para un campo requerido.
 * @property inputNumberRequired - Mensaje de error para un valor numérico requerido.
 * @property inputGreaterThanZeroRequired - Mensaje de error para un valor mayor a 0.
 * @property inputGreaterThanOrEqualZeroRequired - Mensaje de error para un valor mayor o igual a 0.
 * @property inputNamesValidRequired - Mensaje de error para nombres válidos sin símbolos ni espacios.
 * @property inputWholeNumbersRequired - Mensaje de error para valores enteros sin decimales.
 */
export const errorInputFields = {
  inputRequired: "Campo requerido",
  inputNumberRequired: "El valor debe ser tipo númerico",
  inputGreaterThanZeroRequired: "El valor debe ser mayor a 0",
  inputGreaterThanOrEqualZeroRequired: "El valor debe ser mayor o igual a 0",
  inputNamesValidRequired: "No se permite ingresar simbolos ni espacios",
  inputWholeNumbersRequired:
    "No se permiten valores decimales, solo numeros enteros",
};

import { Box, Typography } from "@enerbit/base";
import type { IEntity } from "../../interfaces";
import { CustomCardNeutral } from "./CardNeutral.styled";

interface IProps {
    entity: IEntity;
}

export const EntityCard = ({ entity }: IProps) => {
    const { entityName, data } = entity;
    return (
        <Box sx={{ position: "relative" }}>
            <Typography color="primary" fontWeight="bold" sx={{ my: 1 }}>
                {entityName}
            </Typography>
            <CustomCardNeutral>
                {data?.map((item, index: number) => (
                    <Box sx={{ py: 1 }} key={index}>
                        <Typography>{item.text}:</Typography>
                        <Typography fontWeight="bold">{item.value}</Typography>
                    </Box>
                ))}
            </CustomCardNeutral>
        </Box>
    );
};

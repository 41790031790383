import { ArrowForwardIcon, Box, Button, Typography } from "@enerbit/base";

interface Props {
    goBack: () => void;
    proccessId: string | undefined;
    asicCode: string | null;
}

const ReportResultHeader = ({ goBack, proccessId, asicCode }: Props) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                    variant="outlined"
                    className="back-btn"
                    onClick={goBack}
                >
                    <ArrowForwardIcon style={{ transform: "rotate(180deg)" }} />
                </Button>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "28px",
                            fontWeight: 700,
                            color: "#53358E",
                        }}
                    >
                        Reporte {proccessId}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", color: "#667085" }}>
                        Código de recepción ASIC <strong>{asicCode}</strong>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default ReportResultHeader;

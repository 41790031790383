import {
    MissingHoursStatus,
    RestoreSchedulesModel,
    RestoreSchedulesStatusModel,
    RestoreSchedulesTypes,
    SearchFilters,
    SearchFiltersModel,
    UsagesChartDataModel,
} from "../models/frontiers";
import { ReportAttempsStates, ResultFlags } from "../models/reports";

export const SEARCH_FILTERS: SearchFiltersModel[] = [
    { name: "Serial", value: SearchFilters.METER_SERIAL },
    { name: "Frontera", value: SearchFilters.FRT_CODE },
];

export const RESTORE_SCHEDULES_TYPES: RestoreSchedulesModel[] = [
    { name: "Medidor", value: RestoreSchedulesTypes.METER },
    {
        name: "smartBit",
        value: RestoreSchedulesTypes.DEVICE,
    },
];

export const HOURS: string[] = [
    "00:00:00-05:00",
    "01:00:00-05:00",
    "02:00:00-05:00",
    "03:00:00-05:00",
    "04:00:00-05:00",
    "05:00:00-05:00",
    "06:00:00-05:00",
    "07:00:00-05:00",
    "08:00:00-05:00",
    "09:00:00-05:00",
    "10:00:00-05:00",
    "11:00:00-05:00",
    "12:00:00-05:00",
    "13:00:00-05:00",
    "14:00:00-05:00",
    "15:00:00-05:00",
    "16:00:00-05:00",
    "17:00:00-05:00",
    "18:00:00-05:00",
    "19:00:00-05:00",
    "20:00:00-05:00",
    "21:00:00-05:00",
    "22:00:00-05:00",
    "23:00:00-05:00",
];

export const DEFAULT_DAYS_BEFORE = "3";
export const DEFAULT_INCLUDE_NOFRT = false;

export const INTERVALS_DAYS_BEFORE: { label: string; value: string }[] = [
    { label: "2 días", value: "2" },
    { label: "3 días", value: "3" },
    { label: "4 días", value: "4" },
    { label: "7 días", value: "7" },
];

export const INCLUDE_NOFRT_FILTER: { label: string; value: boolean }[] = [
    { label: "Si", value: true },
    { label: "No", value: false },
];

export const RESTORE_SCHEDULES_STATUS: Record<
    string,
    RestoreSchedulesStatusModel
> = {
    Success: {
        bgcolor: "#E7F8F0",
        color: "#12B76A",
        message: "La última recuperación de datos fue exitosa.",
    },
    waiting: {
        bgcolor: "#FEF4E6",
        color: "#F79009",
        message: "Recuperación de datos en progreso, tiempo de espera: ",
    },
    Failed: {
        bgcolor: "#FEECEB",
        color: "#F04438",
        message: "La última recuperación de datos falló. Inténtalo de nuevo.",
    },
    error: {
        bgcolor: "#FEECEB",
        color: "#F04438",
        message:
            "Hubo un error al consultar el estado del comando de recuperación.",
    },
    loading: {
        bgcolor: "#E4E7EC",
        color: "#333333",
        message: "Consultando estado del comando de recuperación.",
    },
};

export const RESTORE_SCHEDULES_ERRORS: Record<string, string> = {
    423: "Probablemente exista un comando activo, inténtelo nuevamente más tarde.",
    400: "Error al enviar el comando de recuperación.",
};

export const USAGES_CHART_DATASETS: UsagesChartDataModel[] = [
    {
        label: "Energía activa importada",
        data: "active_energy_imported",
        borderColor: "rgba(49, 107, 200, 1)",
        backgroundColor: "rgba(49, 107, 200, 1)",
    },
    {
        label: "Energía reactiva importada",
        data: "reactive_energy_imported",
        borderColor: "rgba(18, 183, 106, 1)",
        backgroundColor: "rgba(18, 183, 106, 1)",
    },
    {
        label: "Energía activa exportada",
        data: "active_energy_exported",
        borderColor: "rgba(247, 144, 9, 1)",
        backgroundColor: "rgba(247, 144, 9, 1)",
    },
    {
        label: "Energía reactiva exportada",
        data: "reactive_energy_exported",
        borderColor: "rgba(83, 53, 142, 1)",
        backgroundColor: "rgba(83, 53, 142, 1)",
    },
];

export const MISSING_HOURS_STATUS: Record<
    MissingHoursStatus,
    { bgcolor: string; color: string; icon: string }
> = {
    EXISTS: { bgcolor: "#E7F8F0", color: "#12B76A", icon: "checkIcon" },
    MISSING: { bgcolor: "#FEECEB", color: "#F04438", icon: "closeIcon" },
    OUT_OF_TIME: { bgcolor: "#F2F4F7", color: "#667085", icon: "lineIcon" },
    ESTIMATED: {
        bgcolor: "#FEF4E6",
        color: "#F79009",
        icon: "priorityHighIcon",
    },
};

export const MISSING_HOURS_LABELS: {
    bgcolor: string;
    color: string;
    label: string;
}[] = [
    { bgcolor: "#FEECEB", color: "#F04438", label: "Datos faltantes" },
    { bgcolor: "#FEF4E6", color: "#F79009", label: "Estimado" },
    { bgcolor: "#E7F8F0", color: "#12B76A", label: "Datos completos" },
    { bgcolor: "#F2F4F7", color: "#667085", label: "Fuera de hora" },
];

export const RELAY_STATUS: Record<any, string> = {
    408: "Desconectado",
    ON: "Conectado",
    OFF: "Desconectado",
    ERROR: "Error consultando",
};

export const REPORTS_STATUS: Record<
    string,
    { fontcolor: string; bgcolor: string; label: string }
> = {
    sent: { bgcolor: "#E7F8F0", fontcolor: "#12B76A", label: "Enviado" },
    noSent: { bgcolor: "#EAF0FA", fontcolor: "#3B82F6", label: "Sin enviar" },
};

export const REPORT_ATTEMPS_STATES: Record<
    ReportAttempsStates,
    { bgcolor: string; fontcolor: string; label: string }
> = {
    Waiting: { bgcolor: "#F5F5F5", fontcolor: "#666666", label: "En espera" },
    Warning: {
        bgcolor: "#FEF4E6",
        fontcolor: "#F79009",
        label: "Recibido con aviso",
    },
    NoSent: { bgcolor: "#EAF0FA", fontcolor: "#3B82F6", label: "Sin enviar" },
    Success: { bgcolor: "#E7F8F0", fontcolor: "#12B76A", label: "Recibido" },
    Error: { bgcolor: "#FEECEB", fontcolor: "#F04438", label: "Rechazado" },
};

export const REPORTS_LIST_ORDER: ReportAttempsStates[] = [
    ReportAttempsStates.NO_SENT,
    ReportAttempsStates.ERROR,
    ReportAttempsStates.WAITING,
    ReportAttempsStates.WARNING,
    ReportAttempsStates.SUCCESS,
];

export const REPORT_RESULT_LIST_ORDERS: ResultFlags[] = [
    ResultFlags.ERROR,
    ResultFlags.SUCCESS,
];

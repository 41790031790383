import { Box, DataGrid, Grid } from "@enerbit/base";
import { useEffect } from "react";

import { customDataGrid } from "../components/table/Table.styled";
import { columnsSwapper } from "../const/ColumnsSwapper";
import { useGet } from "../hooks";
import type { ISwapperRows } from "../interfaces";
import { getSwappedCertificates } from "../services";
import { useSwapMetersStore } from "../store/swappMeters.store";

const SwapMeterTable = () => {
    const { swapMeters, setSwapMeters } = useSwapMetersStore();
    const { isLoading, data, isSuccess } = useGet<ISwapperRows[]>(
        "swap-meter",
        getSwappedCertificates,
    );

    useEffect(() => {
        if (isSuccess && data) {
            setSwapMeters(data);
        }
    }, [isSuccess, data]);

    return (
        <Box>
            <Grid item xs={12}>
                <DataGrid
                    sx={customDataGrid}
                    loading={isLoading}
                    autoHeight
                    disableSelectionOnClick
                    rows={swapMeters || []}
                    columns={columnsSwapper}
                    pageSize={10}
                    rowCount={data?.length}
                    hideFooterPagination
                />
            </Grid>
        </Box>
    );
};

export default SwapMeterTable;

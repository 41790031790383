import { Box, enerbitColors, Grid, Skeleton, Typography } from "@enerbit/base";

const DetailsPlanSkeleton: React.FC = () => {
  return (
    <>
      <Box
        className="Detail-plan-box"
        sx={{
          border: `1px solid ${enerbitColors.neutral[300]}`,
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        <Typography
          className="Title-name-plan"
          color={enerbitColors.primary.main}
        >
          <Skeleton variant="text" width={200} height={40} />
        </Typography>
        <Box sx={{ mb: "32px", mt: "20px" }}>
          <Typography color={enerbitColors.primary.main} fontWeight={700}>
            Descripción:
          </Typography>
          <Skeleton variant="text" width="100%" height={60} />
        </Box>
        <Grid container spacing={2} sx={{ mb: "32px" }}>
          <Grid item xs={6}>
            <Box sx={{ color: enerbitColors.primary.main }}>
              <Typography fontWeight={700} color={enerbitColors.primary.main}>
                Valor de la cobertura:
              </Typography>
              <Skeleton variant="text" width={100} height={30} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography color={enerbitColors.primary.main} fontWeight={700}>
              Tipo de cobertura:
            </Typography>
            <Box
              sx={{ background: enerbitColors.neutral[100] }}
              className="Box-status-plan-disabled"
            >
              <Skeleton variant="text" width={100} height={30} />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: "30px" }}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
      </Box>
    </>
  );
};

export default DetailsPlanSkeleton;

import { Tab, Tabs, Typography } from "@enerbit/base";

import { useEffect, useState } from "react";
import InconsistenciesTable from "./InconsistenciesTable";
import SwapMeterTable from "./SwapMeterTable";

const Lobby = () => {
    const [value, setValue] = useState(
        Number(localStorage.getItem("tab")) || 0,
    );

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        localStorage.setItem("tab", value.toString());
    }, [value]);

    return (
        <>
            <Typography
                color="primary"
                variant="h5"
                fontWeight="bold"
                sx={{ mb: 4 }}
            >
                Novedades
            </Typography>

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ my: 3 }}
                indicatorColor="secondary"
                textColor="inherit"
            >
                <Tab
                    label="Inconsistencia de datos"
                    id={`simple-tab-${0}`}
                    aria-controls={`simple-tabpanel-${0}`}
                    sx={{
                        textTransform: "none",
                        color: "#000",
                        fontWeight: value === 0 ? "bold" : "normal",
                    }}
                />
                <Tab
                    label="Medidores trocados"
                    id={`simple-tab-${1}`}
                    aria-controls={`simple-tabpanel-${1}`}
                    sx={{
                        textTransform: "none",
                        color: "#000",
                        fontWeight: value === 1 ? "bold" : "normal",
                    }}
                />
            </Tabs>

            {value === 0 && <InconsistenciesTable />}
            {value === 1 && <SwapMeterTable />}
        </>
    );
};

export default Lobby;

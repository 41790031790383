import { Box, Typography } from "@enerbit/base";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getRestoreSechedulesStatus } from "../../store/thunks/frontiers";

interface Props {
    deadline: string;
    meterId: string;
}

const CountdownTimer = ({ deadline, meterId }: Props) => {
    const countdownTarget: any = new Date(deadline);

    const getTimeLeft = () => {
        const currentDate: any = new Date();
        const totalTimeLeft = countdownTarget - currentDate;

        if (totalTimeLeft <= 0) {
            return { hours: 0, minutes: 0, seconds: 0 };
        }

        // const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((totalTimeLeft / 1000) % 60);
        return { hours, minutes, seconds };
    };

    const [timeLeft, setTimeLeft] = useState<Record<string, number>>(() =>
        getTimeLeft(),
    );
    const restoreStatus = useSelector(
        (state: RootState) => state.frontiers.restoreScheduleStatus,
    );

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const timer = setInterval(() => {
            const updatedTimeLeft = getTimeLeft();
            setTimeLeft(updatedTimeLeft);

            if (
                updatedTimeLeft.hours === 0 &&
                updatedTimeLeft.minutes === 0 &&
                updatedTimeLeft.seconds === 0
            ) {
                clearInterval(timer);
                if (restoreStatus.is_active)
                    dispatch(getRestoreSechedulesStatus(meterId));
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatTime = (value: number) => {
        return String(value).padStart(2, "0");
    };

    return (
        <Box sx={{ display: "flex" }}>
            {Object.keys(timeLeft).map((unit, index) => (
                <React.Fragment key={unit}>
                    <Box className="value">
                        <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
                            {formatTime(timeLeft[unit])}
                        </Typography>
                    </Box>
                    {index < 2 && (
                        <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
                            :
                        </Typography>
                    )}{" "}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default CountdownTimer;

import {
    Box,
    Button,
    GridColDef,
    Pagination,
    Stack,
    SxProps,
    enerbitColors,
} from "@enerbit/base";
import CustomDatagrid from "../../../../components/Table/CustomDataGrid";
import StatusTag from "../../../../components/Containers/StatusTag";
import { useNavigate } from "react-router-dom";
import PaginationContainer from "../../../../components/Containers/PaginationContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import {
    addFrtToDownload,
    setCurrentPage,
    setSelectedFrts,
} from "../../../../store/slices/frontiers";
import { listFrontiers } from "../../../../store/thunks/frontiers";
import { frontierStatusesColor } from "../../../../common/frontiers";
import { useEffect, useState } from "react";
import { formatFrontierData } from "../../../../utils/frontiers";

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
};

const FrontierTable = () => {
    const navigate = useNavigate();

    const { frontiers, currentPage, totalPages, selectedFrts, toDownload } =
        useSelector((state: RootState) => state.frontiers);

    const [containerHeight, setContainerHeight] = useState<number>(0);

    const getRowHeight = () => 50; // Altura deseada para cada fila

    useEffect(() => {
        const totalRowsHeight = frontiers.length * getRowHeight();
        const margin = 60;
        setContainerHeight(totalRowsHeight + margin);
    }, []);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        event.preventDefault();
        dispatch(setCurrentPage(value));
        dispatch(listFrontiers());
    };

    const cols: GridColDef[] = [
        { field: "code", headerName: "Código SIC", flex: 1 },
        { field: "name", headerName: "Nombre", flex: 2 },
        { field: "frontierType", headerName: "Tipo de frontera", flex: 1 },
        { field: "location", headerName: "Ubicación", flex: 1 },
        {
            field: "xm_register_date",
            headerName: "Fecha de registro XM",
            flex: 1,
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <StatusTag
                        bgcolor={
                            frontierStatusesColor[params.formattedValue].bgcolor
                        }
                        fontcolor={
                            frontierStatusesColor[params.formattedValue]
                                .fontcolor
                        }
                    >
                        {frontierStatusesColor[params.formattedValue].label}
                    </StatusTag>
                );
            },
        },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    sx={detailButtonProps}
                    onClick={() => navigate(`/${params.id}`)}
                >
                    Ver detalle
                </Button>
            ),
        },
    ];
    const addFrtsToDownload = (ids: string[]) => {
        ids.forEach((id) => {
            const tempFrt = frontiers.find(
                (frt) => frt.frontier_xm_code === id,
            );

            if (tempFrt) dispatch(addFrtToDownload(tempFrt));
        });
    };

    return (
        <>
            <Box sx={{ height: containerHeight, width: "100%" }}>
                <CustomDatagrid
                    hideFooter
                    disableSelectionOnClick
                    checkboxSelection
                    disableColumnMenu
                    keepNonExistentRowsSelected
                    columns={cols}
                    rows={formatFrontierData(frontiers)}
                    getRowHeight={getRowHeight}
                    onSelectionModelChange={(ids) => {
                        dispatch(setSelectedFrts(ids as any));
                        addFrtsToDownload(ids as string[]);
                    }}
                    selectionModel={selectedFrts}
                />
            </Box>
            <PaginationContainer>
                <Stack spacing={2}>
                    <Pagination
                        page={currentPage}
                        count={totalPages}
                        shape="rounded"
                        onChange={handleChange}
                    />
                </Stack>
            </PaginationContainer>
        </>
    );
};

export default FrontierTable;

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    enerbitColors,
    Grid,
    InputLabel,
    SxProps,
    Typography,
} from "@enerbit/base";
import { Properties } from "../../../models";
import AccordionButton from "./AccordionButton";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import { SyntheticEvent, useState } from "react";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "../../process-detail/components/CopyButton";
import { serviceLocationNames } from "../../../common";
import { getInvoiceCompany, getMarket } from "../../../utils";

interface Props {
    index: number;
    property: Properties;
}

const PropertyItem = ({ index, property }: Props) => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const handleChange =
        (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : null);
        };

    const accordionSummaryStyles: SxProps = {
        // background: "#fff",
        borderRadius: expanded === index ? "4px 4px 0 0" : "4px",
        height: "auto !important",
        p: 1,
        border: `1px solid ${enerbitColors.primary[200]}`,
        // minHeight: "70px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
        },
    };

    const accordionDetailsStyles: SxProps = {
        p: 1,
        border:
            expanded === index
                ? `1px solid ${enerbitColors.primary[200]}`
                : null,
        borderRadius: "0 0 4px 4px",
    };

    return (
        <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
                my: 2,
                boxShadow: "unset !important",
                borderRadius: "16px !important",
            }}
        >
            <AccordionSummary
                expandIcon={<AccordionButton expanded={expanded === index} />}
                sx={accordionSummaryStyles}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <HomeWorkRoundedIcon color="primary" />
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "12px",
                            }}
                        >
                            Propiedad {index + 1}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                color: enerbitColors.primary.main,
                            }}
                        >
                            {property.address}
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsStyles}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mt: 2,
                        }}
                    >
                        <CopyButton text={property.id} />
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "16px",
                            }}
                        >
                            Id: {property.id}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={2}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Departamento
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={property.state}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={property.state} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Municipio
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={property.city}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={property.city} />
                                    ),
                                }}
                            />
                        </Grid>
                        {property.market && (
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Empresa de energía
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        getInvoiceCompany(property.market) ??
                                        getMarket(property.market)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    getInvoiceCompany(
                                                        property.market,
                                                    ) ??
                                                    getMarket(property.market)
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Dirección
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={property.address}
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton text={property.address} />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                Ubicación del servicio
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                disabled
                                value={
                                    serviceLocationNames[property.service_type]
                                }
                                InputProps={{
                                    endAdornment: (
                                        <CopyButton
                                            text={
                                                serviceLocationNames[
                                                    property.service_type
                                                ] as string
                                            }
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default PropertyItem;

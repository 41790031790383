import { configureStore } from "@reduxjs/toolkit";
import { frontiersSlice } from "./slices/frontiers";
import { eventsSlice } from "./slices/events";
import { reportsSlice } from "./slices/reports";

export const store = configureStore({
    reducer: {
        frontiers: frontiersSlice.reducer,
        events: eventsSlice.reducer,
        reports: reportsSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { api } from "@enerbit/base";
import {
    formatData,
    formatSwapperData,
    simpleFormData,
} from "../helpers/table/formatData";
import type { IRowsInconsistency } from "../interfaces";

export const getKey = async (): Promise<string> => {
    const { data } = await api.get("/inconsistency/storage-available");
    return data.storage[data.storage.length - 1].key;
};

export const getSwappedCertificates = async () => {
    const { data } = await api.get(`/inconsistency/certificate-swapped`);
    return formatSwapperData(data);
};

export const reloadKey = async (page: number) => {
    const key = await getKey();
    localStorage.setItem("key", key);
    const { data } = await api.get(
        `/inconsistency/inconsistencies?key=${key}&page=${page}&size=10`,
    );

    const response = {
        rows: formatData(data),
        total: data.total,
    };
    return response;
};

export const getInconsistencies = async (
    page: number = 0,
): Promise<{ rows: IRowsInconsistency[]; total: number }> => {
    const currentKey = localStorage.getItem("key");
    if (!currentKey) {
        const response = await reloadKey(page);
        return response;
    }

    const { data } = await api.get(
        `/inconsistency/inconsistencies?key=${currentKey}&page=${page}&size=10`,
    );

    if (Object.keys(data).length === 0) {
        const response = await reloadKey(page);
        return response;
    }

    const response = {
        rows: formatData(data),
        total: data.total,
    };
    return response;
};

export const getInconsistenciesBySerial = async (
    serial: string = "",
): Promise<{ rows: IRowsInconsistency[]; total: number }> => {
    const { data } = await api.get(
        `/inconsistency/by-serial?serial=${serial}&cache=true`,
    );
    const response = {
        rows: simpleFormData(data) as IRowsInconsistency[],
        total: 1,
    };
    return response;
};

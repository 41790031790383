import { Dispatch, SetStateAction, useState } from "react";
import {
    Grid,
    Typography,
    FormControl,
    LocalizationProvider,
    TextField,
    Button,
    SearchIcon,
    DownloadOutlinedIcon,
    Box,
    DatePicker,
    FilterListIcon,
    styled,
    MenuProps,
    Menu,
    AdapterMoment,
} from "@enerbit/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";

interface Props {
    since: string | null;
    setSince: Dispatch<SetStateAction<string | null>>;
    setUntil: Dispatch<SetStateAction<string | null>>;
    until: string | null;
    handleSearch: () => void;
    onGenerateFile: () => void;
}

const MissingHoursActions = ({
    since,
    setSince,
    setUntil,
    until,
    handleSearch,
    onGenerateFile,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onSearch = () => {
        handleSearch();
        handleCloseMenu();
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
            }}
        >
            <Button
                onClick={handleOpenMenu}
                sx={{
                    minHeight: "unset",
                    borderRadius: "12px",
                    border: "1px solid #A3A9B6",
                    height: "41px",
                    display: "felx",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <FilterListIcon sx={{ fontSize: "14px", color: "#667085" }} />
                <Typography sx={{ fontSize: "12px", color: "#14161B" }}>
                    Filtrar por fecha
                </Typography>
                <KeyboardArrowDownIcon sx={{ color: "#667085" }} />
            </Button>
            <Button
                onClick={onGenerateFile}
                sx={{ width: "190px", height: "41px" }}
                color="secondary"
                variant="contained"
            >
                <DownloadOutlinedIcon />
                <Typography>Descargar CSV</Typography>
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
            >
                <Box>
                    <FormControl sx={{ mb: 2 }} fullWidth>
                        <Typography>Desde</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={moment(since)}
                                maxDate={moment()}
                                onChange={(value: any) => {
                                    setSince(value.format("YYYY-MM-DD"));
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            "& fieldset": {
                                                borderRadius: "12px",
                                            },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ mb: 2 }} fullWidth>
                        <Typography>Hasta</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={moment(until)}
                                maxDate={moment()}
                                onChange={(value: any) => {
                                    setUntil(value.format("YYYY-MM-DD"));
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        sx: {
                                            "& fieldset": {
                                                borderRadius: "12px",
                                            },
                                            ".MuiInputBase-root": {
                                                height: "41px",
                                            },
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <Button
                        fullWidth
                        sx={{ height: "41px" }}
                        color="primary"
                        variant="contained"
                        onClick={onSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
            </StyledMenu>
        </Box>
    );
};

export default MissingHoursActions;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
    ({ theme }) => ({
        "& .MuiPaper-root": {
            borderRadius: "16px",
            width: 250,
            padding: "24px",
            paddingBottom: "5px",
            boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important",
            top: "550px !important",
        },
    }),
);

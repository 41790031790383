import { useDispatch } from "react-redux";
import {
  FiltersInvoice,
  HeadTableInvoice,
  TableInvoice,
} from "./components/table-invoice-information";

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  getInvoices,
  getTotalOwed,
} from "../../../../store/actions/billing/billing.actions";
import { setEssId } from "../../../../store/slices/billingSlice";
import { AppDispatch } from "../../../../store/store";

export const BillingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(setEssId(id));
    dispatch(getTotalOwed({ ess_id: id!, month_filter: "" }));
    dispatch(
      getInvoices({
        ess_id: id!,
        page: 1,
        month_filter: "",
      })
    );
  }, []);

  return (
    <>
      <HeadTableInvoice />
      <FiltersInvoice />
      <TableInvoice />
      {/* <MonthAndButton /> */}
    </>
  );
};

import { EnerbitPagination, api } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import {
    ICancelServiceOrder,
    ICancelServiceOrderResponse,
    ICancellationReasons,
    IParamsServiceOrders,
    IRespActivities,
    ItemGetOrders,
} from "../models";
import { getRangeISO } from "../utils/ValidationsDateTime";

export const getServiceOrders = async (params: IParamsServiceOrders) => {
    try {
        const { id, entity, page, dateRange, status, orderTypeId } = params;
        const { start, end } = getRangeISO(dateRange);

        return api.get<EnerbitPagination<ItemGetOrders>>(
            `/service-orders/orders?sort_planned_date_begin=DESC&assignee_id=${id}${
                entity ? `&entity_code=${entity}` : ""
            }${status ? `&status=${status}` : ""}${
                orderTypeId ? `&order_type_id=${orderTypeId}` : ""
            }&page=${page ?? 0}&size=${9}${
                dateRange.startDate ? `&planned_date_begin=${start}` : ""
            }${dateRange.endDate ? `&planned_date_end=${end}` : ""}`,
        );
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al cargar las órdenes de servicio", {
            variant: "error",
        });
        throw error;
    }
};

export const getOrderTypes = async () => {
    try {
        return api.get<IRespActivities>("/service-orders/order-types");
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al cargar los tipos de órdenes", {
            variant: "error",
        });
        throw error;
    }
};

export const getCancellationReasons = async () => {
    try {
        return api.get<ICancellationReasons>(
            "/service-orders/cancellation-reasons?page=0&size=50",
        );
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al cancelar la orden", {
            variant: "error",
        });
        throw error;
    }
};

export const cancelOrder = async (order: ICancelServiceOrder) => {
    try {
        await api.post<ICancelServiceOrderResponse>(
            `/service-orders/order-cancel`,
            order,
        );
        enqueueSnackbar("Orden cancelada con éxito.", { variant: "success" });
    } catch (error) {
        enqueueSnackbar("No se pudo cancelar la orden, inténtelo de nuevo.", {
            variant: "error",
        });
        throw error;
    }
};

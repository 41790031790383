export interface FustigationsState {
    fustigations: FailedFustigations[];
    selectedFustigations: FailedFustigations[];
    loading: boolean;
    totalPages: number;
    currentPage: number;
    error: boolean;
    searchValue: string;
    since: string;
    filter: SearchFilters;
}

export interface FustigationsResponse {
    items: FailedFustigations[];
    total: number;
    total_pages: number;
    netx_page: number;
}

export interface GetFustigationsFilters {
    since: string;
}

export interface Fustigations {
    since: string;
    until: string;
    id: string;
    measurement_point_id: string;
    judgment: boolean;
    meter_serial: string;
    frt_code: string;
    created_at: string;
}

export interface SearchFiltersModel {
    name: string;
    value: SearchFilters;
}

export enum SearchFilters {
    FRT_CODE = "frt_code",
    METER_SERIAL = "meter_serial",
    MEASUREMENT_POINT_ID = "measurement_point_id",
}

export interface FailedFustigations {
    since: string;
    until: string;
    measurement_point_id: string;
    meter_serial: string;
    judgment_failed: boolean;
    frt_code: string;
}

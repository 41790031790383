import React, { useEffect, useState } from "react";
import CustomContainer from "../../../../components/Containers/CustomContainer";
import {
    AdapterMoment,
    Box,
    CustomAlert,
    DatePicker,
    FormControl,
    IconButton,
    LoadingButton,
    LocalizationProvider,
    SearchIcon,
    Skeleton,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getMeasures } from "../../../../store/thunks/measurements";
import moment from "moment";
import ReplayIcon from "@mui/icons-material/Replay";
import {
    DATE_TIME_FORMAT,
    USAGES_CHART_DATASETS,
} from "../../../../common/frontiers";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const,
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            title: { display: true, text: "Medida (Wh)" },
        },
        x: { title: { display: true, text: "Hora" } },
    },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const MeasuresChart = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { frtDetail } = useSelector((state: RootState) => state.frontiers);
    const { measurements, loading, error, empty } = useSelector(
        (state: RootState) => state.measurements,
    );

    const [since, setSince] = useState<string>(
        moment().startOf("day").format(DATE_TIME_FORMAT),
    );
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

    const handleSearch = () => {
        if (!frtDetail) return;

        let until;

        if (moment().date() === moment(since).date()) {
            until = moment().format(DATE_TIME_FORMAT);
        } else {
            until = moment(since)
                .add(1, "day")
                .startOf("day")
                .format(DATE_TIME_FORMAT);
        }

        dispatch(
            getMeasures({
                id: frtDetail.measurement_point_id,
                since: since,
                until,
            }),
        );
    };

    const handleReset = () => {
        if (!frtDetail) return;
        setSince(moment().startOf("day").format(DATE_TIME_FORMAT));
        dispatch(
            getMeasures({
                id: frtDetail.measurement_point_id,
                since: moment().startOf("day").format(DATE_TIME_FORMAT),
                until: moment().format(DATE_TIME_FORMAT),
            }),
        );
    };

    const data = {
        labels: measurements.map((measure) => measure.label),
        datasets: USAGES_CHART_DATASETS.map((dataset) => ({
            label: dataset.label,
            data: measurements.map((measure) => measure[dataset.data]),
            borderColor: dataset.borderColor,
            backgroundColor: dataset.backgroundColor,
        })),
    };

    useEffect(() => {
        handleReset();
    }, []);

    return (
        <>
            {!loading && (
                <CustomContainer>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                color: enerbitColors.neutral[700],
                                fontWeight: 700,
                                fontSize: "20px",
                            }}
                        >
                            Medidas
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <FormControl>
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        value={moment(since)}
                                        maxDate={moment()}
                                        onChange={(value: any) => {
                                            setSince(
                                                value.format(DATE_TIME_FORMAT),
                                            );
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                sx: {
                                                    "& fieldset": {
                                                        borderRadius: "12px",
                                                    },
                                                    "& .MuiInputBase-root": {
                                                        height: "41px",
                                                    },
                                                    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderRadius:
                                                                "12px",
                                                        },
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSearch}
                                sx={{
                                    minHeight: "unset !important",
                                    height: "41px",
                                    minWidth: "unset !important",
                                    width: "41px",
                                    ml: 1,
                                    mr: 1,
                                }}
                            >
                                <SearchIcon />
                            </LoadingButton>
                            <IconButton onClick={handleReset}>
                                <ReplayIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {!loading && error && (
                        <CustomAlert
                            sx={{ my: 2 }}
                            severity="error"
                            text="Error al consultar las medidas de esta frontera."
                            onClose={() => {}}
                        />
                    )}
                    {!loading && !error && empty && (
                        <CustomAlert
                            sx={{ my: 2 }}
                            severity="info"
                            text="No se encontraron medidas para esta frontera."
                            onClose={() => {}}
                        />
                    )}
                    {!loading && !error && !empty && (
                        <Line options={options} data={data} />
                    )}
                </CustomContainer>
            )}
            {loading && (
                <Skeleton variant="rounded" height="484px" sx={{ mt: 2 }} />
            )}
        </>
    );
};

export default MeasuresChart;

import { api } from "@enerbit/base";

export const refustigateMeter = async (date: string, serials: string[]) => {
    const res = await api.post(
        "/electricity-supply-service/device/meters/refustigate-days",
        {
            date,
            meter_serials: serials,
        },
    );

    return res;
};

/**
 * Verifica el tipo de cobertura basado en el campo service_agreement_type.
 * @param plan - El objeto del plan.
 * @returns `fijabit` o `dinabit` dependiendo del service_agreement_type.
 */
export const getCoverageType = (plan: {
  service_agreement_type: string;
}): string => {
  return plan.service_agreement_type === "dinabit" ? "Variable" : "Fija";
};

import type {
    CertificateValidate,
    IDataCardResponse,
    IDataToValidate,
} from "../interfaces";

export const onChangeData = (
    data1: IDataCardResponse,
    data2: IDataCardResponse,
    index: number,
    validateDataIndex: number,
    dataToValidated: IDataToValidate,
) => {
    const changeMap = {
        ...dataToValidated.data[validateDataIndex],
        service_order: {
            current:
                (data1.certificates[index]
                    ?.dataToMove as CertificateValidate) || null,
            new:
                (data2.certificates[index]
                    ?.dataToMove as CertificateValidate) || null,
        },
    };
    return changeMap;
};

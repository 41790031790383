import { Box, Typography, theme } from "@enerbit/base";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme cs

import { FC, useEffect, useState } from "react";
import { EEntity } from "../enums/EServiceOrder";
import { useServiceOrders } from "../store/ServiceOrders";
import { ServiceOrdersFilter } from "./ServiceOrdersFilter";
import TableServicesOrdersPage from "./Table/TableServicesOrdersPage";

export enum EOrderStatus {
    "En preparación" = "pending_assignment",
    Pendiente = "pending",
    "Parcial Completada" = "partial_done",
    Completada = "done",
    Cancelada = "cancel",
}

const entityDictionary: Record<EEntity, string> = {
    MP: "cliente",
    LD: "lead",
};

interface Props {
    entity: EEntity;
    leadId?: string | undefined;
    measurementPointId: string;
}

export const ServiceOrders: FC<Props> = ({
    entity,
    leadId,
    measurementPointId,
}) => {
    const {
        updatedTableServiceOrder,
        cleanUpdateTableServiceOrder,
        entityUpdated,
    } = useServiceOrders();
    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        if (updatedTableServiceOrder && entity === entityUpdated) {
            cleanUpdateTableServiceOrder();
            setRenderKey((prevKey) => prevKey + 1);
        }
    }, [updatedTableServiceOrder]);

    return (
        <>
            <Box key={renderKey} sx={{ mt: "32px" }}>
                <Typography
                    color={theme.palette.primary.main}
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        lineHeight: "1.5",
                    }}
                >
                    Órdenes de {entityDictionary[entity]}
                    <span>&nbsp;</span>
                </Typography>
                <ServiceOrdersFilter
                    entity={entity}
                    leadId={leadId}
                    measurementPointId={measurementPointId}
                />
                <Box sx={{ mt: "4rem" }}>
                    <TableServicesOrdersPage
                        entity={entity}
                        leadId={leadId}
                        measurementPointId={measurementPointId}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ServiceOrders;

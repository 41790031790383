import { SyntheticEvent, useState } from "react";
import { Building, EExtraServices } from "../../../models";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    enerbitColors,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Switch,
    SxProps,
    Typography,
} from "@enerbit/base";
import AccordionButton from "../../agenda-detail/components/AccordionButton";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import CopyButton from "./CopyButton";
import { serviceLocationNames } from "../../../common";
import { getInvoiceCompany, getMarket } from "../../../utils";
import FileContainer from "../../../components/container/FileContainer";
import ExtraServiceItem from "../../../components/container/ExtraServiceItem";
import SolafForm from "../../../components/form/SolafForm";
import MobilityForm from "../../../components/form/MobilityForm";

interface Props {
    property: Building;
    index: number;
}

const ProcessPropertyItem = ({ property, index }: Props) => {
    const [expanded, setExpanded] = useState<number | null>(null);
    const [currentExtraService, setCurrentExtraService] =
        useState<EExtraServices | null>(null);

    const handleChange =
        (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : null);
        };

    const accordionSummaryStyles: SxProps = {
        // background: "#fff",
        borderRadius: expanded === index ? "4px 4px 0 0" : "4px",
        height: "auto !important",
        p: 1,
        border: `1px solid ${enerbitColors.primary[200]}`,
        // minHeight: "70px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
        },
    };

    const accordionDetailsStyles: SxProps = {
        p: 1,
        px: 2,
        border:
            expanded === index
                ? `1px solid ${enerbitColors.primary[200]}`
                : null,
        borderRadius: "0 0 4px 4px",
    };

    const onServiceItemClick = (service: EExtraServices) => {
        setCurrentExtraService((prevService) =>
            prevService === service ? null : service,
        );
    };

    return (
        <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
                my: 2,
                boxShadow: "unset !important",
                borderRadius: "16px !important",
            }}
        >
            <AccordionSummary
                expandIcon={<AccordionButton expanded={expanded === index} />}
                sx={accordionSummaryStyles}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <HomeWorkRoundedIcon color="primary" />
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                }}
                            >
                                Propiedad {index + 1}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    color: enerbitColors.primary.main,
                                }}
                            >
                                {property.address}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        {property.extra_service?.mobility_service && (
                            <Chip
                                label="Movilidad"
                                color="success"
                                variant="outlined"
                                size="small"
                            />
                        )}
                        {property.extra_service?.solar_service && (
                            <Chip
                                label="Solar"
                                color="secondary"
                                variant="outlined"
                                size="small"
                            />
                        )}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked={
                                            property.has_energy_service
                                        }
                                        disabled
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: "16px" }}>
                                        ¿Esta propiedad cuenta con servicio de
                                        energía actualmente?
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Departamento
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.state}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.state} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Municipio
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.city}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.city} />
                                ),
                            }}
                        />
                    </Grid>
                    {property.has_energy_service &&
                        property.current_network_operator && (
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                                    Empresa de energía
                                </InputLabel>
                                <CustomTextField
                                    fullWidth
                                    disabled
                                    value={
                                        getInvoiceCompany(
                                            property.current_network_operator,
                                        ) ??
                                        getMarket(
                                            property.current_network_operator,
                                        )
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CopyButton
                                                text={
                                                    getInvoiceCompany(
                                                        property.current_network_operator,
                                                    ) ??
                                                    getMarket(
                                                        property.current_network_operator,
                                                    )
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                    {property.has_energy_service &&
                        property.invoice &&
                        property.invoice.document_url && (
                            <Grid item xs={12}>
                                <Box>
                                    <InputLabel
                                        sx={{ mb: 1, fontSize: "14px" }}
                                    >
                                        Factura empresa de energía
                                    </InputLabel>
                                    <FileContainer
                                        needAuth
                                        name="Factura empresa de energía"
                                        url={property.invoice.document_url}
                                    />
                                </Box>
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Dirección
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={property.address}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton text={property.address} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ mb: 1, fontSize: "14px" }}>
                            Ubicación del servicio
                        </InputLabel>
                        <CustomTextField
                            fullWidth
                            disabled
                            value={serviceLocationNames[property.service]}
                            InputProps={{
                                endAdornment: (
                                    <CopyButton
                                        text={
                                            serviceLocationNames[
                                                property.service
                                            ] as string
                                        }
                                    />
                                ),
                            }}
                        />
                    </Grid>
                    {!currentExtraService && (
                        <>
                            <Grid item xs={12} md={6}>
                                <ExtraServiceItem
                                    service={{
                                        name: "Monitoreo solar",
                                        iconName: "solar",
                                    }}
                                    onClick={() =>
                                        onServiceItemClick(EExtraServices.SOLAR)
                                    }
                                    index={index}
                                    setCurrentExtraService={
                                        setCurrentExtraService
                                    }
                                    disabled={
                                        !property.extra_service?.solar_service
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ExtraServiceItem
                                    service={{
                                        name: "Energía para vehículos eléctricos",
                                        iconName: "mobility",
                                    }}
                                    onClick={() =>
                                        onServiceItemClick(
                                            EExtraServices.MOBILITY,
                                        )
                                    }
                                    index={index}
                                    setCurrentExtraService={
                                        setCurrentExtraService
                                    }
                                    disabled={
                                        !property.extra_service
                                            ?.mobility_service
                                    }
                                />
                            </Grid>
                        </>
                    )}

                    {currentExtraService === EExtraServices.SOLAR &&
                        property.extra_service &&
                        property.extra_service.solar_service && (
                            <Grid item xs={12}>
                                <SolafForm
                                    setCurrentExtraService={
                                        setCurrentExtraService
                                    }
                                    solarService={
                                        property.extra_service.solar_service
                                    }
                                />
                            </Grid>
                        )}
                    {currentExtraService === EExtraServices.MOBILITY &&
                        property.extra_service &&
                        property.extra_service.mobility_service && (
                            <Grid item xs={12}>
                                <MobilityForm
                                    setCurrentExtraService={
                                        setCurrentExtraService
                                    }
                                    mobilityService={
                                        property.extra_service.mobility_service
                                    }
                                />
                            </Grid>
                        )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ProcessPropertyItem;

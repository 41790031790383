import { enerbitColors } from "@enerbit/base";
import ConnectionCardSkeleton from "./components/ConnectionCardSkeleton";
import SectionCardInformationSkeleton from "./components/SectionCardInformationSkeleton";

const LeftMenuSkeleton = () => {
  return (
    <>
      <ConnectionCardSkeleton />
      <SectionCardInformationSkeleton
        backgroundColor="#FFF1E6"
        numItems={11}
      />{" "}
      <SectionCardInformationSkeleton
        backgroundColor={`${enerbitColors?.primary[200]}`}
      />{" "}
      <SectionCardInformationSkeleton backgroundColor="#FFFDDB" numItems={5} />{" "}
      <SectionCardInformationSkeleton
        backgroundColor={`${enerbitColors?.primary[200]}`}
        numItems={5}
      />{" "}
    </>
  );
};

export default LeftMenuSkeleton;

import {
    Box,
    InputLabel,
    FormControl,
    SearchIcon,
    Button,
    IconButton,
    CachedIcon,
    useTheme,
    useMediaQuery,
    SelectChangeEvent,
    Popover,
    Checkbox,
    Chip,
    ListItemText,
    MenuItem,
} from "@enerbit/base";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Filters } from "../../../models";
import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import CustomSelect from "../../../components/Inputs/CustomSelect";

interface Props {
    filters: Filters;
    handleFiltersChange: (key: string, value: unknown) => void;
    resetFilters: () => void;
    handleSearch: () => void;
}

const extraServicesToNames: Record<string, string> = {
    has_mobility_service: "Movilidad eléctrica",
    has_solar_service: "Monitoreo solar",
};

const ProcessTableFilters = ({
    filters,
    handleFiltersChange,
    handleSearch,
    resetFilters,
}: Props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [extraServices, setExtraServices] = useState<string[]>(
        Object.keys(filters)
            .map((key) => {
                if (
                    (key === "has_mobility_service" &&
                        filters["has_mobility_service"]) ||
                    (key === "has_solar_service" &&
                        filters["has_solar_service"])
                ) {
                    return key;
                }
                return "";
            })
            .filter((item) => item !== ""),
    );
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFiltersClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const reset = () => {
        setExtraServices([]);
        resetFilters();
    };

    const handleChange = (
        event: SelectChangeEvent<unknown>,
        _child: React.ReactNode,
    ) => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const value = event.target.value as any;
        setExtraServices(typeof value === "string" ? value.split(",") : value);

        handleFiltersChange(
            "has_solar_service",
            value.includes("has_solar_service"),
        );
        handleFiltersChange(
            "has_mobility_service",
            value.includes("has_mobility_service"),
        );
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "end",
                justifyContent: "space-between",
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    flex: 1,
                }}
            >
                <Box sx={{ flex: isSmallScreen ? "none" : 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Id público</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.public_id}
                            onChange={(e) =>
                                handleFiltersChange("public_id", e.target.value)
                            }
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ flex: isSmallScreen ? "none" : 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Número de teléfono</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.entrypoint}
                            onChange={(e) =>
                                handleFiltersChange(
                                    "entrypoint",
                                    e.target.value,
                                )
                            }
                            InputProps={{
                                startAdornment: <LocalPhoneIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    alignItems: "center",
                    mt: isSmallScreen ? 2 : 0,
                }}
            >
                <Button
                    aria-describedby={id}
                    variant="outlined"
                    onClick={handleButtonClick}
                    size="small"
                    startIcon={<TuneIcon />}
                >
                    Filtros
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFiltersClose}
                    slotProps={{
                        paper: {
                            sx: {
                                width: "300px",
                                p: "12px",
                                height: "250px",
                                borderRadius: "8px",
                            },
                        },
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: isSmallScreen ? "none" : 1 }}>
                            <InputLabel sx={{ mb: 1 }}>Estado</InputLabel>
                            <FormControl fullWidth>
                                <CustomSelect
                                    fullWidth
                                    value={filters.status}
                                    onChange={(e) =>
                                        handleFiltersChange(
                                            "status",
                                            e.target.value as string,
                                        )
                                    }
                                >
                                    <MenuItem value={"in_progress"}>
                                        En progreso
                                    </MenuItem>
                                    <MenuItem value={"completed"}>
                                        Completado
                                    </MenuItem>
                                    <MenuItem value={"expired"}>
                                        Expirado
                                    </MenuItem>
                                </CustomSelect>
                            </FormControl>
                        </Box>
                        <Box sx={{ flex: isSmallScreen ? "none" : 1 }}>
                            <InputLabel sx={{ mb: 1 }}>
                                Servicios adicionales
                            </InputLabel>
                            <FormControl fullWidth>
                                <CustomSelect
                                    fullWidth
                                    multiple
                                    value={extraServices}
                                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                                    renderValue={(selected: any) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                            }}
                                        >
                                            {selected.map((value: string) => (
                                                <Chip
                                                    key={value}
                                                    label={
                                                        extraServicesToNames[
                                                            value
                                                        ]
                                                    }
                                                    variant="outlined"
                                                    color={
                                                        value ===
                                                        "has_solar_service"
                                                            ? "secondary"
                                                            : "success"
                                                    }
                                                    size="small"
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"has_solar_service"}>
                                        <Checkbox
                                            checked={extraServices.includes(
                                                "has_solar_service",
                                            )}
                                        />
                                        <ListItemText
                                            primary={"Monitoreo solar"}
                                        />
                                    </MenuItem>
                                    <MenuItem value={"has_mobility_service"}>
                                        <Checkbox
                                            checked={extraServices.includes(
                                                "has_mobility_service",
                                            )}
                                        />
                                        <ListItemText
                                            primary={"Movilidad eléctrica"}
                                        />
                                    </MenuItem>
                                </CustomSelect>
                            </FormControl>
                        </Box>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                handleSearch();
                                handleFiltersClose();
                            }}
                            size="small"
                        >
                            Filtrar
                        </Button>
                    </Box>
                </Popover>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    fullWidth={isSmallScreen}
                >
                    <SearchIcon />
                </Button>
                <IconButton
                    color="primary"
                    onClick={reset}
                    sx={{
                        width: isSmallScreen ? "100%" : "auto",
                        justifyContent: "center",
                    }}
                >
                    <CachedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ProcessTableFilters;

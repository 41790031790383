import {
    Box,
    Button,
    Checkbox,
    CloseIcon,
    CustomAlert,
    DatePicker,
    FormControl,
    LoadingButton,
    LocalizationProvider,
    Modal,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@enerbit/base";
import "../../../../styles/styles.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { ChangeEvent, useMemo, useState } from "react";
import PaginationContainer from "../../../../components/PaginationContainer";
import { StyledTableHeader } from "../../../../components/StyledTable";
import { AdaptedAssociatedMeters } from "../../../../models/smartbit";
import { refutigateMeters } from "../../../../services/device";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../../common/constants";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

interface Props {
    onClose: () => void;
    open: boolean;
}

const ELEMENTS_PER_PAGE = 5;

const RefustigationModal = ({ open, onClose }: Props) => {
    const meters = useSelector(
        (state: RootState) => state.associatedMeters.meters,
    );
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedMeters, setSelectedMeters] = useState<Set<string>>(
        new Set(),
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");

    const startIndex = useMemo(() => {
        return (currentPage - 1) * ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const endIndex = useMemo(() => {
        return startIndex + ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value);
    };

    const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
        const newSet = new Set(selectedMeters);
        meters.forEach((meter) => {
            if (event.target.checked) {
                newSet.add(meter.meterSerial);
            } else {
                newSet.delete(meter.meterSerial);
            }
        });
        setSelectedMeters(newSet);
    };

    const handleCheck = (
        event: ChangeEvent<HTMLInputElement>,
        meter: AdaptedAssociatedMeters,
    ) => {
        const newSet = new Set(selectedMeters);
        if (event.target.checked) {
            newSet.add(meter.meterSerial);
        } else {
            newSet.delete(meter.meterSerial);
        }
        setSelectedMeters(newSet);
    };

    const handleClose = () => {
        setSelectedMeters(new Set());
        setError(false);
        setSuccess(false);
        setDate("");
        onClose();
    };

    const onRefustigate = async () => {
        if (selectedMeters.size === 0 || !date) return;

        setLoading(true);
        setError(false);
        setSuccess(false);
        try {
            await refutigateMeters(date, Array.from(selectedMeters));
            setSuccess(true);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        color={"primary"}
                        sx={{ fontSize: "21px", fontWeight: 700 }}
                    >
                        Refustigar
                    </Typography>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        className="back-btn"
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Box my={2}>
                    <Typography
                        sx={{
                            paddingBottom: "10px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#3D4350",
                        }}
                    >
                        Elige la fecha
                    </Typography>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={date}
                                // maxDate={moment().format("YYYY-MM-DD")}
                                onChange={(value: any) => {
                                    if (value) {
                                        setDate(value.format("YYYY-MM-DD"));
                                    } else {
                                        setDate("");
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        placeholder: "",
                                        sx: {
                                            "& fieldset": {
                                                borderRadius: "12px",
                                            },
                                        },
                                        error: false,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <LoadingButton
                        onClick={onRefustigate}
                        loading={loading}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Refustigar
                    </LoadingButton>
                </Box>
                {error && (
                    <Box mb={1}>
                        <CustomAlert
                            severity="error"
                            text={
                                "Hubo un error enviando el comando de refustigación."
                            }
                            onClose={() => setError(false)}
                        />
                    </Box>
                )}
                {success && (
                    <Box mb={1}>
                        <CustomAlert
                            severity="success"
                            text={
                                "El comando de refustigación ha sido enviado."
                            }
                            onClose={() => setSuccess(false)}
                        />
                    </Box>
                )}
                <Typography mt={1}>
                    Selecciona los medidores que deseas refustigar.
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{ mt: 1, boxShadow: "unset !important" }}
                >
                    <Table>
                        <TableHead
                            sx={{
                                ".MuiTableCell-root": {
                                    padding: "unset !important",
                                },
                            }}
                        >
                            <StyledTableHeader>
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        checked={meters.every((meter) =>
                                            selectedMeters.has(
                                                meter.meterSerial,
                                            ),
                                        )}
                                        onChange={handleCheckAll}
                                    />
                                </TableCell>
                                <TableCell>Serial del medidor</TableCell>
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody
                            sx={{
                                ".MuiTableCell-root": {
                                    padding: "unset !important",
                                },
                            }}
                        >
                            {meters
                                .slice(startIndex, endIndex)
                                .map((meter, index) => (
                                    <TableRow
                                        key={`${index + 1}-${meter.meterSerial}`}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                checked={selectedMeters.has(
                                                    meter.meterSerial,
                                                )}
                                                onChange={(event) =>
                                                    handleCheck(event, meter)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {meter.meterSerial}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationContainer>
                    <Stack spacing={2}>
                        <Pagination
                            page={currentPage}
                            count={Math.round(
                                meters.length / ELEMENTS_PER_PAGE,
                            )}
                            shape="rounded"
                            onChange={handlePagination}
                        />
                    </Stack>
                </PaginationContainer>
            </Box>
        </Modal>
    );
};

export default RefustigationModal;

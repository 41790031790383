import { getServerErrorMessage } from "@enerbit/base/common/utils/serverErrorMessages";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  ServiceAgreementType,
  UpdateChangePlanEnum,
} from "../../helpers/HelpersPlanAdministration";
import { ErrorPayload, SuccessPayload } from "../../models/payloadTypes";
import {
  ChargeConditions,
  CreatePlansEnergyErrors,
  EnerbitServiceAgreement,
  IntervalWithEnd,
} from "../../models/plan-administration/PlanAdministration";
import {
  EnerbitServiceAgreementPlanCreate,
  UpdateInterval,
} from "../../models/plan-administration/PlanCreate";
import { MakitaState } from "../../models/states/MakitaState";
import { StateStorage } from "../../models/StateStorage";
import {
  getEnerbitElectricitySupplyServiceAgreements,
  getEnerbitElectricitySupplyServiceAgreementsId,
  postEnerbitElectricitySupplyServiceAgreements,
  updateChangePlan,
} from "../actions/electricity-supply-service/electricity-supply-service.actions";

const initialState: MakitaState = {
  planList: undefined,
  isLoadingCreatePlansEnergy: false,
  isLoadingListPlansEnergy: false,
  successCreatePlansEnergy: "",
  errorCreatePlansEnergy: "",
  isDoneCreatePlansEnergy: null,
  page: 0,
  pages: 0,
  size: 10,
  next_page: 0,
  enerbitServiceAgreement: null,
  isLoadingEnerbitServiceAgreements: false,
  paginationEnerbitServiceAgreements: undefined,
  enerbitServiceAgreementPlan: null,
  isLoadingUpdateChangePlan: false,
  successUpdateChangePlan: "",
  errorUpdateChangePlan: "",
  detailPlanEnergy: undefined,
  errorDetailPlanEnergy: null,
  isLoadingDetailPlanEnergy: false,
  startedAt: moment().startOf("month").toDate().toString(),
  serviceAgreementType: ServiceAgreementType.fijabit,
  intervals: [
    {
      start: "00:00:00",
      end: "24:00:00",
      duration: "24H",
      price: 0,
    },
  ],
};

export const makitaSlice = createSlice({
  name: "makitaState",
  initialState,
  reducers: {
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setResetVarsCreatePlans: (state) => {
      state.errorCreatePlansEnergy = "";
      state.successCreatePlansEnergy = "";
    },
    setResetVarsUpdatePlans: (state) => {
      state.isLoadingUpdateChangePlan = false;
      state.errorUpdateChangePlan = "";
      state.successUpdateChangePlan = "";
    },
    setEnerbitServiceAgreement: (
      state,
      action: PayloadAction<EnerbitServiceAgreement<ChargeConditions> | null>
    ) => {
      state.enerbitServiceAgreement = action.payload;
    },
    onEnerbitServiceAgreementChangedPlan: (
      state,
      action: PayloadAction<EnerbitServiceAgreement<ChargeConditions> | null>
    ) => {
      state.enerbitServiceAgreementPlan = action.payload;
    },
    onEnerbitStartedAtChanged: (
      state,
      action: PayloadAction<moment.Moment>
    ) => {
      state.startedAt = action.payload.toDate().toString();
    },
    setIntervals: (state, action: PayloadAction<IntervalWithEnd>) => {
      state.intervals = [...state.intervals, action.payload];
    },
    updateInterval: <K extends keyof IntervalWithEnd>(
      state: MakitaState,
      action: PayloadAction<UpdateInterval<K>>
    ) => {
      const { index, field, value } = action.payload;
      if (index >= 0 && index < state.intervals.length) {
        state.intervals[index][field] = value;
      }
    },
    updateServiceAgreementType: (
      state,
      action: PayloadAction<ServiceAgreementType>
    ) => {
      state.serviceAgreementType = action.payload;
    },
    deleteIntervalAtIndex: (state, action: PayloadAction<number>) => {
      let index = action.payload;
      state.intervals.splice(index, 1);
    },
    resetIntervals: (state: MakitaState) => {
      state.intervals = initialState.intervals;
    },
    resetStatusCreatePlansEnergy: (state) => {
      state.isDoneCreatePlansEnergy = false;
    },
    resetMakitaState: (state) => {
      const { isLoadingListPlansEnergy } = state;
      return {
        ...initialState,
        isLoadingListPlansEnergy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.pending,
        (state) => {
          state.isLoadingListPlansEnergy = true;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.fulfilled,
        (state, action) => {
          state.isLoadingListPlansEnergy = false;
          state.planList = action.payload;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.rejected,
        (state) => {
          state.isLoadingListPlansEnergy = false;
          state.planList = undefined;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreementsId.pending,
        (state) => {
          state.isLoadingDetailPlanEnergy = true;
          state.errorDetailPlanEnergy = null;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreementsId.fulfilled.type,
        (
          state,
          action: PayloadAction<
            SuccessPayload<EnerbitServiceAgreement<ChargeConditions>>
          >
        ) => {
          state.isLoadingDetailPlanEnergy = false;
          state.detailPlanEnergy = action.payload.data;
          state.errorDetailPlanEnergy = null;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreementsId.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingDetailPlanEnergy = false;
          state.detailPlanEnergy = undefined;
          const customErrorMessages = {
            404: "No se encontró el plan.",
            500: "Error del servidor. No fue encontrar el plan.",
          };
          state.errorDetailPlanEnergy = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      )
      .addCase(
        postEnerbitElectricitySupplyServiceAgreements.pending.type,
        (state) => {
          state.isLoadingCreatePlansEnergy = true;
          state.successCreatePlansEnergy = null;
          state.errorCreatePlansEnergy = null;
        }
      )
      .addCase(
        postEnerbitElectricitySupplyServiceAgreements.fulfilled.type,
        (
          state,
          action: PayloadAction<
            SuccessPayload<EnerbitServiceAgreementPlanCreate>
          >
        ) => {
          const customErrorMessages = {
            201: CreatePlansEnergyErrors.successCreatePlansEnergy,
            200: CreatePlansEnergyErrors.successCreatePlansEnergy,
          };
          state.successCreatePlansEnergy = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
          state.errorCreatePlansEnergy = null;
          state.isLoadingCreatePlansEnergy = false;
          state.isDoneCreatePlansEnergy = true;
        }
      )
      .addCase(
        postEnerbitElectricitySupplyServiceAgreements.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          const customErrorMessages = {
            500: "Error del servidor. No fue posible crear el plan.",
          };
          state.isLoadingCreatePlansEnergy = false;
          state.successCreatePlansEnergy = null;
          state.errorCreatePlansEnergy = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
          state.isDoneCreatePlansEnergy = false;
        }
      )
      .addCase(updateChangePlan.pending.type, (state) => {
        state.isLoadingUpdateChangePlan = true;
      })
      .addCase(
        updateChangePlan.fulfilled.type,
        (state, action: PayloadAction<string[]>) => {
          state.isLoadingUpdateChangePlan = false;
          if (action.payload.length > 0) {
            state.errorUpdateChangePlan =
              UpdateChangePlanEnum.errorUpdateChangePlan;
            state.successUpdateChangePlan = "";
          } else {
            state.successUpdateChangePlan =
              UpdateChangePlanEnum.successUpdateChangePlan;
            state.errorUpdateChangePlan = "";
          }
        }
      )
      .addCase(updateChangePlan.rejected.type, (state) => {
        state.isLoadingUpdateChangePlan = false;
        state.errorUpdateChangePlan =
          UpdateChangePlanEnum.errorUpdateChangePlan;
        state.successUpdateChangePlan = "";
      });
  },
});

export const {
  setResetVarsCreatePlans,
  setResetVarsUpdatePlans,
  handlePageChange,
  setEnerbitServiceAgreement,
  onEnerbitServiceAgreementChangedPlan,
  onEnerbitStartedAtChanged,
  setIntervals,
  updateInterval,
  updateServiceAgreementType,
  deleteIntervalAtIndex,
  resetIntervals,
  resetStatusCreatePlansEnergy,
  resetMakitaState,
} = makitaSlice.actions;

export const isLoadingListPlansEnergy = (state: StateStorage) =>
  state.makitaState.isLoadingListPlansEnergy;

export default makitaSlice.reducer;

import { Box, Grid, Skeleton, Typography, enerbitColors } from "@enerbit/base";
import React from "react";

export const ServicesPlanSkeleton: React.FC = () => {
  return (
    <>
      <Box className="Title-service-plan" sx={{ margin: "0 0 1.375rem 0" }}>
        <Typography color={enerbitColors.primary.main} fontSize={"28px"}>
          <Skeleton variant="text" width={200} height={40} />
        </Typography>
      </Box>

      <Box
        className="Container-services-plan-main"
        sx={{
          color: enerbitColors.primary.main,
          background: enerbitColors.neutral[100],
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              fontSize={"16px"}
              sx={{ color: enerbitColors.primary.main }}
            >
              <Skeleton variant="text" width={150} height={30} />
            </Typography>
            <Typography
              fontSize={"24px"}
              fontWeight={700}
              sx={{ color: enerbitColors.primary.main }}
            >
              <Skeleton variant="text" width={50} height={30} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="Detail-plan-box-service" sx={{ mt: "20px" }}>
        {[...Array(5)].map((_, index) => (
          <Grid key={index} container spacing={2} sx={{ mb: "16px" }}>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  className="Box-services-plan"
                  sx={{
                    background: enerbitColors.warning.main,
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    marginRight: "16px",
                  }}
                >
                  <Skeleton variant="circular" width={90} height={40} />
                </Box>

                <Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      color={"#4A4A4A"}
                      fontWeight={700}
                      fontSize={"16px"}
                    >
                      <Skeleton variant="text" width={100} height={30} />
                    </Typography>
                  </Box>
                  <Typography color={"#929292"}>
                    <Skeleton variant="text" width={200} height={20} />
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
              <Skeleton variant="rectangular" width={110} height={40} />
            </Grid>
          </Grid>
        ))}
        <Box
          sx={{ margin: "20px 0", display: "flex", justifyContent: "center" }}
        >
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={30}
              height={30}
              sx={{ margin: "0 8px", borderRadius: "8px" }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ServicesPlanSkeleton;

import {
    Box,
    Button,
    CustomAlert,
    LoadingButton,
    Modal,
    Typography,
} from "@enerbit/base";

interface Props {
    open: boolean;
    handleClose: () => void;
    errorMessage?: string;
    loading?: boolean;
    error?: boolean;
    setError?: React.Dispatch<React.SetStateAction<any>>;
    onConfirm: () => void;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
    width: "500px",
};

const ConfirmationModal = ({
    open,
    handleClose,
    error,
    setError,
    errorMessage,
    loading,
    onConfirm,
}: Props) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box>
                    <Typography
                        color="primary"
                        sx={{
                            fontSize: "28px",
                            textAlign: "center",
                            fontWeight: 400,
                        }}
                    >
                        ¿Está seguro de detener la recuperación de datos?
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: 400,
                            color: "#667085",
                        }}
                    >
                        <strong>Esta acción no se puede deshacer,</strong> y
                        solo verá los datos recuperados hasta este momento.
                    </Typography>
                    {error && setError && errorMessage && (
                        <CustomAlert
                            sx={{ my: 1 }}
                            severity="error"
                            onClose={() => setError(undefined)}
                            text={errorMessage}
                        />
                    )}
                    <Box sx={{ display: "flex", gap: 2, my: 2 }}>
                        <Button
                            fullWidth
                            color="secondary"
                            variant="outlined"
                            onClick={handleClose}
                            disabled={loading}
                        >
                            No
                        </Button>
                        <LoadingButton
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={onConfirm}
                            loading={loading}
                        >
                            Si
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;

import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
    FustigationsResponse,
    GetFustigationsFilters,
    SearchFilters,
} from "../../models/fustigations";
import moment from "moment";

const REQUEST_SIZE = 50;

export const getFustigations = createAsyncThunk(
    "fustigations/getFustigations",
    async (filters: GetFustigationsFilters, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const currentPage = state.fustigations.currentPage;

        const since = moment(filters.since)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ssZZ");
        const until = moment(filters.since)
            .add(1, "day")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ssZZ");

        const params: Record<string, any> = {
            page: currentPage,
            since,
            until,
            page_size: REQUEST_SIZE,
        };

        if (state.fustigations.searchValue) {
            params[state.fustigations.filter] = state.fustigations.searchValue;
        }

        const res = await api.get<FustigationsResponse>(
            `/fustigations/fustigations/failed-fustigations`,
            { params },
        );

        return res.data;
    },
);

import { configureStore } from "@reduxjs/toolkit";
import { frontiersSlice } from "./slices/frontiers";
import { measurementsSlice } from "./slices/measurements";
import { ordersHistorySlice } from "./slices/orders";
import { generationFrontier } from "./slices/generatingFrontiers";

export const store = configureStore({
    reducer: {
        frontiers: frontiersSlice.reducer,
        measurements: measurementsSlice.reducer,
        ordersHistory: ordersHistorySlice.reducer,
        generationFrontiers: generationFrontier.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Box, Button, CachedIcon, SearchIcon, TextField } from "@enerbit/base";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { listSmartBits, searchDevice } from "../../../store/thunks/generalInfo";
import CustomTextField from "../../../components/CustomTextField";

const Searchbar = () => {
    const [searchValue, setSearchValue] = useState<string>("");

    const dispatch = useDispatch<AppDispatch>();

    const handleSearch = () => {
        console.log(searchValue);
        if (!searchValue) return;

        dispatch(searchDevice(searchValue));
    };

    const handleReset = () => {
        setSearchValue("");
        dispatch(listSmartBits());
    };
    return (
        <Box sx={{ display: "flex", width: "700px" }}>
            <CustomTextField
                value={searchValue}
                fullWidth
                placeholder="Buscar"
                sx={{ borderRadius: "12px 0px 0px 12px !important" }}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                    sx: {
                        "& fieldset": {
                            borderRadius: "12px 0px 0px 12px !important",
                        },
                    },
                }}
            />
            <Button
                onClick={handleSearch}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0px 12px 12px 0px !important",
                }}
                variant="contained"
                color="primary"
            >
                <SearchIcon />
            </Button>
            <Button variant="contained" sx={{ ml: 2 }} onClick={handleReset}>
                <CachedIcon />
            </Button>
        </Box>
    );
};

export default Searchbar;

import type {
    IResponseInconsistencyData,
    IRowsInconsistency,
    ISwapperData,
    ItemRowData,
} from "../../interfaces";

export const formatData = (
    inconsistencyData: IResponseInconsistencyData,
): IRowsInconsistency[] => {
    const dataArray = inconsistencyData.items;
    const formatRows = dataArray.map((item, index: number) => ({
        id: item.data.assignee.lead?.[0].id || index,
        origin: "sistema",
        serial: item.data.meter.serie || "No registra",
        serial_certificate:
            item.data.assignee.lead?.[0].certificate[0]?.form_data
                ?.main_meter_installed?.meter_serial?.value || "No registra",
        type: "inconsistency",
        action: "Revisar",
        data: item,
    }));

    return formatRows as IRowsInconsistency[];
};

export const simpleFormData = (inconsistencyData: ItemRowData) => {
    const item = inconsistencyData;
    const formatRows = {
        id: item.data.assignee.lead?.[0].id || "No registra",
        origin: "sistema",
        serial: item.data.meter.serie || "No registra",
        serial_certificate:
            item.data.assignee.lead?.[0].certificate[0]?.form_data
                ?.main_meter_installed?.meter_serial?.value || "No registra",
        type: "inconsistency",
        action: "Revisar",
        data: item,
    };

    return [formatRows];
};

export const formatSwapperData = (swapperData: ISwapperData[]) => {
    const dataArray = swapperData;
    const formatRows = dataArray.map((item) => ({
        id: item.id,
        serial:
            item?.form_data?.swapper_meters?.swap_meter?.serial ||
            "No registra",
        report_serial:
            item?.form_data?.swapper_meters?.report_meter?.serial ||
            "No registra",
        observations:
            item?.form_data?.swapper_meters?.observation || "Sin observaciones",
        origin: "campo",
        type: "inconsistency",
        data: item,
    }));

    return formatRows;
};

import {
  Box,
  CircularProgress,
  CustomAlert,
  Typography,
  formatterPeso,
  theme,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../../../../models/store/StateStorage";

export const HeadTableInvoice = () => {
  const { totalOWed, isLoadingTotalOwed, errorTotalOwed } = useSelector(
    (state: StateStorage) => state.billing
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "28px", fontWeight: "700", mb: "10px" }}
        >
          Histórico de Facturación<span>&nbsp;</span>
        </Typography>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "18px", fontWeight: "400" }}
        >
          Elige el periodo que quieres consultar
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {errorTotalOwed ? (
          <CustomAlert
            severity="error"
            text={errorTotalOwed}
            onClose={() => null}
          />
        ) : isLoadingTotalOwed ? (
          <Box className="Loading-flex">
            <CircularProgress />
          </Box>
        ) : (
          <Box
            className="Container-outstanding-debts"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F2F4F7",
              borderRadius: "16px",
              padding: "16px",
            }}
          >
            <Box>
              <Typography
                color={theme.palette.primary.main}
                sx={{ fontSize: "18px", fontWeight: "700" }}
              >
                Deuda a la fecha
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                sx={{ fontSize: "28px", fontWeight: "700" }}
              >
                {formatterPeso.format(totalOWed?.total_owe ?? 0)}
              </Typography>
            </Box>
            {/* {totalOWed.total_owe > 0 && (
            <Box sx={{ marginLeft: "20px" }}>
              <Button
                color="secondary"
                variant="contained"
                sx={{ height: "90%" }}
              >
                <DownloadOutlinedIcon sx={{ fontSize: "18px" }} />
              </Button>
            </Box>
          )} */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

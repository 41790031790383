import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdaptedSignalResponse, ISignal } from "../../models/smartbit";
import {
    mapSignaNamesToValues,
    removeDuplicateHours,
} from "../../utils/device";

export const getDeviceSignal = createAsyncThunk(
    "signalCoverga/getDeviceSIgnal",
    async (id: string, { rejectWithValue }) => {
        try {
            const res = await api.get<ISignal[]>(
                `/verification/smartbits/signal?device_id=${id}`,
            );
            const signal: AdaptedSignalResponse[] = removeDuplicateHours(
                res.data,
            )
                .map((s) => ({
                    date: s.time_sender,
                    signalStatus: mapSignaNamesToValues(s.signal_status),
                }))
                .slice(-6);
            return { signal, operator: res.data[0].operator };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

import { Box, Typography, enerbitColors } from "@enerbit/base";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ConventionCardGeneration from "../../../common/Cards/ConventionCardGeneration";
import { ExpandDefinitions } from "../../../common/Cards/ExpandDefinitions";
import { SelectDatePickerOrRange } from "../../../common/Dates/SelectDatePickerOrRange";
import {
    GraphicFiltersByTimestamp,
    graphicFiltersByTimestamp,
} from "../../../const/Graphic";
import { useGraphicsStore } from "../../../store/Graphics";
import { useSolarManager } from "../../../store/SolarManager";
import { getInitDay, getNextDay } from "../../../utils/Date";
import GenerationCards from "./GenerationCards";
import { GraphicHig3 } from "./GraphicHig3";

export const GenerationVariables = () => {
    const methods = useForm<{
        since: string | null;
        until: string | null;
        since_month: string;
        until_month: string;
    }>({
        defaultValues: {
            since: null,
            until: null,
            since_month: "",
            until_month: "",
        },
        mode: "onChange",
    });

    const [period, setPeriod] = useState(GraphicFiltersByTimestamp.HOUR);

    const { cleanUsages, getUsages } = useGraphicsStore();
    const { solarService } = useSolarManager();

    useEffect(() => {
        methods.reset();
        cleanUsages();
    }, [period]);

    useEffect(() => {
        methods.setValue("since", getInitDay(new Date()));
        methods.setValue("until", getNextDay(new Date()));
    }, []);

    useEffect(() => {
        if (solarService) {
            getUsages({
                since: methods.watch("since")!,
                until: methods.watch("until")!,
                period_str: period,
                measurement_point_id: solarService?.measurement_point_id ?? "",
                parent_point_id: solarService?.parent_point_id ?? "",
            });
        }
    }, [solarService]);

    return (
        <>
            <div
                style={{
                    border: `1px solid ${enerbitColors.primary[200]}`,
                    borderRadius: "10px",
                    padding: "4px",
                    width: "100%",
                }}
            >
                <div>
                    <p
                        style={{
                            textAlign: "center",
                            color: enerbitColors.primary.main,
                            fontWeight: "bold",
                            fontSize: "20px",
                        }}
                    >
                        Información sobre la energía del Sistema FV
                    </p>
                </div>
                <FormProvider {...methods}>
                    <Box sx={{ mx: "2rem" }}>
                        <Box
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                backgroundColor: enerbitColors.neutral[100],
                                borderRadius: "16px",
                                p: 1,
                                cursor: "pointer",
                            }}
                        >
                            {graphicFiltersByTimestamp.map((filter, index) => (
                                <Box
                                    key={index}
                                    onClick={() => setPeriod(filter.value)}
                                    sx={{
                                        width: "33.33%",
                                        backgroundColor:
                                            filter.value === period
                                                ? "white"
                                                : "transparent",
                                        borderRadius:
                                            filter.value === period
                                                ? "16px"
                                                : "0",
                                        textAlign: "center",
                                        py: 0.3,
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontWeight="fontWeightBold"
                                        color="text.primary"
                                    >
                                        {filter.label}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <SelectDatePickerOrRange
                            period={period}
                            name={" "}
                            label={""}
                        />
                    </Box>
                </FormProvider>
                <GraphicHig3 type={period} />
            </div>
            <ExpandDefinitions body={<ConventionCardGeneration />} />
            <GenerationCards period={period} />
        </>
    );
};

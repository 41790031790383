import { PrivateRoute, ThemeConfig } from "@enerbit/base";

import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import States from "../pages/States";
import { persistor, store } from "../store/store";
export default function Routers() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PrivateRoute>
          <ThemeConfig>
            <section className="Container-root">
              <HashRouter>
                <Routes>
                  <Route path="states/:id" element={<States />} />
                </Routes>
              </HashRouter>
            </section>
          </ThemeConfig>
        </PrivateRoute>
      </PersistGate>
    </Provider>
  );
}

import {
    Box,
    ExpandLessIcon,
    ExpandMoreIcon,
    InfoIcon,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useState } from "react";

export const ExpandDefinitions = ({ body }: { body: JSX.Element }) => {
    const [expand, setExpand] = useState(false);
    return (
        <>
            <Box>
                <Box
                    sx={{
                        mt: 3,
                        padding: "16px 24px",
                        border: "1px solid #D9D2E6",
                        borderRadius: "12px 12px 0px 0px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <InfoIcon
                                sx={{
                                    color: enerbitColors.secondary.orange.main,
                                    mr: 1,
                                }}
                            />
                            <Typography
                                sx={{
                                    color: "#37235F",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                }}
                            >
                                Algunas definiciones que debes conocer
                            </Typography>
                        </Box>
                        <Box
                            role="toogle"
                            sx={{
                                background: enerbitColors.primary[100],
                                width: "25px",
                                height: "24px",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => setExpand(!expand)}
                        >
                            {expand ? (
                                <ExpandLessIcon
                                    sx={{
                                        color: enerbitColors.primary.main,
                                        fontSize: "18px",
                                    }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    sx={{
                                        color: enerbitColors.primary.main,
                                        fontSize: "18px",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {expand && (
                <Box
                    sx={{
                        background: "#F4F1FA",
                        borderRadius: "0px 0px 10px 10px",
                        padding: "16px 24px 6px 24px",
                    }}
                >
                    {body}
                </Box>
            )}
        </>
    );
};

import { enerbitColors } from "@enerbit/base";

export const conventions = [
    {
        title: "¿Qué es la energía importada o consumida de la red?",
        body: "Es la energía que consumes directamente de la red de distribución cuando los paneles no están generando.",
        color: "#12B76A",
    },
    {
        title: "¿Qué es la energía generada?",
        body: (
            <span>
                Es la energía que se produce en tus paneles solares o sistema
                fotovoltaico, gracias a la luz del sol. Este dato te muestra{" "}
                <b>
                    la generación real total, es decir, la suma de la energía
                    que autoconsumes y la que exportas a la red.
                </b>
            </span>
        ),
        color: "#FACB23",
    },
    {
        title: "¿Qué es la energía consumida?",
        body: (
            <span>
                Es la energía total que consumes, sin importar que venga de los
                paneles solares o de la red, y se mide en vatios o kilovatios
                hora (Wh o kWh). Este dato muestra{" "}
                <b>
                    el consumo real total, que es la suma de la energía que
                    autoconsumes del sistema fotovoltaico y la energía que
                    consumes de la red.
                </b>
            </span>
        ),
        color: "#F04438",
    },
    {
        title: "¿Qué es la energía autoconsumida?",
        body: "De la energía total que se consume en la propiedad, la energía autoconsumida es aquella que se toma directamente de los paneles solares que tienes instalados.",
        color: "#FF7705",
    },
    {
        title: "¿Qué es energía consumida de la red?",
        body: "Es la energía que consumes directamente de la red de distribución cuando los paneles no están generando.",
        color: "#55D4B6",
    },
    {
        title: "¿Qué es la energía neta?",
        body: (
            <span>
                Es el valor de energía que representa tu consumo neto y se
                calcula así ={" "}
                <b>
                    {" "}
                    energía exportada - energía importada o consumida de la red.{" "}
                </b>
            </span>
        ),
        color: "#438E96",
    },
    {
        title: "¿Qué es la energía exportada?",
        body: "Es la energía producida por tus paneles solares que no autoconsumes, y que se “entrega” a la red. Por esto se te ofrece como propietario del sistema un descuento en la factura de energía y/o incluso, un reconocimiento monetario.",
        color: "#3B82F6",
    },
];

export const generationConventions = [
    {
        title: "1. Energía generada",
        description: (
            <span>
                Es la energía que se produce en tus paneles solares o sistema
                fotovoltaico, gracias a la luz del sol. Este dato te muestra{" "}
                <b>
                    {" "}
                    la generación real total, es decir, la suma de la energía
                    que autoconsumes y la que exportas a la red.
                </b>
            </span>
        ),
        iconStyles: {
            background: "#E26A04",
            borderRadius: "50px",
            height: "16px",
            width: "16px",
        },
        titleColor: "#E26A04",
        isTitle: true,
    },
    {
        title: "1.1. Energía autoconsumida",
        description: `Es la energía solar generada por tu sistema fotovoltaico, y que consumes en la propiedad donde tienes instalados tus paneles, sea en tu casa o en tu empresa.`,
        iconStyles: {
            background: "#FFC390",
            border: "1px solid #FF7705",
            borderRadius: "50px",
            height: "12px",
            width: "12px",
        },
        titleColor: enerbitColors.neutral[700],
    },
    {
        title: "1.2. Energía exportada",
        description: `Es la energía producida por tus paneles solares que no autoconsumes, y que se “entrega” a la red. Por esto se te ofrece como propietario del sistema un descuento en la factura de energía y/o incluso, un reconocimiento monetario.`,
        iconStyles: {
            background: "#FF7705",
            border: "1px solid #EF730B",
            borderRadius: "50px",
            height: "12px",
            width: "12px",
        },
        titleColor: enerbitColors.neutral[700],
    },
    {
        title: "2. Energía consumida",
        description: (
            <span>
                Es la energía total que consumes, sin importar que venga de los
                paneles solares o de la red, y se mide en vatios o kilovatios
                hora (Wh o kWh). Este dato muestra{" "}
                <b>
                    el consumo real total, que es la suma de la energía que
                    autoconsumes del sistema fotovoltaico y la energía que
                    consumes de la red.
                </b>
            </span>
        ),
        iconStyles: {
            background: enerbitColors.primary.main,
            borderRadius: "50px",
            height: "16px",
            width: "16px",
        },
        titleColor: enerbitColors.primary.main,
        isTitle: true,
    },
    {
        title: "2.1. Energía autoconsumida",
        description: `De la energía total que se consume en la propiedad, la energía autoconsumida es aquella que se toma directamente de los paneles solares que tienes instalados.`,
        iconStyles: {
            background: enerbitColors.primary[200],
            border: "1px solid " + enerbitColors.primary[400],
            borderRadius: "50px",
            height: "12px",
            width: "12px",
        },
        titleColor: enerbitColors.neutral[600],
    },
    {
        title: "2.2. Energía consumida de la red",
        description: `Es la energía que consumes directamente de la red de distribución cuando los paneles no están generando.`,
        iconStyles: {
            background: enerbitColors.primary[400],
            border: "1px solid " + enerbitColors.primary.main,
            borderRadius: "50px",
            height: "12px",
            width: "12px",
        },
        titleColor: enerbitColors.neutral[600],
    },
];

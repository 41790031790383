import { Box, Chip, Divider, Grid, Typography } from "@enerbit/base";

import type { IDataCertificate, IEntity, TWarningType } from "../../interfaces";
import { CustomCard } from "./Card.styled";
import CertificateCards from "./CertificateCards";
import { EntityCard } from "./EntityCard";

interface IProps {
    subTitle: string;
    insideCardBorderColor?: string;
    certificateData?: IDataCertificate[];
    entityData?: IEntity[];
    type: TWarningType;
    id: string;
}

const SubCard = ({
    insideCardBorderColor,
    entityData,
    subTitle,
    certificateData,
    type,
}: IProps) => {
    return (
        <CustomCard borderColor={insideCardBorderColor}>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography sx={{ color: insideCardBorderColor }}>
                        {subTitle}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Box display="flex" justifyContent="flex-end">
                        <Chip
                            sx={{
                                backgroundColor:
                                    type === "swap"
                                        ? "error.100"
                                        : "warning.100",
                                color:
                                    type === "swap"
                                        ? "error.main"
                                        : "secondary.main",
                            }}
                            label={
                                type === "swap" ? "Trocado" : "Inconsistencia"
                            }
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider
                color={
                    insideCardBorderColor === "#f79009"
                        ? "secondary.main"
                        : "error.main"
                }
                sx={{
                    my: 2,
                }}
            />
            {certificateData
                ? certificateData.map((certificate, index) => (
                      <CertificateCards
                          key={index}
                          name={subTitle}
                          certificate={certificate}
                      />
                  ))
                : entityData &&
                  entityData.map((entity, index) => (
                      <EntityCard key={index} entity={entity} />
                  ))}
        </CustomCard>
    );
};

export default SubCard;

import { Box, Typography, enerbitColors } from "@enerbit/base";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import type { ICertificate, IEntity, TWarningType } from "../../interfaces";

import { CustomCard } from "./Card.styled";
import SubCard from "./SubCard";

interface IProps {
    meterSerial: string;
    subTitle: string;
    insideCardBorderColor?: string;
    entityData: IEntity[];
    certificateData: ICertificate[];
    type: TWarningType;
}

const CardMeter = ({
    subTitle,
    insideCardBorderColor,
    meterSerial,
    entityData,
    certificateData,
    type,
}: IProps) => {
    return (
        <CustomCard>
            <Box sx={SerialStyled}>
                <DnsOutlinedIcon sx={{ mr: 1 }} />
                <Typography color="inherit" fontWeight="inherit" variant="h6">
                    Serial del medidor: {meterSerial}
                </Typography>
            </Box>
            <SubCard
                subTitle={subTitle}
                insideCardBorderColor={insideCardBorderColor}
                entityData={entityData}
                type={type}
                id={entityData[0].id}
            />
            {certificateData &&
                certificateData?.map(
                    (certificate, index: number) =>
                        certificate && (
                            <SubCard
                                key={index}
                                subTitle={certificate?.name}
                                insideCardBorderColor={insideCardBorderColor}
                                certificateData={certificate?.data}
                                type={type}
                                id={certificate?.id}
                            />
                        ),
                )}
        </CustomCard>
    );
};

export default CardMeter;

//-----------------Styles------------------//

const SerialStyled = {
    background: enerbitColors.primary.main,
    borderRadius: "9px",
    p: 1,
    color: "#fff",
    fontWeight: "bold",
    display: "flex",
};

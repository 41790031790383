import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { api } from "@enerbit/base";
import {
    AdaptedEventsResponse,
    GetEventsRequest,
    ISmartBitEvents,
} from "../../models/events";
import moment from "moment";
import { formatDeviceEventMessage } from "../../utils/device";
import { SB_EVENTS } from "../../pages/SmartbitDetail/common/device";

export const getEvents = createAsyncThunk(
    "sbEvents/getEvents",
    async (
        { id, since, until }: GetEventsRequest,
        { rejectWithValue, dispatch, getState },
    ) => {
        const state = getState() as RootState;
        const { currentPage } = state.sbEvents;

        try {
            const res = await api.get<ISmartBitEvents>(
                `/verification/smartbits/events?device_id=${id}&since=${since}&until=${until}`,
            );
            const adaptedEvents: AdaptedEventsResponse[] = res.data.items.map(
                (event) => ({
                    name:
                        SB_EVENTS[event.event_types.code] ??
                        "Evento desconocido",
                    date: moment(event.timestamp)
                        .utcOffset("-05:00")
                        .format("YYYY-MM-DD HH:mm"),
                    description: formatDeviceEventMessage(event),
                }),
            );
            return {
                events: adaptedEvents,
                currentPage: res.data.page,
                totalPages: res.data.pages,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

import {
    Box,
    Button,
    CustomAlert,
    LoadingButton,
    Skeleton,
    Typography,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import Backbutton from "../../components/BackButton/Backbutton";
import { getEssByMp } from "../../services/frontiers";
import { setFrtDetail } from "../../store/slices/frontiers";
import { AppDispatch, RootState } from "../../store/store";
import { getFrontier } from "../../store/thunks/frontiers";
import DetailTabs from "./components/DetailTabs/DetailTabs";
import GeneralInformation from "./components/GeneralInformation/GeneralInformation";
import MeasuresChart from "./components/MeasuresChart/MeasuresChart";

const FrontierDetail = () => {
    const [isLoadingEssId, setIsLoadingEssId] = useState(true);
    const [essId, setEssId] = useState("");
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const { id } = useParams<{ id: string }>();
    const { frtDetail, loading, error } = useSelector(
        (state: RootState) => state.frontiers,
    );

    useEffect(() => {
        dispatch(setFrtDetail(null));
        if (!id) return;
        dispatch(getFrontier(id));
    }, []);

    const getEssId = async () => {
        setIsLoadingEssId(true);
        try {
            const essId = await getEssByMp(frtDetail?.measurement_point_id!);
            setEssId(essId);
        } catch (err) {
            return err;
        } finally {
            setIsLoadingEssId(false);
        }
    };

    useEffect(() => {
        getEssId();
    }, [frtDetail]);

    const goToEss = () => {
        navigateToUrl(`/#/electricity-supply-service/attention/${essId}`);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Backbutton onClick={() => navigate("/")} />
                    <Box>
                        <Typography
                            sx={{
                                color: "#53358E",
                                fontWeight: 700,
                                fontSize: "28px",
                            }}
                        >
                            {id}
                        </Typography>
                        <Typography sx={{ color: "#667085" }}>
                            Código SIC
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={goToEss}
                        disabled={!essId}
                        loading={isLoadingEssId}
                        sx={{ mr: 1 }}
                    >
                        ver servicio de energía
                    </LoadingButton>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigateToUrl("#/orders")}
                    >
                        Generar OS
                    </Button>
                </Box>
            </Box>
            {error && (
                <CustomAlert
                    text="Error consultando la información de esta frontera."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            <Box sx={{ mt: 2 }}>
                {loading && <Skeleton variant="rounded" height="197px" />}
                {!loading && <GeneralInformation />}
            </Box>
            {loading && (
                <Skeleton variant="rounded" height="484px" sx={{ mt: 2 }} />
            )}
            {frtDetail && !error && (
                <Box sx={{ mt: 2 }}>
                    <MeasuresChart />
                </Box>
            )}
            {loading && (
                <Skeleton variant="rounded" height="100px" sx={{ mt: 2 }} />
            )}
            {frtDetail && !error && (
                <Box sx={{ mt: 2 }}>
                    <DetailTabs />
                </Box>
            )}
        </Box>
    );
};

export default FrontierDetail;

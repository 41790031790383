import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    CloseIcon,
    CustomAlert,
    Typography,
    styled,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getMissingReadingRecords } from "../../store/thunks/frontiers";
import TableDaysFilter from "../../components/TableDaysFilter/TableDaysFilter";
import FrontiersTable from "../../components/FrontiersTable/FrontiersTable";
import ReadingRecordsListActions from "../../components/ReadingRecordsListActions/ReadingRecordsListActions";
import { updateSelectedFrts } from "../../store/slices/frontiers";

const ReadingRecordsList = () => {
    const frontiers = useSelector(
        (state: RootState) => state.frontiers.frontiers,
    );
    const loading = useSelector(
        (state: RootState) => state.frontiers.isLoading,
    );
    const days = useSelector((state: RootState) => state.frontiers.days);
    const includeFrt = useSelector(
        (state: RootState) => state.frontiers.includeFrt,
    );
    const selectedMeters = useSelector(
        (state: RootState) => state.frontiers.selectedFrts,
    );

    const dispatch = useDispatch<AppDispatch>();

    const [daysFilter, setDaysFilter] = useState<string>(days);
    const [frtFilter, setFrtFilter] = useState<boolean>(includeFrt);

    const getMissingRecords = () => {
        dispatch(getMissingReadingRecords(daysFilter));
    };

    const resetSelectedFrts = () => {
        dispatch(updateSelectedFrts([]));
    };

    useEffect(() => {
        getMissingRecords();
    }, []);

    return (
        <Box>
            <Typography
                mb={2}
                sx={{ fontSize: "28px", fontWeight: 700, color: "#53358E" }}
            >
                Registro de consumos y reportes a XM
            </Typography>
            <ReadingRecordsListActions daysFilter={daysFilter} />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 3,
                    gap: 3,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 5,
                    }}
                >
                    <StatusIndicator bgColor="#EFE9FB" fontColor="#53358E">
                        <Box sx={{ display: "flex" }}>
                            <Typography>Seleccionados: </Typography>
                            <Typography sx={{ ml: 1, fontWeight: 700 }}>
                                {selectedMeters.length}
                            </Typography>
                        </Box>
                        <Button
                            onClick={resetSelectedFrts}
                            sx={{
                                minWidth: "unset !important",
                                width: "24px",
                                height: "24px",
                                borderRadius: "50% !important",
                            }}
                        >
                            <CloseIcon sx={{ fontSize: "21px" }} />
                        </Button>
                    </StatusIndicator>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                        }}
                    >
                        <Typography>Estado: </Typography>
                        <StatusIndicator bgColor="#FEE4E2" fontColor="#F04438">
                            Incompleto
                        </StatusIndicator>
                        <StatusIndicator bgColor="#D1FADF" fontColor="#12B76A">
                            Completo
                        </StatusIndicator>
                    </Box>
                </Box>
                <Box>
                    <TableDaysFilter
                        days={daysFilter}
                        setDays={setDaysFilter}
                        frtFilter={frtFilter}
                        setFrtFilter={setFrtFilter}
                    />
                </Box>
            </Box>
            {frontiers.length > 0 && !loading && (
                <Box mt={3}>
                    <FrontiersTable days={days} filter={daysFilter} />
                </Box>
            )}
            {frontiers.length === 0 && !loading && (
                <Box my={4}>
                    <CustomAlert
                        severity="info"
                        text={"No hay fronteras para mostrar"}
                        onClose={() => {}}
                    />
                </Box>
            )}
            {loading && (
                <Box
                    my={4}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default ReadingRecordsList;

interface StatusColor {
    fontColor: string;
    bgColor: string;
}

const StatusIndicator = styled("div")<StatusColor>(
    ({ fontColor, bgColor }) => ({
        width: "180px",
        height: "32px",
        backgroundColor: bgColor,
        color: fontColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderRadius: "8px",
    }),
);

import {
  AdapterDayjs,
  AddIcon,
  Box,
  Button,
  CardPdf,
  Checkbox,
  CustomModal,
  DeleteOutlineIcon,
  DragZone,
  FieldArray,
  FormikProvider,
  Grid,
  InputLabel,
  LoadingButton,
  LocalizationProvider,
  RotateRightIcon,
  TextField,
  Typography,
  useFormik,
  yup,
} from "@enerbit/base";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateHoursDifference,
  isTotalHoursIntervalsValid,
  validateIntervals,
} from "../../../helpers/checkTimeIntervals";
import { getLabelServiceAgreementType } from "../../../helpers/GetLabelServiceAgreementType";
import { ServiceAgreementType } from "../../../helpers/HelpersPlanAdministration";
import {
  generateNextInterval,
  getInitialIntervals,
  validateIntervalsMinimum,
} from "../../../helpers/IntervalPlanHelpers";
import { CustomModalTypes } from "../../../models/ModalProps";
import {
  Interval,
  IntervalWithEnd,
} from "../../../models/plan-administration/PlanAdministration";
import { EnerbitServiceAgreementPlanCreate } from "../../../models/plan-administration/PlanCreate";
import { StateStorage } from "../../../models/StateStorage";
import {
  getEnerbitElectricitySupplyServiceAgreements,
  postEnerbitElectricitySupplyServiceAgreements,
} from "../../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import {
  deleteIntervalAtIndex,
  resetIntervals,
  setIntervals,
  setResetVarsCreatePlans,
  updateInterval,
  updateServiceAgreementType,
} from "../../../store/slices/makitaSlice";
import { AppDispatch } from "../../../store/store";
import { errorInputFields } from "../../../utils/ErrorInputFields";
import CustomError from "../../custom-error/CustomError";
import CustomTimePicker from "./ExtendedTimePicker";
import { TitleCreationCoverage } from "./TitleCreationCoverage";
interface CreateModalPlanProps {
  onClose: () => void;
}

const CreateModalPlan = forwardRef<CustomModalTypes, CreateModalPlanProps>(
  ({ onClose, ...props }, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const createClient = useRef<CustomModalTypes>(null);
    const [isCheckedTechnologicalTools, setIsCheckedTechnologicalTools] =
      useState(true);
    const {
      successCreatePlansEnergy,
      intervals,
      serviceAgreementType,
      isDoneCreatePlansEnergy,
    } = useSelector((state: StateStorage) => state.makitaState);

    useEffect(() => {
      dispatch(resetIntervals());
    }, [dispatch]);

    const handleCheckboxTechnologicalTools = (event: {
      target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
      const isChecked = event.target.checked;
      setIsCheckedTechnologicalTools(isChecked);
      if (isChecked) {
        formik.setFieldTouched("technologicalToolsBeforeIva", true);
      } else {
        formik.setFieldValue("technologicalToolsBeforeIva", 0);
        formik.setFieldTouched("technologicalToolsBeforeIva", false);
      }
    };

    useImperativeHandle(ref, () => ({
      changeModal() {
        if (createClient.current) {
          createClient.current.changeModal();
        }
      },
    }));

    const initialValues: EnerbitServiceAgreementPlanCreate & {
      intervals: IntervalWithEnd[];
    } = {
      name: "",
      description: "",
      service_agreement_type: ServiceAgreementType.fijabit,
      meterRentBeforeVAT: 0,
      priceCoverage: 0,
      technologicalToolsBeforeIva: 0,
      intervals: getInitialIntervals(serviceAgreementType, intervals),
      agreement_file: null,
    };

    const validationSchema = yup.object({
      checkedTypeCoverage: yup.boolean(),
      labels: yup.string(),
      name: yup.string().required(errorInputFields.inputRequired),
      description: yup.string().required(errorInputFields.inputRequired),
      meterRentBeforeVAT: yup
        .number()
        .integer(errorInputFields.inputWholeNumbersRequired)
        .required(errorInputFields.inputRequired)
        .min(0, errorInputFields.inputGreaterThanZeroRequired)
        .typeError(errorInputFields.inputNumberRequired),
      technologicalToolsBeforeIva: yup.number().when([], {
        is: () => isCheckedTechnologicalTools,
        then: yup
          .number()
          .integer(errorInputFields.inputWholeNumbersRequired)
          .required(errorInputFields.inputRequired)
          .min(0, errorInputFields.inputGreaterThanOrEqualZeroRequired)
          .typeError(errorInputFields.inputNumberRequired),
        otherwise: yup.number().notRequired(),
      }),
      priceCoverage: yup
        .number()
        .integer(errorInputFields.inputWholeNumbersRequired)
        .when("service_agreement_type", {
          is: (valServiceAgreementType: ServiceAgreementType) => {
            return valServiceAgreementType === ServiceAgreementType.fijabit;
          },
          then: (schema) =>
            schema
              .required(errorInputFields.inputRequired)
              .min(1, errorInputFields.inputGreaterThanZeroRequired)
              .typeError(errorInputFields.inputNumberRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      intervals: yup.array().when("service_agreement_type", {
        is: (valServiceAgreementType: ServiceAgreementType) => {
          return valServiceAgreementType === ServiceAgreementType.dinabit;
        },
        then: (schema) =>
          schema.of(
            yup.object().shape({
              price: yup
                .number()
                .required("El campo es requerido")
                .min(1, "El precio debe ser mayor a 0"),
            })
          ),
        otherwise: (schema) => schema,
      }),
      agreement_file: yup.mixed().required(errorInputFields.inputRequired),
    });

    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        values.intervals = intervals;

        // Eliminar el valor de technologicalToolsBeforeIva si el checkbox está desactivado
        if (!isCheckedTechnologicalTools) {
          delete values.technologicalToolsBeforeIva;
        }

        // Eliminar el valor de priceCoverage si el service_agreement_type es dinabit
        if (values.service_agreement_type === ServiceAgreementType.dinabit) {
          delete values.priceCoverage;
        }

        const handleSubmit = () => {
          dispatch(postEnerbitElectricitySupplyServiceAgreements(values))
            .then(() => {
              if (createClient.current) {
                createClient.current.changeModal();
                formik.resetForm();
              }
              dispatch(
                getEnerbitElectricitySupplyServiceAgreements({ pattern: "" })
              );
              if (onClose) {
                onClose();
              }
            })
            .catch((error) => {
              console.error("Error al enviar el formulario", error);
            });
        };

        if (values.service_agreement_type === ServiceAgreementType.dinabit) {
          if (validateIntervals(intervals).isValid) {
            handleSubmit();
          } else {
            console.error("No se puede enviar el formulario variable");
          }
        } else if (
          values.service_agreement_type === ServiceAgreementType.fijabit
        ) {
          handleSubmit();
        }

        validateIntervals(intervals);
      },
    });

    const filtersSelected = [
      {
        value: ServiceAgreementType.fijabit,
        icon: <FilterTiltShiftIcon />,
        component: <></>,
      },
      {
        value: ServiceAgreementType.dinabit,
        icon: <RotateRightIcon />,
        component: (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", paddingLeft: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TitleCreationCoverage title={"Hora de inicio"} />
                  </Grid>
                  <Grid item xs={4}>
                    <TitleCreationCoverage title={"Hora Final"} />
                  </Grid>
                  <Grid item xs={4}>
                    <TitleCreationCoverage title={"Valor"} />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    margin: "auto 0 auto 1rem",
                  }}
                ></Box>
              </Box>

              <FormikProvider value={formik}>
                <FieldArray
                  name="intervals"
                  validateOnChange={true}
                  render={(arrayHelpers) => (
                    <div>
                      {intervals.map(
                        (
                          itemIntervals: Interval & IntervalWithEnd,
                          index: number
                        ): JSX.Element => {
                          return (
                            <Box
                              key={index}
                              mt={2}
                              display="flex"
                              alignItems="center"
                              paddingLeft="1rem"
                            >
                              <Grid
                                container
                                spacing={2}
                                sx={{ alignItems: "center" }}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  sx={{ width: "100%" }}
                                  key={`start-${index}`}
                                >
                                  <CustomTimePicker
                                    value={parseInt(
                                      itemIntervals.start.split(":")[0],
                                      10
                                    )}
                                    onChange={(intervalsStart) => {
                                      const startValue =
                                        intervalsStart
                                          .toString()
                                          .padStart(2, "0") + ":00:00";
                                      dispatch(
                                        updateInterval({
                                          index,
                                          field: "start",
                                          value: startValue,
                                        })
                                      );
                                      dispatch(
                                        updateInterval({
                                          index,
                                          field: "duration",
                                          value: `${calculateHoursDifference({
                                            start: startValue,
                                            end: intervals[index].end,
                                          })}H`,
                                        })
                                      );
                                    }}
                                    sx={{ padding: "8px", width: "100%" }}
                                    ampm={false}
                                    minTime={0}
                                    maxTime={24}
                                    minutesStep={60}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{ width: "100%" }}
                                  key={`end-${index}`}
                                >
                                  <CustomTimePicker
                                    value={parseInt(
                                      itemIntervals.end.split(":")[0],
                                      10
                                    )}
                                    onChange={(intervalsEnd) => {
                                      const endValue =
                                        intervalsEnd
                                          .toString()
                                          .padStart(2, "0") + ":00:00";
                                      dispatch(
                                        updateInterval({
                                          index,
                                          field: "end",
                                          value: endValue,
                                        })
                                      );

                                      dispatch(
                                        updateInterval({
                                          index,
                                          field: "duration",
                                          value: `${calculateHoursDifference({
                                            start: intervals[index].start,
                                            end: endValue,
                                          })}H`,
                                        })
                                      );
                                    }}
                                    sx={{ padding: "8px", width: "100%" }}
                                    ampm={false}
                                    minTime={0}
                                    maxTime={24}
                                    minutesStep={60}
                                  />
                                </Grid>

                                <Grid item xs={4} key={`price-${index}`}>
                                  <NumericFormat
                                    className="Input-variable-value"
                                    id={`intervals[${index}].price`}
                                    name={`intervals[${index}].price`}
                                    value={itemIntervals.price}
                                    customInput={TextField}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      formik.setFieldTouched(
                                        `intervals[${index}].price`,
                                        true
                                      );
                                    }}
                                    onValueChange={(values) => {
                                      formik.setFieldValue(
                                        `intervals[${index}].price`,
                                        values.value
                                      );
                                      dispatch(
                                        updateInterval({
                                          index,
                                          field: "price",
                                          value: values.value,
                                        })
                                      );
                                    }}
                                    error={
                                      formik.touched.intervals?.[index]
                                        ?.price &&
                                      Array.isArray(formik.errors.intervals) &&
                                      Boolean(
                                        (formik.errors.intervals[index] as any)
                                          ?.price
                                      )
                                    }
                                    helperText={
                                      formik.touched.intervals?.[index]
                                        ?.price &&
                                      Array.isArray(formik.errors.intervals) &&
                                      (formik.errors.intervals[index] as any)
                                        ?.price
                                    }
                                    valueIsNumericString
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="$"
                                  />
                                </Grid>
                                <Grid item xs={4} sx={{ width: "100%" }}></Grid>
                              </Grid>

                              <Box
                                width="2rem"
                                height="3rem"
                                sx={{
                                  margin: "auto 0 auto 1rem",
                                }}
                                key={`price-${index}`}
                              >
                                <Button
                                  id="button-delete-interval"
                                  name="buttonDeleteInterval"
                                  color="error"
                                  disabled={validateIntervalsMinimum(intervals)}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    arrayHelpers.remove(index);
                                    dispatch(deleteIntervalAtIndex(index));
                                  }}
                                  className="Loading-button Button-delete-interval"
                                >
                                  <Box className="Container-delete-plan">
                                    <DeleteOutlineIcon />
                                  </Box>
                                </Button>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                      <Box>
                        {(() => {
                          const result = validateIntervals(intervals);
                          return (
                            result.type && (
                              <CustomError
                                message={result.message || "Unknown error"}
                              />
                            )
                          );
                        })()}
                      </Box>
                      <Box
                        className="Modal-buttons-container"
                        sx={{ paddingLeft: "1rem" }}
                      >
                        <Button
                          fullWidth
                          sx={{
                            marginTop: "20px",
                            paddingLeft: "1rem",
                          }}
                          variant="outlined"
                          disabled={
                            isTotalHoursIntervalsValid(intervals) ? true : false
                          }
                          color="primary"
                          startIcon={
                            <AddIcon className="Icon-item-add-interval" />
                          }
                          onClick={(e) => {
                            const intervalsDefault: IntervalWithEnd =
                              generateNextInterval(intervals);
                            arrayHelpers.push(intervalsDefault);
                            dispatch(setIntervals(intervalsDefault));
                            isTotalHoursIntervalsValid(intervals);
                          }}
                        >
                          Agregar otra franja horaria
                        </Button>
                      </Box>
                    </div>
                  )}
                />
              </FormikProvider>
            </LocalizationProvider>
          </>
        ),
      },
    ];

    const onDrop = useCallback((acceptedFiles: Blob[]): void => {
      formik.setFieldValue("agreement_file", acceptedFiles[0]);
    }, []);

    return (
      <CustomModal
        maxWidth="md"
        ref={createClient}
        onClose={() => {
          isDoneCreatePlansEnergy &&
            dispatch(
              getEnerbitElectricitySupplyServiceAgreements({ pattern: "" })
            );
          dispatch(setResetVarsCreatePlans());
          dispatch(resetIntervals());
          formik.resetForm();
          if (onClose) {
            onClose();
          }
        }}
        dialogContent={
          <form onSubmit={formik.handleSubmit}>
            <Box className="Header-plan" sx={{ fontWeight: "600" }}>
              Creación de un plan
            </Box>{" "}
            <InputLabel shrink className="Input-label">
              Nombre del plan
            </InputLabel>{" "}
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                id="Input-modal-name-creation-plan"
                name="name"
                placeholder="Nombre del plan"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <InputLabel shrink className="Input-label">
              Descripción
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                id="Input-modal-description-creation-plan"
                name="description"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Aquí va la descripción"
                sx={{ width: "100%", borderRadius: "14px" }}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>
            <InputLabel shrink className="Input-label">
              Valor del alquiler del medidor antes de IVA
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <NumericFormat
                id="Input-modal-fixed-value-creation-plan"
                name="meterRentBeforeVAT"
                placeholder="Valor númerico"
                sx={{ width: "100%", borderRadius: "14px" }}
                value={formik.values.meterRentBeforeVAT}
                onValueChange={(values) => {
                  formik.setFieldValue("meterRentBeforeVAT", values.value);
                }}
                thousandSeparator="."
                decimalSeparator=","
                prefix="$"
                allowLeadingZeros={false}
                decimalScale={0}
                customInput={TextField}
                error={
                  formik.touched.meterRentBeforeVAT &&
                  Boolean(formik.errors.meterRentBeforeVAT)
                }
                helperText={
                  formik.touched.meterRentBeforeVAT &&
                  formik.errors.meterRentBeforeVAT
                }
              />
            </Box>
            <Grid container spacing={2} sx={{ padding: "0.2rem 0 0 0" }}>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={isCheckedTechnologicalTools}
                  onChange={handleCheckboxTechnologicalTools}
                  className="Checkbox-makita"
                />
                <InputLabel
                  shrink
                  className="Input-label Input-label-technological-tools"
                >
                  Aplica de herramientas tecnológicas
                </InputLabel>
              </Grid>
              <Grid item xs={6} md={6}>
                <InputLabel
                  shrink
                  className="Input-label"
                  sx={{
                    color: isCheckedTechnologicalTools
                      ? "inherit"
                      : "rgba(0, 0, 0, 0.6) !important", // Cambia el color del texto dependiendo del estado del checkbox
                  }}
                  disabled={!isCheckedTechnologicalTools}
                >
                  Herramientas tecnológicas antes de IVA
                </InputLabel>
                <Box className="TextField-without-border-radius Text-field-filters">
                  <NumericFormat
                    disabled={!isCheckedTechnologicalTools}
                    id="Input-modal-fixed-value-creation-plan"
                    name="technologicalToolsBeforeIva"
                    placeholder="Valor númerico"
                    value={formik.values.technologicalToolsBeforeIva}
                    sx={{ width: "100%", borderRadius: "14px" }}
                    onValueChange={(values) => {
                      formik.setFieldValue(
                        "technologicalToolsBeforeIva",
                        values.value
                      );
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    allowLeadingZeros={false}
                    decimalScale={0}
                    customInput={TextField}
                    error={
                      formik.touched.technologicalToolsBeforeIva &&
                      Boolean(formik.errors.technologicalToolsBeforeIva)
                    }
                    helperText={
                      formik.touched.technologicalToolsBeforeIva &&
                      formik.errors.technologicalToolsBeforeIva
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Box>
              {/* //TODO: Agregar los estilos de esta clase al base */}
              <Typography className="Title-type-coverage">
                Elige la cobertura para este plan
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  {filtersSelected.map((selectedFilter, index) => (
                    <Grid item xs={6} md={6} key={index}>
                      <Box
                        id={
                          selectedFilter.value ===
                          formik.values.service_agreement_type
                            ? "Select-modal-fixed-coverage"
                            : "Select-modal-variable-coverage"
                        }
                        className={
                          selectedFilter.value ===
                          formik.values.service_agreement_type
                            ? "Card-coverage selected"
                            : "Card-coverage"
                        }
                        onClick={() => {
                          formik.setFieldValue(
                            "service_agreement_type",
                            selectedFilter.value
                          );
                          dispatch(
                            updateServiceAgreementType(selectedFilter.value)
                          );
                          dispatch(resetIntervals());
                        }}
                      >
                        <Box className="Icons-select-services">
                          <Box
                            sx={{
                              marginRight: "3.5rem",
                              color:
                                selectedFilter.value ===
                                formik.values.service_agreement_type
                                  ? "inherit"
                                  : "gray",
                            }}
                            className="Icon-makita-plan"
                          >
                            {selectedFilter.icon}
                          </Box>
                          <Box className="Label-search-filter">
                            {getLabelServiceAgreementType(selectedFilter.value)}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}

                  {filtersSelected.map((value, index) => (
                    <Box
                      sx={{
                        display:
                          value.value === formik.values.service_agreement_type
                            ? "block"
                            : "none",
                        width: "100%",
                      }}
                      key={index}
                    >
                      {" "}
                      {value.component}{" "}
                    </Box>
                  ))}
                </Grid>
              </Box>
            </Box>
            {formik.values.service_agreement_type ===
            ServiceAgreementType.fijabit ? (
              <>
                <InputLabel shrink className="Input-label">
                  Valor de la cobertura
                </InputLabel>
                <Box className="TextField-without-border-radius Text-field-filters">
                  <NumericFormat
                    id="Input-modal-coverage-value-creation-plan"
                    name="priceCoverage"
                    placeholder="Valor númerico"
                    sx={{ width: "100%", borderRadius: "14px" }}
                    onValueChange={(values) => {
                      formik.setFieldValue("priceCoverage", values.value);
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    allowLeadingZeros={false}
                    decimalScale={0}
                    customInput={TextField}
                    error={
                      formik.touched.priceCoverage &&
                      Boolean(formik.errors.priceCoverage)
                    }
                    helperText={
                      formik.touched.priceCoverage &&
                      formik.errors.priceCoverage
                    }
                  />
                </Box>
              </>
            ) : (
              <Box></Box>
            )}
            {
              <>
                <Box>
                  {formik.values.agreement_file ? (
                    <>
                      <CardPdf
                        // @ts-ignore //TODO quitar este ts ignore despues
                        id="Input-modal-agreement_file-value-creation-plan"
                        name="agreement_file"
                        typeFile=".md"
                        isSuccess={true}
                        bgColor="success"
                        isClearFile={true}
                        color="error"
                        fileInfo={formik.values.agreement_file}
                        onClearFile={() =>
                          formik.setFieldValue("agreement_file", null)
                        }
                      />
                    </>
                  ) : (
                    <DragZone
                      // @ts-ignore //TODO quitar este ts ignore despues
                      onDrop={onDrop}
                      color="success"
                      typeFile="md"
                      // @ts-ignore //TODO quitar este ts ignore despues
                      error={
                        formik.touched.agreement_file &&
                        formik.errors.agreement_file
                      }
                    />
                  )}
                  <Typography sx={{ color: "red" }}>
                    {formik.touched.agreement_file &&
                      formik.errors.agreement_file}
                  </Typography>
                </Box>
              </>
            }
            <Box className="Modal-buttons-container">
              <Button
                id="Button-modal-cancel"
                name="buttonModalCancel"
                color="warning"
                size="small"
                className="Modal-button-cancel"
                onClick={() => {
                  createClient.current?.changeModal();
                }}
                sx={{ width: "49%", height: "2.5625rem" }}
              >
                <Box style={{ fontWeight: "bold", margin: 0 }}>Cancelar</Box>
              </Button>

              <LoadingButton
                id="Button-modal-save"
                type="submit"
                name="buttonModalSave"
                color="warning"
                size="small"
                className={
                  successCreatePlansEnergy
                    ? `Loading-button Modal-button-logout button-disabled`
                    : `Loading-button Modal-button-logout`
                }
                sx={{ width: "49%", height: "2.5625rem" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    color: "white",
                  }}
                >
                  Guardar
                </Typography>
              </LoadingButton>
            </Box>
          </form>
        }
      />
    );
  }
);

export default CreateModalPlan;

import { HashRouter, Route, Routes } from "react-router-dom";
import Fustigations from "../pages/Fustigations";

const Router = () => {
    return (
        <HashRouter basename="/fustigations">
            <Routes>
                <Route path="/" element={<Fustigations />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;

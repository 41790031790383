import {
    Box,
    Button,
    DataGrid,
    Grid,
    InputAdornment,
    Pagination,
    SearchIcon,
    TextField,
} from "@enerbit/base";
import { useEffect, useState } from "react";

import { customDataGrid } from "../components/table/Table.styled";
import { columns } from "../const";
import { useGet } from "../hooks";
import { IRowsInconsistency } from "../interfaces";
import { getInconsistencies, getInconsistenciesBySerial } from "../services";
import { useInconsistenciesStore } from "../store/inconsistencies.store";

interface IRowData {
    rows: IRowsInconsistency[];
    total: number;
}

const InconsistenciesTable = () => {
    const { rows, setRows } = useInconsistenciesStore();

    const [page, setPage] = useState<number>(
        Number(localStorage.getItem("page")) || 0,
    );

    const [isSearch, setIsSearch] = useState(false);
    const [serial, setSerial] = useState<string>("");

    const { isLoading, data, isSuccess } = useGet<IRowData, number>(
        "inconsistencies",
        getInconsistencies,
        page,
    );

    useEffect(() => {
        localStorage.setItem("page", page.toString());
    }, [page]);

    const {
        isLoading: isLoadingSerial,
        data: dataSerial,
        isSuccess: isSuccessSerial,
    } = useGet<IRowData, string>(
        "simple-inconsistencies",
        getInconsistenciesBySerial,
        serial,
        isSearch && serial.trim() !== "",
    );

    const pageCount = Math.ceil((rows?.total || 0) / 10);

    const handlePageChange = (_: unknown, value: number) => {
        setPage(value - 1);
    };

    useEffect(() => {
        if (isSuccess && data && !isSearch && !serial) {
            setRows(data);
        }
    }, [isSuccess, data, isSearch, serial]);

    useEffect(() => {
        if (isSuccessSerial && dataSerial) {
            setRows(dataSerial);
            setIsSearch(false);
        }
    }, [isSuccessSerial, dataSerial]);

    const searchSerial = () => {
        if (serial.trim() !== "") {
            setIsSearch(true);
        }
    };

    useEffect(() => {
        if (!serial) {
            setIsSearch(false);
        }
    }, [serial]);

    return (
        <>
            <Box sx={{ display: "flex", mb: 3 }}>
                <TextField
                    sx={{ width: "300px", mr: 2 }}
                    size="small"
                    placeholder="Buscar por serial del medidor"
                    value={serial}
                    onChange={({ target }) => setSerial(target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ height: "36px" }}
                    onClick={searchSerial}
                >
                    Buscar
                </Button>
            </Box>

            <Grid item xs={12}>
                <DataGrid
                    sx={customDataGrid}
                    loading={isLoading || isLoadingSerial}
                    pagination
                    autoHeight
                    disableSelectionOnClick
                    rows={rows?.rows || []}
                    columns={columns}
                    pageSize={10}
                    rowCount={rows?.total}
                    paginationMode="server"
                    hideFooterPagination
                />
            </Grid>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ p: 1.2, mt: 1 }}
            >
                <Pagination
                    shape="rounded"
                    color="primary"
                    count={pageCount}
                    page={page + 1}
                    onChange={handlePageChange}
                />
            </Grid>
        </>
    );
};

export default InconsistenciesTable;

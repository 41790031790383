import { api } from "@enerbit/base";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../common/frontiers";
import { CreateGeneratingFrontier } from "../models/frontiers";

export const createGenerationFrontier = async (
    data: CreateGeneratingFrontier,
) => {
    await api.post("/frontiers/agpe-frontiers/agpe-frontier/", undefined, {
        params: {
            ...data,
            export_energy_start_datetime: moment(
                data.export_energy_start_datetime,
            ).format(DATE_TIME_FORMAT),
            back_up_agreement_power_capacity: data.back_up_agreement
                ? data.back_up_agreement_power_capacity
                : 0,
        },
    });
};

export const getEssByMp = async (mp: string) => {
    const { data } = await api.get(
        `/electricity-supply-service/manager/electricity-supply-services/?measurement_point_id=${mp}`,
    );
    return data.items[0].id;
};

import {
    api,
    Box,
    Button,
    CardPdf,
    DownloadOutlinedIcon,
    DragZone,
    enerbitColors,
    FormHelperText,
    InputLabel,
    Typography,
} from "@enerbit/base";
import { useCreateZeroEstimationContext } from "../../../../context/create-zero-estimation";
import { DropEvent, FileRejection } from "react-dropzone";

const UploadFile = () => {
    const { form } = useCreateZeroEstimationContext();
    const {
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = form;

    const onDrop = (
        acceptedFiles: File[],
        _fileRejections: FileRejection[],
        _event: DropEvent,
    ) => {
        setValue("file", acceptedFiles[0]);
    };

    const onClearFile = () => {
        setValue("file", undefined);
    };

    const handleDownload = async () => {
        try {
            const response = await api.get(
                "/estimations/download-estimated-batch-template",
                {
                    responseType: "blob",
                },
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "estimaciones_en_cero_template.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <InputLabel required>Subir excel</InputLabel>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={handleDownload}
                    startIcon={<DownloadOutlinedIcon />}
                >
                    Descargar plantilla de excel
                </Button>
            </Box>
            <Box sx={{ my: 1 }}>
                <Typography
                    sx={{
                        color: enerbitColors.neutral[600],
                        fontWeight: 700,
                    }}
                >
                    Importante: Recuerda que el archivo que sea cargado debe
                    tener como máximo 100 lineas (sin contar la cabecera) para
                    evitar demoras y fallos al momento de crear las
                    estimaciones.
                </Typography>
            </Box>
            {!!errors?.file && errors.file.message && (
                <FormHelperText error>{errors.file.message}</FormHelperText>
            )}
            {watch("file") && (
                <CardPdf
                    fileInfo={getValues("file")}
                    onClearFile={onClearFile}
                    isSuccess={true}
                    progress={100}
                    typeFile={"xlsx"}
                    isClearFile={true}
                />
            )}
            {!watch("file") && <DragZone typeFile="xlsx,csv" onDrop={onDrop} />}
        </Box>
    );
};

export default UploadFile;

import {
    Box,
    Button,
    DownloadOutlinedIcon,
    Loader,
    enerbitColors,
} from "@enerbit/base";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { convertToEnergy } from "../../../classes/Energy";
import { GraphicFiltersByTimestamp } from "../../../const/Graphic";
import { IEnergyAccumulate } from "../../../models/usages/Usages";
import { useGraphicsStore } from "../../../store/Graphics";
import {
    StackedDataPoint,
    getColorStroke,
    getEjex,
    getTextY,
    hideTooltip,
    showTooltip,
} from "../../../utils/Graphic";
import { downloadUsages } from "../../../utils/reports";
import { GraphicFilters } from "./GraphicFilters";

export interface DataPoint extends IEnergyAccumulate {
    active_energy_selfconsumption_negative: number;
}
export const GraphicHig3 = ({
    type,
}: {
    type:
        | GraphicFiltersByTimestamp.HOUR
        | GraphicFiltersByTimestamp.DAY
        | GraphicFiltersByTimestamp.MONTH;
}) => {
    const chartRef = useRef<SVGSVGElement | null>(null);

    const { usages, isLoading, filters } = useGraphicsStore();

    useEffect(() => {
        const renderChart = () => {
            // Limpiar el contenido existente
            d3.select(chartRef.current).selectAll("*").remove();

            // Subgrupos para datos positivos y negativos
            const subgroupsPositivos = [
                "active_energy_selfconsumption",
                "conventional_active_energy_exported",
            ];
            const subgroupsNegativos = [
                "active_energy_selfconsumption_negative",
                "conventional_active_energy_imported",
            ];

            const unit =
                Math.max(
                    d3.max(usages, (d) =>
                        Math.max(
                            d.active_energy_selfconsumption +
                                d.conventional_active_energy_exported,
                        ),
                    )!,
                    d3.max(usages, (d) =>
                        Math.max(
                            d.active_energy_selfconsumption +
                                d.conventional_active_energy_imported,
                        ),
                    )!,
                ) > 1000
                    ? "kWh"
                    : "Wh";

            // Reformateo de datos basado en filtros
            const data =
                usages?.map((d) => ({
                    ...d,
                    active_energy_selfconsumption: convertToEnergy({
                        value: filters.active_energy_selfconsumption
                            ? d.active_energy_selfconsumption
                            : 0,
                        fixedUnit: unit,
                    }).value,
                    conventional_active_energy_exported: convertToEnergy({
                        value: filters.conventional_active_energy_exported
                            ? d.conventional_active_energy_exported
                            : 0,
                        fixedUnit: unit,
                    }).value,
                    active_energy_selfconsumption_negative: convertToEnergy({
                        value: filters.active_energy_selfconsumption_negative
                            ? -Math.abs(d.active_energy_selfconsumption)
                            : 0,
                        fixedUnit: unit,
                    }).value,
                    conventional_active_energy_imported: convertToEnergy({
                        value: filters.conventional_active_energy_imported
                            ? -Math.abs(d.conventional_active_energy_imported)
                            : 0,
                        fixedUnit: unit,
                    }).value,
                })) ?? [];

            const maxValuePos = d3.max(data, (d) =>
                Math.max(
                    d.active_energy_selfconsumption +
                        d.conventional_active_energy_exported,
                ),
            )!;

            const maxValueNeg = d3.max(data, (d) =>
                Math.max(
                    d.active_energy_selfconsumption_negative * -1 +
                        d.conventional_active_energy_imported * -1,
                ),
            );

            // Dimensiones del gráfico
            const margin = { top: 20, right: 30, bottom: 50, left: 60 };
            const barWidth = 70;
            const containerWidth =
                (chartRef.current?.parentElement?.clientWidth || 0) -
                margin.left -
                margin.right;
            const scrollWidth =
                data.length > 17 ? data.length * barWidth : containerWidth;
            const width = scrollWidth - margin.left - margin.right;
            const height = 550 - margin.top - margin.bottom;

            // Escalas
            const x = d3
                .scaleBand()
                .domain(data.map((d) => getEjex(d, type)))
                .range([0, width])
                .padding(0.2);

            const y = d3
                .scaleLinear()
                .domain([maxValueNeg! * -1.1, maxValuePos * 1.1])
                .range([height, 0]);

            const color = d3
                .scaleOrdinal()
                .domain([...subgroupsPositivos, ...subgroupsNegativos])
                .range(["#FFC390", "#FF7705D9", "#D9D2E6", "#B3A5CD"]);

            const svgY = d3
                .select(chartRef.current)
                .append("svg")
                .attr("width", margin.left + 10) // Space reserved for the Y-axis
                .attr("height", height + margin.top + margin.bottom - 10) // Height for Y-axis and label
                .style("position", "absolute") // Ensure Y-axis is fixed
                .append("g")
                .attr("transform", `translate(${margin.left + 10}, 0)`);

            const svg = d3
                .select(chartRef.current)
                .append("div")
                .attr("class", "graphic-container")
                .style("overflow-x", "scroll") // Enable horizontal scrolling
                .style("overflow-y", "hidden") // Disable vertical scrolling
                .style("width", `${containerWidth}px`) // Width of visible container
                .style("margin-top", "20px")
                .style("margin-left", `${data.length > 17 ? "70px" : "90px"}`);

            const svgContent = svg
                .append("svg") // Separate SVG element for content
                .attr(
                    "width",
                    `${data.length > 17 ? scrollWidth - 60 : scrollWidth}`,
                ) // Content width (adjusted for margin)
                .attr("height", height + margin.top + margin.bottom - 10); // Height for Y-axis and label

            const g = svgContent
                .append("g") // Group for content elements
                .attr(
                    "transform",
                    `translate(${margin.left - 60}, ${margin.top})`,
                );

            // Render X-axis (within g for scrolling)
            g.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(x))
                .selectAll("path, line")
                .remove();

            // Text "Hora" (outside g for fixed position)
            svg.append("text")
                .attr("x", containerWidth) // Centrar texto relativo al área visible
                .attr("y", height + 45) // Position below the X-axis
                .attr("text-anchor", "middle")
                .text(getTextY(type))
                .attr("class", "fixed-text");

            // Y-axis rendering (remains the same)
            svgY.append("g")
                .call(d3.axisLeft(y))
                .selectAll("path, line")
                .remove();

            svgY.append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "rotate(-90)")
                .attr("x", -height / 2)
                .attr("y", -margin.left + 30)
                .text(unit)
                .style("fill", "#53358E")
                .style("font-weight", "bold")
                .style("font-size", "12px");

            // Render bars
            const renderBars = (
                stackedData: d3.Series<unknown, unknown>[],
                isNegative: boolean,
            ) => {
                svgContent
                    .append("g")
                    .selectAll<SVGGElement, StackedDataPoint>("g")
                    .data(stackedData)
                    .enter()
                    .append("g")
                    //@ts-ignore
                    .attr("fill", (d) => color(d.key))
                    .attr("stroke", (d) => getColorStroke(d.key as string))
                    .selectAll<SVGRectElement, unknown>("rect")
                    .data((d) => d)
                    .enter()
                    .append("rect")
                    .attr(
                        "x",
                        (d) => x(getEjex(d.data as DataPoint, type)) ?? 0,
                    )
                    .attr("y", height)
                    .attr("height", 0)
                    .attr("width", x.bandwidth())
                    .on("mouseover", (event, d) =>
                        showTooltip(
                            event,
                            d as StackedDataPoint,
                            isNegative,
                            type,
                            unit,
                        ),
                    )
                    .on("mouseleave", hideTooltip)
                    .transition()
                    .duration(800)
                    .attr("y", (d) => y(d[isNegative ? 0 : 1]))
                    .attr(
                        "height",
                        (d) =>
                            y(d[isNegative ? 1 : 0]) - y(d[isNegative ? 0 : 1]),
                    )
                    .attr("width", x.bandwidth());
            };

            const stackedDataPos = d3.stack().keys(subgroupsPositivos)(
                data as Iterable<{ [key: string]: number }>,
            );
            const stackedDataNeg = d3.stack().keys(subgroupsNegativos)(
                data as Iterable<{ [key: string]: number }>,
            );

            renderBars(stackedDataPos, false);
            renderBars(stackedDataNeg, true);

            // Líneas horizontales
            svgContent
                .append("g")
                .selectAll("line")
                .data(y.ticks().filter((tick) => tick !== 0))
                .enter()
                .append("line")
                .attr("x1", 0)
                .attr("x2", width)
                .attr("y1", (d) => y(d))
                .attr("y2", (d) => y(d))
                .style("stroke", "#ccc")
                .style("stroke-dasharray", "3,3");
        };

        renderChart();
    }, [usages, filters, chartRef, type]);

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Loader message={""} />
            </div>
        );
    }

    if (!isLoading && (!usages || usages?.length === 0))
        return (
            <p
                style={{
                    textAlign: "center",
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                No hay datos para mostrar
            </p>
        );

    return (
        <>
            <Box ref={chartRef} />
            <GraphicFilters />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DownloadOutlinedIcon />}
                    sx={{
                        padding: "0.3rem 1.1rem !important",
                        mr: 2,
                        mt: 1,
                        mb: 3,
                    }}
                    onClick={() => downloadUsages(usages)}
                >
                    Descargar CSV
                </Button>
            </Box>
        </>
    );
};

import { api } from "@enerbit/base";
import {
    Agenda,
    IAgendaDetail,
    IPaginatedResponse,
    SearchAgendaParams,
} from "../models";

export const searchAgendasService = async ({
    page,
    filters,
}: SearchAgendaParams) => {
    try {
        const params: Record<string, unknown> = {
            size: 50,
            page,
            sort_created_at: "DESC",
        };

        if (filters) {
            Object.keys(filters).map((key) => {
                params[key] = filters[key];
            });
        }

        const { data } = await api.get<IPaginatedResponse<Agenda>>(
            "/conversion/agendas",
            { params },
        );
        return data;
    } catch (_error) {
        throw new Error("ErrSearchingAgendas");
    }
};

export const getAgendaDetail = async (id: string) => {
    try {
        const { data } = await api.get<IAgendaDetail>(
            `/conversion/agendas/${id}`,
        );
        return data;
    } catch (_error) {
        throw new Error("ErrSearchingAgendas");
    }
};

import {
  enerbitColors,
  LocationCityIcon,
  PersonSearchOutlinedIcon,
  SpeakerNotesOutlinedIcon,
  theme,
} from "@enerbit/base";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import { useDispatch } from "react-redux";
import { navigateToUrl } from "single-spa";

import { updatedUserServices } from "../../models/accounts/ServicesAccount";
import { setIndexTab } from "../../store/slices/informationSlice";
import { AppDispatch } from "../../store/store";
import SectionCardInformation from "../CardInformationGeneral/SectionCardInformation";
interface ShowUserInformationProps {
  selectedAppUser: updatedUserServices[];
  hasAppUsers: boolean;
}

export const ShowUserInformation: React.FC<ShowUserInformationProps> = ({
  selectedAppUser,
  hasAppUsers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  if (!selectedAppUser || !Array.isArray(selectedAppUser) || !hasAppUsers) {
    return null;
  }

  const user = selectedAppUser.find((user) => user);

  return (
    <SectionCardInformation
      titleText={"Información del usuario"}
      titleColor={theme.palette.primary.main}
      bodyColor={enerbitColors?.neutral[700]}
      background={enerbitColors.neutral[100]}
      IconTitle={LocationCityIcon}
      showButtonsBottom={[
        {
          textButton: "Ver más detalle",
          shouldShowButtonBottom: true,
          onClick: () => {
            navigateToUrl(
              "/#/services-account?q=" +
                user?.user_id +
                "&serviceGroup=" +
                user?.service_account_id
            );
          },
          Icon: SpeakerNotesOutlinedIcon,
        },
      ]}
      showButtonTop={false}
      onClick={() => {
        dispatch(setIndexTab(2));
      }}
      data={[
        {
          label: "ID del usuario:",
          value: user?.user_id || "",
          Icon: PersonSearchOutlinedIcon,
        },
        {
          label: "N° identificación:",
          value: user?.legalIdCode || "",
          Icon: AccountBoxOutlinedIcon,
        },
        {
          label: "Email:",
          value: user?.email || "",
          Icon: MarkEmailUnreadOutlinedIcon,
        },
        {
          label: "Número contacto:",
          value: user?.phone || "",
          Icon: AccountBoxOutlinedIcon,
        },
      ]}
    />
  );
};

import {
    Modal,
    Box,
    Typography,
    Button,
    CloseIcon,
    LocalizationProvider,
    TextField,
    CustomAlert,
    LoadingButton,
    DatePicker,
    FormControl,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack,
    Pagination,
    AdapterMoment,
} from "@enerbit/base";
import { useMemo, useState } from "react";
import { refustigateMeter } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import moment from "moment";
import PaginationContainer from "../PaginationContainer/PaginationContainer";

interface Props {
    open: boolean;
    handleClose: () => void;
    meterSerial?: string;
    isBulk?: boolean;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

const ELEMENTS_PER_PAGE = 5;

const RefustigateModal = ({
    open,
    handleClose,
    meterSerial,
    isBulk,
}: Props) => {
    const selectedFrts = useSelector(
        (store: RootState) => store.frontiers.selectedFrts,
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);

    const startIndex = useMemo(() => {
        return (currentPage - 1) * ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const endIndex = useMemo(() => {
        return startIndex + ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const onRefustigate = async () => {
        if (!date) return;

        setLoading(true);
        setError(false);
        setSuccess(false);
        try {
            await refustigateMeter(
                date,
                meterSerial
                    ? [meterSerial]
                    : isBulk
                      ? selectedFrts.map((frt) => frt.meter_serial)
                      : [],
            );
            setSuccess(true);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        setLoading(false);
        setError(false);
        setSuccess(false);
        setDate("");
        handleClose();
    };

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value);
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            color={"primary"}
                            sx={{ fontSize: "21px", fontWeight: 700 }}
                        >
                            Refustigar
                        </Typography>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            className="back-btn"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box my={2}>
                        <Typography
                            sx={{
                                paddingBottom: "10px",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#3D4350",
                            }}
                        >
                            Elige la fecha
                        </Typography>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    format="YYYY/MM/DD"
                                    value={moment(date)}
                                    maxDate={moment()}
                                    disabled={loading}
                                    onChange={(value: any) => {
                                        setDate(value.format("YYYY-MM-DD"));
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            sx: {
                                                "& fieldset": {
                                                    borderRadius: "12px",
                                                },
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Box>
                    {error && (
                        <Box mb={1}>
                            <CustomAlert
                                severity="error"
                                text={
                                    "Hubo un error enviando el comando de refustigación."
                                }
                                onClose={() => setError(false)}
                            />
                        </Box>
                    )}
                    {success && (
                        <Box mb={1}>
                            <CustomAlert
                                severity="success"
                                text={
                                    "El comando de refustigación ha sido enviado."
                                }
                                onClose={() => setSuccess(false)}
                            />
                        </Box>
                    )}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={
                            isBulk ? selectedFrts.length === 0 || !date : !date
                        }
                        onClick={onRefustigate}
                    >
                        Refustigar
                    </LoadingButton>
                    {isBulk && (
                        <Box my={2}>
                            <Typography
                                sx={{ color: "#53358E", fontSize: "18px" }}
                            >
                                Lista de medidores seleccionados (
                                {selectedFrts.length})
                            </Typography>
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead
                                        sx={{
                                            ".MuiTableCell-root": {
                                                padding: "12px !important",
                                            },
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell>Frontera</TableCell>
                                            <TableCell>
                                                Serial del medidor
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            ".MuiTableCell-root": {
                                                padding: "12px !important",
                                            },
                                        }}
                                    >
                                        {selectedFrts
                                            .slice(startIndex, endIndex)
                                            .map((frt) => (
                                                <TableRow key={frt.frt_code}>
                                                    <TableCell>
                                                        {frt.frt_code}
                                                    </TableCell>
                                                    <TableCell>
                                                        {frt.meter_serial}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationContainer>
                                <Stack spacing={2}>
                                    <Pagination
                                        page={currentPage}
                                        count={Math.round(
                                            selectedFrts.length /
                                                ELEMENTS_PER_PAGE,
                                        )}
                                        shape="rounded"
                                        onChange={handlePagination}
                                    />
                                </Stack>
                            </PaginationContainer>
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default RefustigateModal;

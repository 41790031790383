import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import { useNavigate, useParams } from "react-router-dom";
import Backbutton from "../../components/Buttons/back-button";
import SectionContainer from "../../components/container/section-container";
import PropertyForm from "./components/PropertyForm";
import IdentityForm from "./components/IdentityForm";
import Checkout from "./components/Checkout";
import StatusTag from "../../components/container/status-tag";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import { handleCopy } from "../../utils";
import useSWR from "swr";
import { Process } from "../../models";
import { processStatus } from "../../utils/process";

const getWebsiteUrl = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    if (!baseUrl) return "https://pasate.enerbit.dev";

    const env = baseUrl.split(".");

    if (env[2] === "dev" || env[2] === "qa")
        return "https://pasate.enerbit.dev";

    return "https://pasate.enerbit.co";
};

const ProcessDetail = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const websiteUrl = getWebsiteUrl();

    const {
        data: processDetail,
        error,
        isLoading,
    } = useSWR<Process>(id ? `/conversion/processes/${id}` : null);

    const handleBack = () => {
        navigate("/");
    };

    const goToWebsite = () => {
        if (!processDetail) return;
        window.open(
            `${websiteUrl}/proceso/${processDetail.public_id}`,
            "_blank",
            "noopener,noreferrer",
        );
    };

    const copyWebsiteLink = () => {
        if (!processDetail) return;
        handleCopy(`${websiteUrl}/proceso/${processDetail.public_id}`);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Backbutton onClick={handleBack} />
                    <Box>
                        <Typography sx={{ color: enerbitColors.neutral[700] }}>
                            Proceso
                        </Typography>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "28px",
                                fontWeight: 700,
                            }}
                        >
                            {id}
                        </Typography>
                    </Box>
                    {processDetail && (
                        <StatusTag
                            fontcolor={processStatus[processDetail.status].font}
                            bgcolor={
                                processStatus[processDetail.status].bgcolor
                            }
                        >
                            {processStatus[processDetail.status].label}
                        </StatusTag>
                    )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={copyWebsiteLink}
                        startIcon={<ContentCopyIcon />}
                    >
                        Copiar link website
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={goToWebsite}
                        startIcon={<LinkIcon />}
                    >
                        Ir al website
                    </Button>
                </Box>
            </Box>
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error cargando detalles del proceso."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {!isLoading && !error && processDetail && (
                <>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 1: Información inicial
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <PropertyForm process={processDetail} />
                    </SectionContainer>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 2: Verificación de identidad
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <IdentityForm process={processDetail} />
                    </SectionContainer>
                    <SectionContainer sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                                fontSize: "18px",
                            }}
                        >
                            Paso 3: Confirmación y firma de la oferta comercial
                        </Typography>
                        <Divider sx={{ my: 2, mx: "-12px" }} />
                        <Checkout process={processDetail} />
                    </SectionContainer>
                </>
            )}
        </Box>
    );
};

export default ProcessDetail;

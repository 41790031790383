import moment from "moment";
import { Frontier, FrontiersTableData } from "../models/frontiers";
import { CSV_FILE_HEADERS, frontierTypeToName } from "../common/frontiers";
import { DownloadFileModel } from "../models/files";
import Papa from "papaparse";
import { Usages } from "../models/measurements";

export const getFrontierStatus = (registerDate: string) => {
    if (moment(registerDate).isAfter(moment())) {
        return "incoming";
    }
    const datesDiff = moment().diff(registerDate, "days");
    if (Math.abs(datesDiff) === 3) {
        return "new";
    }

    return "old";
};

export const formatFrontierData = (frts: Frontier[]) => {
    const formatedData: FrontiersTableData[] = frts.map((frt) => ({
        code: frt.frontier_xm_code,
        frontierType: frontierTypeToName[frt.frontier_type],
        id: frt.frontier_xm_code,
        location: `${frt.City}, ${frt.Department}`,
        xm_register_date: moment(frt.frontier_xm_registered_from)
            .utcOffset(0)
            .format("YYYY-MM-DD"),
        name: frt.DisplayName,
        status: getFrontierStatus(frt.frontier_xm_registered_from),
    }));

    return formatedData;
};

export const downloadFrtsFile = (frts: Frontier[]) => {
    console.log("frts to download: ", frts);

    const rows = frts.map((frt) => ({
        Código: frt.frontier_xm_code,
        Nombre: frt.DisplayName,
        "Tipo de frontera": frontierTypeToName[frt.frontier_type],
        Ubicación: `${frt.City}-${frt.Department}`,
        Dirección: frt.Street,
        "Fecha de registro ante XM": frt.frontier_xm_registered_from,
        "Serial medidor principal": frt.MeterNumber,
        "Operador de red": frt.CurrentNetworkOperator,
        "Código lead en Odoo": frt.LeadIntegratorID,
    }));

    const csv = Papa.unparse({
        fields: CSV_FILE_HEADERS,
        data: rows,
    });

    createDownloadLink({
        data: "\uFEFF" + csv,
        fileName: "fronteras",
        fileType: "text/csv;charset=utf-8;",
    });
};

export const createDownloadLink = ({
    data,
    fileName,
    fileType,
}: DownloadFileModel) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const formatUsagesChartData = (usages: Usages[]) => {
    const labels = [
        "active_energy_imported",
        "reactive_energy_imported",
        "active_energy_exported",
        "reactive_energy_exported",
    ];

    const formatedUsages = usages.map((usage) => {
        const data: {
            label: string;
            active_energy_imported: number;
            reactive_energy_imported: number;
            active_energy_exported: number;
            reactive_energy_exported: number;
        } = {
            label: moment(usage.time_start).utcOffset("0500").format("HH:mm"),
            active_energy_imported: usage.active_energy_imported,
            reactive_energy_imported: usage.reactive_energy_imported,
            active_energy_exported: usage.active_energy_exported,
            reactive_energy_exported: usage.reactive_energy_exported,
        };

        return data;
    });

    return formatedUsages;
};

import {
    Box,
    EditOutlinedIcon,
    Grid,
    Loader,
    LoadingButton,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormItem } from "../../../common/dynamic-form";
import {
    IDs,
    solarInverterInformation,
    solarStateCommon,
    solarSystemInformation,
} from "../../../common/form-builders/form-information";
import { IDynamicForm } from "../../../models/form/IDynamicForm";
import { ISolarInformationForm } from "../../../models/form/SolarInformationForm";
import { SolarInformationSchema } from "../../../schemas/SolarInformationSchema";
import { updateCapacity, updateState } from "../../../services/State";
import { useGeopolitics } from "../../../store/Geolocalization";
import { useInverters } from "../../../store/Inverters";
import { useSolarManager } from "../../../store/SolarManager";

export const InformationForm = ({ idEss }: { idEss: string }) => {
    const { solarService } = useSolarManager();
    const [isSendInformation, setIsSendInformation] = useState(false);
    const {
        getEstateById,
        estate,
        cities,
        states,
        getCities,
        getStates,
        getCountries,
        country,
    } = useGeopolitics();
    const { inverter, getInverters } = useInverters();

    const methods = useForm<ISolarInformationForm>({
        resolver: zodResolver(SolarInformationSchema),
        defaultValues: {
            ess_id: idEss,
            solar_id: solarService?.solar_service_id,
            measurement_point_id: solarService?.measurement_point_id,
            integration_type: solarService?.integration_type,
        },
        mode: "onChange",
    });

    const { setValue, getValues, watch } = methods;

    useEffect(() => {
        getEstateById(solarService?.estate_id ?? "");
        getCountries();
        getInverters(solarService?.inverter_id ?? "");
    }, []);

    useEffect(() => {
        if (inverter) {
            setValue("serial_number", inverter.serial);
            setValue("brand", inverter.sfv_inverter_brand.brand);
            setValue("model", inverter.sfv_inverter_model.model);
            setValue(
                "capacity_kwp",
                (solarService?.solar_details.capacity_peak ?? 0).toString(),
            );
            setValue(
                "solar_instalator",
                inverter.sfv_inverter_company.company ?? "",
            );
        }
    }, [inverter]);

    useEffect(() => {
        if (estate && states.length > 0) {
            setValue("country", country?.name ?? "");
            setValue("state", estate.state);
            setValue("address", estate.address);
            setValue(
                "stratum",
                estate.details?.social_stratum.description ?? "",
            );
            setValue(
                "catastral_identifier_type",
                estate?.details?.catastral_type.description ?? "",
            );
            setValue(
                "catastral_identifier",
                estate?.details?.catastral_id ?? "",
            );
            setValue(
                "longitude",
                estate.geolocalization.longitude?.toString() ?? "",
            );
            setValue(
                "latitude",
                estate.geolocalization.latitude?.toString() ?? "",
            );
            setValue(
                "altitude",
                estate.geolocalization.altitude?.toString() ?? "",
            );
            setValue(
                "plus_code",
                estate.geolocalization.plus_code?.toString() ?? "",
            );
        }
    }, [estate, states]);

    useEffect(() => {
        if (country) getStates(country.id);
    }, [country]);

    useEffect(() => {
        if (cities.length > 0 && estate) setValue("city", estate.city);
    }, [cities]);

    useEffect(() => {
        const id = states.find((state) => state.name === watch("state"))?.id;
        if (states.length > 0 && id) getCities(id);
    }, [watch("state")]);

    const stateInformation: IDynamicForm[] = [
        {
            name: "",
            label: "4. Información del predio",
            type: "title",
            sx: { xs: 12, md: 6, lg: 4 },
        },
        {
            name: "country",
            label: "País",
            props: { disabled: true },
            type: "text",
            sx: { xs: 12, md: 6, lg: 4 },
        },
        {
            name: "state",
            label: "Departamento",
            type: "select",
            fetchItems: () =>
                states.map((state) => ({
                    text: state.name,
                    value: state.name,
                })),
            sx: { xs: 12, md: 6, lg: 4 },
        },
        {
            name: "city",
            label: "Ciudad",
            type: "select",
            fetchItems: () =>
                cities.map((city) => ({ text: city.name, value: city.name })),
            sx: { xs: 12, md: 6, lg: 4 },
        },
        ...solarStateCommon,
    ];

    const updateInformation = async () => {
        const isFormValid = await methods.trigger();
        if (isFormValid && estate?.id) {
            setIsSendInformation(true);
            try {
                const solarServiceId = solarService?.solar_service_id ?? "";

                await Promise.all([
                    updateCapacity(
                        solarServiceId,
                        Number(watch("capacity_kwp")),
                    ),
                    updateState(estate.id, getValues()),
                ]);

                enqueueSnackbar("Los datos se actualizaron correctamente", {
                    variant: "success",
                });
            } catch (error) {
                enqueueSnackbar("Hubo un error al actualizar los datos", {
                    variant: "error",
                });
                throw error;
            } finally {
                setIsSendInformation(false);
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <Box sx={{ marginBottom: "60px" }}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{
                                fontSize: "25px",
                                color: enerbitColors.primary.main,
                                fontWeight: "bold",
                            }}
                        >
                            Información
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LoadingButton
                            loading={isSendInformation}
                            onClick={updateInformation}
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            <span>
                                <EditOutlinedIcon
                                    sx={{
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                        mr: "4px",
                                    }}
                                />
                                Editar información
                            </span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>

            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{ mb: "20px" }}>
                    <Grid container spacing={3} sx={{ pb: "40px" }}>
                        {IDs.map((item, index) => (
                            <FormItem {...item} key={index} />
                        ))}
                    </Grid>
                </Box>

                <Box sx={{ mb: "20px" }}>
                    <Grid container spacing={3} sx={{ pb: "40px" }}>
                        {solarInverterInformation.map((item, index) => (
                            <FormItem {...item} key={index} />
                        ))}
                    </Grid>
                </Box>

                <Box sx={{ mb: "20px" }}>
                    <Grid container spacing={3} sx={{ pb: "40px" }}>
                        {solarSystemInformation.map((item, index) => (
                            <FormItem {...item} key={index} />
                        ))}
                    </Grid>
                </Box>

                {!country || states.length == 0 || cities.length == 0 ? (
                    <Box sx={{ mb: "10px" }}>
                        <Loader
                            message={
                                "Cargado información del predio, por favor espere"
                            }
                        />
                    </Box>
                ) : (
                    <Box sx={{ mb: "20px" }}>
                        <Grid container spacing={3} sx={{ pb: "40px" }}>
                            {(!country ||
                                states.length == 0 ||
                                cities.length == 0) && (
                                <Box sx={{ mb: "10px" }}>
                                    <Loader
                                        message={
                                            "Cargado información del predio, por favor espere"
                                        }
                                    />
                                </Box>
                            )}
                            {stateInformation.map((item, index) => (
                                <FormItem {...item} key={index} />
                            ))}
                        </Grid>
                    </Box>
                )}
            </form>
        </FormProvider>
    );
};

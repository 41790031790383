import { api } from "@enerbit/base";

export const getMpByMeterIdl = async (meterId: string) => {
    const { data } = await api.get(
        `/assignees/measurement-point/meter-relations?meter_id=${meterId}`,
    );
    return data.measurement_point_id;
};

export const getEssByMp = async (merterId: string) => {
    const mp = await getMpByMeterIdl(merterId);
    const { data } = await api.get(
        `/electricity-supply-service/manager/electricity-supply-services/?measurement_point_id=${mp}`,
    );
    return data.items[0].id;
};

import { Grid, Skeleton } from "@enerbit/base";
import { GENERAL_INFO } from "../../common/device";

const GeneralInfomationSkeleton = () => {
    return (
        <Grid container columnSpacing={2}>
            {GENERAL_INFO.map((item, index) => (
                <Grid
                    key={`${index + 1}-${item.key}`}
                    item
                    sm={12 / GENERAL_INFO.length}
                >
                    <Skeleton
                        sx={{ borderRadius: "16px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={"69px"}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default GeneralInfomationSkeleton;

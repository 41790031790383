import {
    ArrowForwardIcon,
    Box,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Grid,
    IconButton,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { IAgendaDetail, Process } from "../../../models";
import CopyButton from "../../process-detail/components/CopyButton";
import StatusTag from "../../../components/container/status-tag";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { processStatus } from "../../../utils/process";

interface Props {
    agenda: IAgendaDetail;
}

const Processes = ({ agenda }: Props) => {
    const navigate = useNavigate();

    const { data: process, error, isLoading } = useSWR<Process>(
        agenda ? `/conversion/processes/${agenda.process_id}` : null,
    );

    return (
        <SectionContainer>
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "18px",
                }}
            >
                Procesos
            </Typography>
            <Divider sx={{ my: 2, mx: "-12px" }} />
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    severity="error"
                    text="Error cargando procesos."
                    onClose={() => { }}
                />
            )}
            {!isLoading && !error && process && (
                <Box
                    sx={{
                        padding: "12px",
                        border: `1px solid ${enerbitColors.primary[200]}`,
                        borderRadius: "4px",
                    }}
                >
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={4}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <CopyButton text={process.id} />
                                <Box>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral.main,
                                            fontSize: "12px",
                                        }}
                                    >
                                        Id
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            color: enerbitColors.neutral[900],
                                        }}
                                    >
                                        {process.id}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <CopyButton text={process.public_id} />
                                <Box>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral.main,
                                            fontSize: "12px",
                                        }}
                                    >
                                        Id público
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            color: enerbitColors.neutral[900],
                                        }}
                                    >
                                        {process.public_id}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <CopyButton text={process.entrypoint} />
                                <Box>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral.main,
                                            fontSize: "12px",
                                        }}
                                    >
                                        Número de teléfono
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            color: enerbitColors.neutral[900],
                                        }}
                                    >
                                        {process.entrypoint}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <StatusTag
                                fontcolor={processStatus[process.status].font}
                                bgcolor={processStatus[process.status].bgcolor}
                            >
                                {processStatus[process.status].label}
                            </StatusTag>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                sx={{
                                    backgroundColor: "#FF7705",
                                    borderRadius: "12px",
                                    height: "32px",
                                    width: "32px",
                                    ":hover": {
                                        backgroundColor: "#FF7705",
                                    },
                                }}
                                onClick={() => {
                                    navigate(`/process/${process.id}`);
                                }}
                            >
                                <ArrowForwardIcon sx={{ color: "white" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </SectionContainer>
    );
};

export default Processes;

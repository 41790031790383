import {
    Box,
    CheckCircleIcon,
    LoadingButton,
    Stack,
    Typography,
    useSnackbar,
} from "@enerbit/base";
import ReportGmailerrorredTwoToneIcon from "@mui/icons-material/ReportGmailerrorredTwoTone";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
interface Props {
    hasError: boolean;
    errorValidated: {
        serial: string;
        message: string;
    }[];
    onSwapAssignees: () => Promise<void>;
}

const ValidateDialog = ({
    hasError,
    errorValidated,
    onSwapAssignees,
}: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { mutate, isPending, isSuccess, isError } = useMutation({
        mutationFn: () => {
            return onSwapAssignees();
        },
    });

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("Cambios realizados correctamente", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
            navigate(`/`);
        }
        if (isError) {
            enqueueSnackbar("Error al cambiar los datos", {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
        }
    }, [isSuccess, isError]);
    return (
        <Box sx={{ p: 3 }}>
            {hasError ? (
                <Stack spacing={2}>
                    <Box sx={{ textAlign: "center" }}>
                        <ReportGmailerrorredTwoToneIcon
                            sx={{
                                color: "error.main",
                                fontSize: "80px",
                            }}
                        />
                    </Box>
                    <Typography
                        color="error"
                        variant="h5"
                        fontWeight="bold"
                        textAlign={"center"}
                    >
                        No se pueden cambiar los datos
                    </Typography>
                    <Box sx={{ p: 2, backgroundColor: "neutral.100" }}>
                        {errorValidated.map((error, index) => (
                            <Typography color="neutral.main" key={index}>
                                <b>{error.serial}</b>: {error.message}
                            </Typography>
                        ))}
                    </Box>
                </Stack>
            ) : (
                <Stack spacing={2}>
                    <Box sx={{ textAlign: "center" }}>
                        <CheckCircleIcon
                            sx={{
                                color: "success.main",
                                fontSize: "80px",
                            }}
                        />
                    </Box>
                    <Typography
                        color="success.main"
                        variant="h5"
                        fontWeight="bold"
                        textAlign={"center"}
                    >
                        Cambio de datos permitidos
                    </Typography>
                    <Typography color="neutral.main">
                        El cambio de información se puede realizar sin
                        problemas.
                    </Typography>

                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        loading={isPending}
                        onClick={() => mutate()}
                    >
                        Intercambiar datos
                    </LoadingButton>
                </Stack>
            )}
        </Box>
    );
};

export default ValidateDialog;

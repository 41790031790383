import { ArrowForwardIcon, Box, Button, Typography } from "@enerbit/base";
import { useNavigate } from "react-router-dom";

interface Props {
    meterSerial: string;
}

const DetailHeader = ({ meterSerial }: Props) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
                variant="outlined"
                className="back-btn"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowForwardIcon style={{ transform: "rotate(180deg)" }} />
            </Button>
            <Typography
                sx={{ fontSize: "28px", fontWeight: 700, color: "#53358E" }}
            >
                Detalle de punto de medida {meterSerial}
            </Typography>
        </Box>
    );
};

export default DetailHeader;

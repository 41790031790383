import {
  Box,
  CloseIcon,
  IconButton,
  Modal,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onEnerbitServiceAgreementChangedPlan } from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";

export type ModalChangePlanProps = {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

export const ModalChangePlan = ({
  open,
  handleClose,
}: ModalChangePlanProps) => {
  const [lastPage, setLastPage] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const closeModalChangePlan = () => {
    dispatch(onEnerbitServiceAgreementChangedPlan(null));
    setLastPage(false);
  };

  useEffect(() => {
    if (!open) {
      closeModalChangePlan();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="Modal-change-modal-plan">
        <Box className="Modal-change-plan-subbox">
          <Typography
            variant="h4"
            color={enerbitColors.primary.main}
            align="center"
            fontWeight="bold"
          >
            {lastPage ? "¿Estás seguro?" : "Migrar servicios a otro plan"}
          </Typography>
          <IconButton
            onClick={(_) => {
              if (handleClose) {
                handleClose({}, "backdropClick");
                closeModalChangePlan();
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* {!lastPage ? (
                    <FormChangeModal
                        handleClose={() => {
                            if (handleClose) {
                                handleClose({}, "backdropClick");
                                closeModalChangePlan();
                            }
                        }}
                        handleChangePage={() => setLastPage(!lastPage)}
                    />
                ) : (
                    <FormLastModal
                        handleChangePage={() =>
                            // dispatch(updateChangePlan()).then(() =>
                            //     dispatch(getListPlans()),
                            // )
                        }
                        handleClose={() => {
                            if (handleClose) {
                                handleClose({}, "backdropClick");
                                closeModalChangePlan();
                            }
                        }}
                    />
                )} */}
      </Box>
    </Modal>
  );
};

import {
    AccountCircleOutlinedIcon,
    Box,
    ExpandMoreIcon,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    enerbitColors,
    theme,
} from "@enerbit/base";
import { useEffect } from "react";
import { translateRoles } from "../../const/roles/RolesName";
import { useUser } from "../../store/User";

export const CustomizedSelect = () => {
    const { userSelected, setUserSelected, usersApp } = useUser();

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const userId = event.target.value as string;
        const userSelected = usersApp.find((user) => user.user_id === userId);
        if (userSelected) {
            setUserSelected(userSelected);
        }
    };

    useEffect(() => {
        setUserSelected(null)
        setUserSelected(usersApp[0]);
    }, []);

    return (
        <>
            <FormControl
                sx={{
                    width: "300px",
                    boxShadow: "none",
                    color: theme.palette.primary.main,
                    "& .MuiSelect-select": {
                        margin: "0 !important",
                        padding: "16.5px 0 !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                }}
            >
                <Select
                    sx={{ padding: "0 !important" }}
                    value={userSelected ? userSelected.user_id : ""}
                    onChange={(event) => handleChange(event)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={(props) => (
                        <ExpandMoreIcon
                            {...props}
                            sx={{
                                color: `${theme.palette.primary.main} !important`,
                            }}
                        />
                    )}
                >
                    {usersApp.map((user, index) => (
                        <MenuItem
                            key={user.user_id}
                            value={user.user_id}
                            sx={{
                                "&:hover": {
                                    backgroundColor: theme.palette.action.hover,
                                },
                            }}
                        >
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    padding: "0 !important",
                                    margin: "0.9rem 0 !important",
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: "0 0 !important",
                                        justifyContent: "flex-end",
                                        minWidth: "1.5rem !important",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background:
                                                theme.palette.primary.main,
                                            marginRight: "0.8rem",
                                            padding: "0.3rem 0.3rem",
                                            borderRadius: "15%",
                                            display: "flex",
                                            border: `1px solid ${theme.palette.primary.main}`,
                                        }}
                                    >
                                        <AccountCircleOutlinedIcon
                                            sx={{
                                                color: "white",
                                                fontSize: "1.563rem",
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                            color:
                                                userSelected &&
                                                    userSelected.user_id ===
                                                    user.user_id
                                                    ? theme.palette.primary.main
                                                    : enerbitColors
                                                        ?.primary[400],
                                            textAlign: "left",
                                            wordBreak: "break-word",
                                            maxWidth: "200px",
                                            whiteSpace: "normal",
                                        }}
                                    >
                                        {user.usersApp.pii?.names + " " + user.usersApp.pii?.last_names}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: "normal",
                                            color:
                                                userSelected &&
                                                    userSelected.user_id ===
                                                    user.user_id
                                                    ? theme.palette.primary.main
                                                    : enerbitColors
                                                        ?.primary[400],
                                            textAlign: "left",
                                        }}
                                    >
                                        {translateRoles(user.rol?.name!)}
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

import {
    Alert,
    ArrowBackIosIcon,
    Box,
    Dialog,
    Grid,
    LoadingButton,
    Typography,
    enerbitColors,
} from "@enerbit/base";

import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SkeletonCard from "../components/common/SkeletonCard";

import CardMeter from "../components/common/CardMeter";

import DashLine from "../components/swap-meter/DashLine";
import ValidateDialog from "../components/swap-meter/ValidateDialog";
import { onChangeData } from "../helpers/swapData";
import { useGet } from "../hooks";
import type { IDataCardResponse, IDataToValidate } from "../interfaces";
import { dataCard } from "../services/buildData";
import { swapAssignees } from "../services/orders";
import { validatedData } from "../services/validatedData";
import { useSwapMetersStore } from "../store/swappMeters.store";

interface IErrorResponse {
    error: {
        message: string;
    }[];
    data: {
        meter: {
            serie: string;
        };
    };
}

interface IError {
    message: string;
    serial: string;
}

const SwapMeter = () => {
    const { id } = useParams();
    const { swapMeters } = useSwapMetersStore();
    const currentData = swapMeters.filter((swap) => swap.id === id)[0];
    const [dataCard1, setDataCard1] = useState<IDataCardResponse>();
    const [dataCard2, setDataCard2] = useState<IDataCardResponse>();
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    const [errorValidated, setErrorValidated] = useState<IError[]>([]);
    const [hasError, setHasError] = useState(false);
    const [isSwap, setIsSwap] = useState(false);
    const [dataToValidated, setDataToValidated] = useState<IDataToValidate>({
        data: [
            {
                serial: currentData.serial,
                lead: null,
                service_order: null,
            },
            {
                serial: currentData.report_serial,
                lead: null,
                service_order: null,
            },
        ],
    });
    const navigate = useNavigate();

    const onValidateData = async (body: IDataToValidate) => {
        const res = await validatedData(body);
        setHasError(false);
        setErrorValidated([]);
        if (res) {
            const errors = res.map((item: IErrorResponse) => ({
                serial: item?.data?.meter?.serie,
                message: item?.error[0]?.message,
            }));
            if (errors.length > 0) {
                setErrorValidated(errors);
                setHasError(true);
            }
        }

        setIsOpenDialog(true);
    };

    const {
        mutate,
        isPending,
        data: dataValidated,
        isSuccess: isSuccessValidated,
    } = useMutation({
        mutationFn: (body: IDataToValidate) => {
            return onValidateData(body);
        },
    });

    useEffect(() => {
        if (dataValidated && isSuccessValidated) {
            setDataToValidated(dataValidated);
        }
    }, [dataValidated, isSuccessValidated]);

    const { isLoading, data, isSuccess, isError } = useGet<
        IDataCardResponse,
        string
    >("certificates-1", dataCard, currentData.serial);

    useEffect(() => {
        if (isSuccess) {
            setDataCard1(data);
        }
    }, [isSuccess]);

    const {
        isLoading: isLoadingReport,
        data: dataReport,
        isSuccess: isSuccessReport,
        isError: isErrorReport,
    } = useGet<IDataCardResponse, string>(
        "certificates-2",
        dataCard,
        currentData.report_serial,
    );

    useEffect(() => {
        if (isSuccessReport) {
            setDataCard2(dataReport);
        }
    }, [isSuccessReport]);

    useEffect(() => {
        setIsSwap(!isSwap);
    }, [dataCard1, dataCard2]);

    const fakeArray = [1, 2];

    const onSwapEntity = () => {
        if (dataCard1 && dataCard2) {
            const dataEntity1 = {
                ...dataToValidated.data[0],
                serial: currentData.serial,
                lead: {
                    current: dataCard1.entity[0].dataToMove,
                    new: dataCard2.entity[0].dataToMove,
                },
            };

            const dataEntity2 = {
                ...dataToValidated.data[1],
                serial: currentData.report_serial,
                lead: {
                    current: dataCard2.entity[0].dataToMove,
                    new: dataCard1.entity[0].dataToMove,
                },
            };

            setDataToValidated({
                data: [
                    dataEntity1,

                    dataEntity2,
                ],
            });

            setDataCard1({
                ...dataCard1,
                entity: dataCard2.entity,
            });
            setDataCard2({
                ...dataCard2,
                entity: dataCard1.entity,
            });
        }
    };

    const onSwapCertificate = (index: number) => {
        if (dataCard1 && dataCard2) {
            setDataToValidated({
                data: [
                    onChangeData(
                        dataCard1,
                        dataCard2,
                        index,
                        0,
                        dataToValidated,
                    ),
                    onChangeData(
                        dataCard2,
                        dataCard1,
                        index,
                        1,
                        dataToValidated,
                    ),
                ],
            });

            const certificatesCopy1 = [...dataCard1.certificates];
            const certificatesCopy2 = [...dataCard2.certificates];

            const temp = certificatesCopy1[index];
            certificatesCopy1[index] = certificatesCopy2[index];
            certificatesCopy2[index] = temp;

            setDataCard1({
                ...dataCard1,
                certificates: certificatesCopy1,
            });

            setDataCard2({
                ...dataCard2,
                certificates: certificatesCopy2,
            });
        }
    };

    const onSwapAssignees = async () => {
        if (dataCard1 && dataCard2) {
            const body = {
                right: {
                    order_id:
                        dataToValidated.data[1].service_order?.current
                            .order_id!,
                    certificate_id:
                        dataToValidated.data[1].service_order?.current
                            .certificate_id!,
                    assignee_id: dataCard1.entity[0].dataToMove.lead_id,
                },
                left: {
                    order_id:
                        dataToValidated.data[0].service_order?.current
                            .order_id!,
                    certificate_id:
                        dataToValidated.data[0].service_order?.current
                            .certificate_id!,
                    assignee_id: dataCard2.entity[0].dataToMove.lead_id,
                },
            };
            await swapAssignees(body);
            setIsOpenDialog(false);
        }
        setIsOpenDialog(false);
        return;
    };

    return (
        <>
            {isError || isErrorReport ? (
                "Error al cargar la información necesaria"
            ) : (
                <>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                        <ArrowBackIosIcon
                            sx={{ mr: 1, cursor: "pointer" }}
                            onClick={() => navigate("/")}
                        />
                        <Typography
                            color="primary"
                            variant="h5"
                            fontWeight="bold"
                        >
                            Medidor trocado
                        </Typography>
                    </Box>

                    <Typography>Detalle de los medidores</Typography>

                    <Alert severity="info" sx={{ my: 2 }}>
                        <Typography>{currentData?.observations}</Typography>
                    </Alert>

                    <Grid container spacing={3}>
                        <Grid item xs>
                            {isLoading
                                ? fakeArray.map((_, index: number) => (
                                      <SkeletonCard key={index} />
                                  ))
                                : dataCard1 && (
                                      <CardMeter
                                          meterSerial={currentData.serial}
                                          subTitle="Información"
                                          insideCardBorderColor={
                                              enerbitColors.error.main
                                          }
                                          entityData={dataCard1.entity}
                                          certificateData={
                                              dataCard1.certificates
                                          }
                                          type="swap"
                                      />
                                  )}
                        </Grid>
                        <Grid
                            item
                            xs={0.5}
                            alignItems={"center"}
                            justifyItems={"center"}
                        >
                            {!isLoadingReport &&
                                !isLoading &&
                                dataCard1 &&
                                dataCard2 && (
                                    <DashLine
                                        entities={dataCard1.entity}
                                        certificates={dataCard1.certificates}
                                        certificates2={dataCard2.certificates}
                                        onSwapEntity={onSwapEntity}
                                        onSwapCertificate={onSwapCertificate}
                                    />
                                )}
                        </Grid>

                        <Grid item xs>
                            {isLoadingReport
                                ? fakeArray.map((_, index: number) => (
                                      <SkeletonCard key={index} />
                                  ))
                                : dataCard2 && (
                                      <CardMeter
                                          meterSerial={
                                              currentData.report_serial
                                          }
                                          subTitle="Información"
                                          insideCardBorderColor={
                                              enerbitColors.error.main
                                          }
                                          entityData={dataCard2.entity}
                                          certificateData={
                                              dataCard2.certificates
                                          }
                                          type="swap"
                                      />
                                  )}
                        </Grid>
                    </Grid>

                    <Box sx={{ textAlign: "end", my: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            disabled={isLoading || isLoadingReport}
                            onClick={() => mutate(dataToValidated)}
                            loading={isPending}
                        >
                            Validar cambios de datos
                        </LoadingButton>
                    </Box>
                </>
            )}

            <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
                <ValidateDialog
                    hasError={hasError}
                    errorValidated={errorValidated}
                    onSwapAssignees={onSwapAssignees}
                />
            </Dialog>
        </>
    );
};

export default SwapMeter;

import {
    Autocomplete,
    Box,
    Button,
    CloseIcon,
    CustomAlert,
    DateTimePicker,
    Grid,
    LoadingButton,
    LocalizationProvider,
    MenuItem,
    Modal,
    Select,
    SxProps,
    TextField,
    Typography,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { RestoreSchedulesTypes } from "../../../../models/smartbit";
import { RESTORE_SCHEDULES_TYPES } from "../../common/device";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import moment from "moment";
import {
    restoreSchedulesByDeviceName,
    restoreSchedulesByMeter,
} from "../../../../services/device";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DATE_TIME_FORMAT } from "../../../../common/constants";

interface Props {
    onClose: () => void;
    open: boolean;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

const selectInputStyles: SxProps = {
    "& fieldset": {
        borderRadius: "12px !important",
    },
};

const inputLabelsStyles: SxProps = {
    paddingBottom: "7px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3D4350",
};
const datePickerStyles: SxProps = {
    "& fieldset": { borderRadius: "14px" },
    "& .MuiOutlinedInput-root": {
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
        },
    },
};

const RestoreSchedulesModal = ({ onClose, open }: Props) => {
    const meters = useSelector(
        (state: RootState) => state.associatedMeters.meters,
    );
    const generalInfo = useSelector(
        (state: RootState) => state.generalInfo.sbGeneralInfo,
    );
    const [restoreType, setRestoretype] = useState<RestoreSchedulesTypes>(
        RestoreSchedulesTypes.DEVICE,
    );
    const [selectedMeter, setSelectedMeter] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);
    const [since, setSince] = useState<any>("");
    const [until, setUntil] = useState<any>("");

    const handleClose = () => {
        console.log("close");
        setError(undefined);
        setSuccess(false);
        setSince(null);
        setUntil(null);
        setSelectedMeter("");
        setRestoretype(RestoreSchedulesTypes.DEVICE);
        onClose();
    };

    const handleDateChange = (name: string, value: any) => {
        if (name === "since") {
            setSince(value);
        } else {
            setUntil(value);
        }
    };

    const onRestoreSchedules = async () => {
        if (!since || !until) return;
        if (restoreType === RestoreSchedulesTypes.METER && !selectedMeter)
            return;

        setError(undefined);
        setSuccess(false);
        setLoading(true);

        const tSince = since.format(DATE_TIME_FORMAT);
        const tUntil = until.format(DATE_TIME_FORMAT);

        try {
            if (restoreType === RestoreSchedulesTypes.DEVICE) {
                await restoreSchedulesByDeviceName(
                    generalInfo.deviceName,
                    tSince,
                    tUntil,
                );
            } else {
                await restoreSchedulesByMeter(selectedMeter, tSince, tUntil);
            }
            setSuccess(true);
        } catch (error) {
            setError("Error enviando el comando de recuperación.");
        } finally {
            setLoading(false);
        }
    };

    const handleMeterChange = (event: any, newValue: any) => {
        if (!newValue) return;

        setSelectedMeter(newValue.id);
    };

    useEffect(() => {
        console.log("since: ", moment(since).format(DATE_TIME_FORMAT));
        console.log("until: ", until);
    }, [since, until]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        color={"primary"}
                        sx={{ fontSize: "21px", fontWeight: 700 }}
                    >
                        Recuperar datos
                    </Typography>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        className="back-btn"
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Box my={2}>
                    <Box mb={2}>
                        <Typography sx={inputLabelsStyles}>
                            Realizar la recuperación por
                        </Typography>
                        <Select
                            fullWidth
                            value={restoreType}
                            onChange={(event) =>
                                setRestoretype(
                                    event.target.value as RestoreSchedulesTypes,
                                )
                            }
                            sx={selectInputStyles}
                        >
                            {RESTORE_SCHEDULES_TYPES.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    {restoreType === RestoreSchedulesTypes.METER ? (
                        <Box my={2}>
                            <Typography sx={inputLabelsStyles}>
                                Selecciona el medidor
                            </Typography>
                            <Autocomplete
                                disablePortal
                                onChange={handleMeterChange}
                                options={meters.map((m) => ({
                                    label: m.meterSerial,
                                    id: m.meterId,
                                }))}
                                renderInput={(params) => (
                                    <TextField
                                        sx={datePickerStyles}
                                        {...params}
                                    />
                                )}
                            />
                        </Box>
                    ) : null}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={1}>
                            <Grid item md={6} xs={12}>
                                <Typography sx={inputLabelsStyles}>
                                    Fecha inicial
                                </Typography>
                                <DateTimePicker
                                    disableFuture
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: null,
                                        seconds: null,
                                    }}
                                    ampm={false}
                                    format="YYYY-MM-DD HH:mm"
                                    value={since}
                                    minutesStep={60}
                                    views={["day", "hours"]}
                                    onChange={(value: any) => {
                                        handleDateChange("since", value);
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            sx: datePickerStyles,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography sx={inputLabelsStyles}>
                                    Fecha final
                                </Typography>
                                <DateTimePicker
                                    disableFuture
                                    format="YYYY-MM-DD HH:mm"
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: null,
                                        seconds: null,
                                    }}
                                    ampm={false}
                                    value={until}
                                    minutesStep={60}
                                    views={["day", "hours"]}
                                    onChange={(value) => {
                                        handleDateChange("until", value);
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            sx: datePickerStyles,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Box>
                {error && (
                    <Box mb={1}>
                        <CustomAlert
                            severity="error"
                            text={error}
                            onClose={() => setError(undefined)}
                        />
                    </Box>
                )}
                {success && (
                    <Box mb={1}>
                        <CustomAlert
                            severity="success"
                            text={
                                "Se ha enviado el comando de recuperación con éxito."
                            }
                            onClose={() => setSuccess(false)}
                        />
                    </Box>
                )}
                <LoadingButton
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ color: "white" }}
                    loading={loading}
                    onClick={onRestoreSchedules}
                >
                    Recuperar
                </LoadingButton>
            </Box>
        </Modal>
    );
};

export default RestoreSchedulesModal;

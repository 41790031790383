import { Box, styled } from "@enerbit/base";

interface CustomCardProps {
    borderColor?: string;
}

export const CustomCard = styled(Box)<CustomCardProps>`
   border: 1px solid ${(props) => props.borderColor || "#ccc"};
   border-radius: 12px;
   margin: 20px 0;
   padding: 20px;
   background: #FFF;
`;

import { createSlice } from "@reduxjs/toolkit";
import { FrontiersState } from "../../models/frontiers";
import { MeasurementsState } from "../../models/measurements";
import { getMeasures } from "../thunks/measurements";
import { formatUsagesChartData } from "../../utils/frontiers";
import { Serie } from "@nivo/line";

const initialState: MeasurementsState = {
    measurements: [],
    loading: false,
    error: false,
    empty: false,
    // since: "",
    // until: "",
};

export const measurementsSlice = createSlice({
    name: "measurements",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getMeasures.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.empty = false;
        });
        builder.addCase(getMeasures.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.measurements = formatUsagesChartData(payload);
            if (payload.length === 0) {
                state.empty = true;
            }
        });
        builder.addCase(getMeasures.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.empty = false;
        });
    },
});

export const {} = measurementsSlice.actions;

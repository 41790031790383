import { Button, type GridColDef } from "@enerbit/base";
import { useNavigate } from "react-router-dom";
import { ISwapperRows } from "../interfaces";

export const columnsSwapper: GridColDef<ISwapperRows[][number]>[] = [
    { field: "origin", headerName: "Origen", flex: 1 },
    { field: "serial", headerName: "Serial instalado", flex: 1 },
    { field: "report_serial", headerName: "Serial reportado", flex: 1 },
    { field: "observations", headerName: "Observaciones", flex: 1 },
    {
        field: "action",
        headerName: "Acciones",
        flex: 1,
        renderCell: (params) => {
            const navigate = useNavigate();
            const handleClick = () => {
                params.value = navigate(`/swap-meters/${params.id}`);
            };

            return (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: "35px" }}
                    onClick={handleClick}
                >
                    Revisar
                </Button>
            );
        },
    },
];

import { api, initEnviroment, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import MainLayout from "./layout/MainLayout";
import Router from "./router/Router";
import { SWRConfig } from "swr";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export default hot(function Root() {
    return (
        <ThemeConfig>
            <PrivateRoute>
                <SWRConfig
                    value={{
                        fetcher,
                        keepPreviousData: true,
                    }}
                >
                    <MainLayout>
                        <Router />
                    </MainLayout>
                </SWRConfig>
            </PrivateRoute>
        </ThemeConfig>
    );
});

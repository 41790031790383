import {
	LibraryOptionsModel,
	TLibraryDocument,
} from "../models/LibraryConsult";

export const libraryOptions: Record<TLibraryDocument, LibraryOptionsModel> = {
	productMeterConformity: {
		title: "Certificado de conformidad de producto de medidor",
		completeInfo: false,
		fileName: "Certificado de conformidad de producto de medidor",
	},
	technicalEquipment: {
		title: "Ficha técnica equipos de medida",
		completeInfo: false,
		fileName: "Ficha técnica equipos de medida",
	},
	terminalBlockCertificate: {
		title: "Certificado de conformidad producto: Bornera",
		completeInfo: false,
		fileName: "Certificado de conformidad producto: Bornera",
	},
	wiresCertificate: {
		title: "Certificado de conformidad producto: Cable",
		completeInfo: false,
		fileName: "Certificado de conformidad producto - Cable",
	},
	cellsCertificate: {
		title: "Certificado de conformidad producto: Celda",
		completeInfo: false,
		fileName: "Certificado de conformidad producto - Celda",
	},
	meterCalibrationCertificate: {
		title: "Certificado de calibración de medidores",
		completeInfo: false,
		fileName: "Certificado de calibración de medidores",
	},
	tcCalibrationCertificate: {
		title: "Certificado de calibración de TC",
		completeInfo: false,
		fileName: "Certificado de calibración de TC",
	},
	ttCalibrationCertificate: {
		title: "Certificado de calibración de TT",
		completeInfo: false,
		fileName: "Certificado de calibración de TT",
	},
	// routinesTestCertificate: {
	//   title: "Certificado de pruebas de rutina",
	//   completeInfo: false,
	//   fileName: "Certificado de pruebas de rutina",
	// },
	policiesCGM: {
		title: "Parámetros procedimientos y políticas CGM",
		completeInfo: false,
		fileName: "Parámetros procedimientos y políticas CGM",
	},
	tlmSchema: {
		title: "Esquema TLM y comunicación medidores",
		fileName: "Esquema TLM y comunicación medidores",
	},
	opCommunications: {
		title: "Condiciones op comunicaciones, medidores y cgm",
		fileName: "Condiciones op comunicaciones, medidores y cgm",
	},
	infoProcess: {
		title: "Procedimiento para la crítica de información",
		fileName: "Procedimiento para la crítica de información",
	},
	meterValidationProcess: {
		title: "Procedimiento validación de datos leídos de los medidores",
		fileName: "Procedimiento validación de datos leídos de los medidores",
	},
	securityMechanism: {
		title: "Mecanismo de protección, seguridad e integridad",
		fileName: "Mecanismo de protección, seguridad e integridad",
	},
	dataSecurity: {
		title:
			"Procedimiento seguridad física e informática para protección de datos",
		fileName:
			"Procedimiento seguridad física e informática para protección de datos",
	},
	cgmDataProtection: {
		title: "Procedimiento transmisión de datos CGM-ASIC",
		fileName: "Procedimiento transmisión de datos CGM-ASIC",
	},
	maintenanceProcedure: {
		title: "Procedimiento de mantenimiento de la medida",
		fileName: "Procedimiento de mantenimiento de la medida",
	},
	anualReportCGM: {
		title: "Informe anual operación CGM",
		fileName: "Informe anual operación CGM",
	},
	recordDossierPreviousSupplier: {
		title: "Hoja de vida comercializador previo",
		fileName: "Hoja de vida comercializador previo",
	},
	recordDossier: {
		title: "Hoja de vida",
		fileName: "Hoja de vida",
	},
};

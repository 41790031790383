import {
    Box,
    InputLabel,
    FormControl,
    SearchIcon,
    Button,
    IconButton,
    CachedIcon,
    theme,
    useMediaQuery,
} from "@enerbit/base";
import CustomTextField from "../../../components/Inputs/CustomTextField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import AbcIcon from '@mui/icons-material/Abc';
import { Filters } from "../../../models";

interface Props {
    filters: Filters;
    handleFiltersChange: (key: string, value: string) => void;
    resetFilters: () => void;
    handleSearch: () => void;
}

const AgendaTableFilters = ({
    filters,
    handleFiltersChange,
    resetFilters,
    handleSearch,
}: Props) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "end",
                gap: 2,
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    flex: 1,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Nombre</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.name}
                            onChange={(e) =>
                                handleFiltersChange("name", e.target.value)
                            }
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Número de teléfono</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.phone_number}
                            onChange={(e) =>
                                handleFiltersChange(
                                    "phone_number",
                                    e.target.value,
                                )
                            }
                            InputProps={{
                                startAdornment: <LocalPhoneIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Email</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.email}
                            onChange={(e) =>
                                handleFiltersChange("email", e.target.value)
                            }
                            InputProps={{
                                startAdornment: <MailIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Id del proceso</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.process_id}
                            onChange={(e) =>
                                handleFiltersChange(
                                    "process_id",
                                    e.target.value,
                                )
                            }
                            InputProps={{
                                startAdornment: <AbcIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    alignItems: "center",
                    mt: isSmallScreen ? 2 : 0,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    fullWidth={isSmallScreen} 
                >
                    <SearchIcon />
                </Button>
                <IconButton
                    color="primary"
                    onClick={resetFilters}
                    sx={{
                        width: isSmallScreen ? "100%" : "auto",
                        justifyContent: "center",
                    }}
                >
                    <CachedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default AgendaTableFilters;

import { create } from "zustand";
import type { ISwapperRows } from "../interfaces";

type Store = {
    swapMeters: ISwapperRows[];
    setSwapMeters: (data: ISwapperRows[]) => void;
};

export const useSwapMetersStore = create<Store>((set) => ({
    swapMeters: [],
    setSwapMeters: (data) => set({ swapMeters: data }),
}));

import { create } from "zustand";
import { IRowsInconsistency } from "../interfaces";

interface IRowData {
    rows: IRowsInconsistency[];
    total: number;
}

type Store = {
    rows: IRowData;
    setRows: (newRows: IRowData) => void;
};

export const useInconsistenciesStore = create<Store>((set) => ({
    rows: {
        rows: [],
        total: 0,
    },
    setRows: (rowData: IRowData) => set({ rows: rowData }),
}));

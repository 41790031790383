import CustomContainer from "../../../../components/CustomContainer";
import * as d3 from "d3";
import {
    Box,
    CustomAlert,
    Typography,
    enerbitColors,
    styled,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import moment from "moment";
import { useMeasure } from "react-use";
import { generateRandomId } from "../../../../utils/common";
import { mapValuesToSignalNames } from "../../../../utils/device";
import { useEffect } from "react";

const SignalCoverage = () => {
    const { operator, signal, error } = useSelector(
        (state: RootState) => state.signalCoverage,
    );
    const [ref, { width, height }] = useMeasure();
    const margin = { top: 20, right: 50, bottom: 20, left: 80 };

    const drawChart = () => {
        const data: [number, number][] = signal.map((s) => [
            moment(s.date).valueOf(),
            s.signalStatus,
        ]);

        console.log("signal: ", signal);

        const svg = d3.select("#coverage-chart");
        svg.attr("width", width).attr("height", height);

        //Scales
        const x = d3.scaleTime();
        const xDomain = signal.map((s) => moment(s.date).valueOf());
        x.domain([d3.min(xDomain) ?? 0, d3.max(xDomain) ?? 100]);
        x.range([margin.left, width - margin.right]);

        // console.log("x ticks: ", x.ticks(2));

        const y = d3.scaleLinear();
        y.domain([0, 4]);
        y.range([height - margin.bottom, margin.top]);

        // Clear previous content
        svg.selectAll("*").remove();

        y.ticks().forEach((max) => {
            if (![0, 1, 2, 3, 4].includes(max)) return;
            const group = svg
                .append("g")
                .attr("transform", `translate(0, ${y(max)})`);
            group
                .append("line")
                .attr("key", generateRandomId())
                .attr("x1", margin.left)
                .attr("x2", width - margin.right)
                .attr("stroke", "#E4E7EC");
            group
                .append("text")
                .attr("key", generateRandomId())
                .style("font-size", "13px")
                .attr("fill", enerbitColors.neutral.main)
                .text(mapValuesToSignalNames(max));
        });

        signal.forEach((s) => {
            const group = svg
                .append("g")
                .attr(
                    "transform",
                    `translate(${x(moment(s.date) ?? 0)},${height})`,
                );
            group
                .append("text")
                .attr("key", generateRandomId())
                .style("font-size", "13px")
                .attr("fill", enerbitColors.neutral.main)
                .text(moment(s.date).format("HH:mm"));
        });

        // Create line object for generate line chart
        const line = d3.line();
        line.x((d) => x(d[0]));
        line.y((d) => y(d[1]));

        const dLine = line(data);

        svg.append("path")
            .attr("d", dLine)
            .attr("fill", "none")
            .attr("stroke", "#FF7705")
            .attr("strokeWidth", "2px");

        data.map((d) => {
            svg.append("circle")
                .attr("key", generateRandomId())
                .attr("cx", x(d[0]))
                .attr("cy", y(d[1]))
                .attr("r", 3)
                .attr("fill", "#FF7705");
        });
    };

    useEffect(() => {
        drawChart();
        console.log("signal: ", signal);
    }, [width, height, signal]);

    return (
        <CustomContainer
            sx={{ width: "100%", height: "342px", borderRadius: "0" }}
        >
            {error ? (
                <CustomAlert
                    text="Error cargando señal del smartBit"
                    severity="error"
                    onClose={() => {}}
                />
            ) : (
                <>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            color: enerbitColors.primary.main,
                            fontSize: "20px",
                        }}
                    >
                        Cobertura
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                        }}
                    >
                        <ChartIndicator />
                        <Typography sx={{ color: enerbitColors.neutral.main }}>
                            {operator}
                        </Typography>
                    </Box>
                    <Box
                        sx={{ height: "calc(100% - 100px)" }}
                        ref={ref}
                        className="chart-container"
                    >
                        <svg id="coverage-chart"></svg>
                    </Box>
                </>
            )}
        </CustomContainer>
    );
};

export default SignalCoverage;

const ChartIndicator = styled("div")(() => ({
    width: "36px",
    height: "2px",
    backgroundColor: "#FF7705",
}));

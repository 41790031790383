import { PrivateRoute, ThemeConfig } from "@enerbit/base";

import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import AttentionPage from "../pages/AttentionPage";
import { store } from "../store/store";
export default function Routers() {
  return (
    <Provider store={store}>
      <PrivateRoute>
        <ThemeConfig>
          <section className="Container-attention">
            <HashRouter>
              <Routes>
                <Route
                  path="/electricity-supply-service/attention/:id"
                  element={<AttentionPage />}
                />
              </Routes>
            </HashRouter>
          </section>
        </ThemeConfig>
      </PrivateRoute>
    </Provider>
  );
}

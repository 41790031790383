import { styled } from "@enerbit/base";
import { useSelector } from "react-redux";
import LeftMenu from "../common/LeftMenu/LeftMenu";
import ListTabs from "../common/Tabs/ListTabs";
import type { StateStorage } from "../models/store/StateStorage";
import type { TabModel } from "../models/ui/Tab";

import { BillingPage } from "./components/Tabs/billing/BillingPage";
import { Information } from "./components/Tabs/information/Information";
import UsagesPage from "./components/Tabs/usages/components/UsagesPage";
import ServiceOrdersContainer from "./service-orders/ServiceOrdersContainer";

const AttentionPage = () => {
  const { service, estates } = useSelector(
    (state: StateStorage) => state.information
  );
  const { enerbitFrontiers } = useSelector(
    (state: StateStorage) => state.assignees
  );

  const tabs: TabModel[] = [
    {
      label: "Facturación",
      component: <BillingPage />,
    },
    {
      label: "Consumos",
      component: <UsagesPage />,
      // biome-ignore lint/complexity/noUselessTernary: <explanation>
      disabled: service?.started_at ? false : true,
    },
    {
      label: "Información",
      component: <Information />,
      disabled: service && estates && enerbitFrontiers ? false : true,
    },
    {
      label: "Órdenes de servicio",
      component: <ServiceOrdersContainer />,
      // biome-ignore lint/complexity/noUselessTernary: <explanation>
      disabled: service?.measurement_point_id ? false : true,
    },
  ];

  return (
    <AttentionPageContainer>
      <LeftMenu />
      <ContentContainer>
        <ListTabs tabs={tabs} />
      </ContentContainer>
    </AttentionPageContainer>
  );
};

export default AttentionPage;

const AttentionPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled("div")`
  padding: 1.5rem 2.25rem 2.25rem 2rem;
  width: 100%;
`;

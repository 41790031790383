import { Button, type GridColDef } from "@enerbit/base";
import { useNavigate } from "react-router-dom";
import { IRowsInconsistency } from "../interfaces";

export const columns: GridColDef<IRowsInconsistency[][number]>[] = [
    { field: "origin", headerName: "Origen del reporte", flex: 1 },
    { field: "serial", headerName: "Serial de medidor (MP)", flex: 1 },
    {
        field: "serial_certificate",
        headerName: "Serial medidor en el acta",
        flex: 1,
    },

    {
        field: "action",
        headerName: "Acciones",
        flex: 1,
        renderCell: (params) => {
            const navigate = useNavigate();
            const handleClick = () => {
                const type = params.row.type;
                const url =
                    type === "swap"
                        ? "/swap-meters"
                        : `/inconsistencies-data/${params.row.id}`;
                params.value = navigate(url);
            };

            return (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: "35px" }}
                    onClick={handleClick}
                >
                    Revisar
                </Button>
            );
        },
    },
];

class Energy {
    unit: string;
    reactiveUnit: string;
    value: number;
    constructor({
        unit,
        reactiveUnit,
        value,
    }: { unit: string; reactiveUnit: string; value: number }) {
        this.unit = unit;
        this.reactiveUnit = reactiveUnit;
        this.value = value;
    }
}

export function convertToEnergy({
    value,
    fixedUnit = "",
}: { value: number; fixedUnit?: string }) {
    const units = {
        wh: { unit: "wh", reactiveUnit: "vArh", divisor: 1 },
        kWh: { unit: "kWh", reactiveUnit: "kVArh", divisor: 1000 },
        mWh: { unit: "mWh", reactiveUnit: "mVArh", divisor: 1000000 },
    };

    if (fixedUnit && units[fixedUnit as keyof typeof units]) {
        const { unit, reactiveUnit, divisor } =
            units[fixedUnit as keyof typeof units];
        return new Energy({ unit, reactiveUnit, value: value / divisor });
    }

    // Dynamic unit selection
    if (value > -1000 && value < 1000) {
        return new Energy({
            unit: units.wh.unit,
            reactiveUnit: units.wh.reactiveUnit,
            value,
        });
    } else if (value > -1000000 && value < 1000000) {
        return new Energy({
            unit: units.kWh.unit,
            reactiveUnit: units.kWh.reactiveUnit,
            value: value / units.kWh.divisor,
        });
    } else {
        return new Energy({
            unit: units.mWh.unit,
            reactiveUnit: units.mWh.reactiveUnit,
            value: value / units.mWh.divisor,
        });
    }
}

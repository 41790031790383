import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Grid,
    Typography,
    styled,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { getMissingHours } from "../../services";
import { generateUsagesCsv, getDaysDiff } from "../../helpers";
import MissingHoursItem from "./MissingHoursItem";
import CachedIcon from "@mui/icons-material/Cached";
import moment from "moment";
import MissingHoursActions from "../MissingHoursAction/MissingHoursActions";
import { MeasuresModel, MissingHoursStatus } from "../../models/frontiers";
import { HOURS, MISSING_HOURS_LABELS } from "../../const";

interface Props {
    meterSerial: string;
    meterId: string;
}

const MissingHours = ({ meterSerial, meterId }: Props) => {
    const [missingHours, setMissingHours] =
        useState<Record<string, Record<string, MissingHoursStatus>>>();
    const [groupedDates, setGroupedDates] =
        useState<Record<string, MeasuresModel[]>>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [since, setSince] = useState<string | null>("");
    const [until, setUntil] = useState<string | null>("");

    const _getMissingHours = async (
        since?: string | null,
        until?: string | null,
        addedDay: boolean = false,
    ) => {
        setError(undefined);
        let diff = -1;
        if (since && until) {
            const tUntil = addedDay
                ? moment(until)
                      .subtract(1, "day")
                      .startOf("hour")
                      .format("YYYY-MM-DDTHH:mm:ssZZ")
                : until;
            diff = getDaysDiff(since, tUntil);
        }

        if (diff + 1 > 7) {
            setError("El rango de días a consultar supera los 7 días.");
            return;
        }

        setLoading(true);

        const defaultSince = moment()
            .subtract(2, "days")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ssZZ");
        const defaultUntil = moment()
            .startOf("hour")
            .format("YYYY-MM-DDTHH:mm:ssZZ");

        try {
            const _missingHours = await getMissingHours(
                meterSerial,
                since ?? defaultSince,
                until ?? defaultUntil,
            );

            setMissingHours(_missingHours.missingHours);

            setGroupedDates(_missingHours.groupedByDates);
        } catch (error) {
            setError("Error cargando las horas faltantes para este medidor");
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        let tSince = moment(since).format("YYYY-MM-DDTHH:mm:ssZZ");
        let tUntil;
        let addedDay: boolean;

        const currentDate = moment();

        if (
            currentDate.format("YYYY-MM-DD") ===
            moment(until).format("YYYY-MM-DD")
        ) {
            tUntil = currentDate
                .startOf("hour")
                .format("YYYY-MM-DDTHH:mm:ssZZ");
            addedDay = false;
        } else {
            tUntil = moment(until)
                .add(1, "day")
                .format("YYYY-MM-DDTHH:mm:ssZZ");
            addedDay = true;
        }

        _getMissingHours(tSince, tUntil, addedDay);
    };

    useEffect(() => {
        _getMissingHours();
    }, []);

    const onRefresh = () => {
        _getMissingHours();
        setSince(null);
        setUntil(null);
    };

    const onGenerateFile = () => {
        if (!groupedDates) return;

        generateUsagesCsv(groupedDates, meterSerial);
    };

    return (
        <Box
            sx={{
                minHeight: "280px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "16px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography>Estado:</Typography>
                    {MISSING_HOURS_LABELS.map((label, index) => (
                        <StatusIndicator
                            key={index}
                            fontColor={label.color}
                            bgColor={label.bgcolor}
                        >
                            {label.label}
                        </StatusIndicator>
                    ))}
                    <Button
                        sx={{
                            width: "28px",
                            minWidth: "unset !important",
                            height: "32px",
                            borderRadius: "8px !important",
                        }}
                        onClick={onRefresh}
                        disabled={loading}
                        variant="contained"
                        color="primary"
                    >
                        <CachedIcon />
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                    }}
                >
                    <MissingHoursActions
                        since={since}
                        setSince={setSince}
                        until={until}
                        setUntil={setUntil}
                        handleSearch={handleSearch}
                        onGenerateFile={onGenerateFile}
                    />
                </Box>
            </Box>
            {error && (
                <Box my={4}>
                    <CustomAlert
                        severity="error"
                        text={error}
                        onClose={() => setError(undefined)}
                    />
                </Box>
            )}
            {!loading &&
                missingHours &&
                Object.keys(missingHours).length > 0 && (
                    <>
                        <Grid container columnSpacing={2} mt={3}>
                            <Grid item xs={1.3}></Grid>
                            {HOURS.map((hour) => (
                                <Grid item xs={0.43} key={hour}>
                                    <Typography
                                        sx={{ color: "#120C20" }}
                                    >{`H${hour.split(":")[0]}`}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                        {Object.keys(missingHours)
                            .reverse()
                            .map((key) => (
                                <MissingHoursItem
                                    key={key}
                                    dayKey={key}
                                    hours={missingHours[key]}
                                    meterId={meterId}
                                />
                            ))}
                    </>
                )}
            {!loading &&
                missingHours &&
                Object.keys(missingHours).length === 0 && (
                    <Box my={4}>
                        <CustomAlert
                            severity="info"
                            text={
                                "No hay datos por mostrar. Por favor refresca."
                            }
                            onClose={() => {
                                return;
                            }}
                        />
                    </Box>
                )}
            {loading && (
                <Box
                    my={4}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default MissingHours;

interface StatusColor {
    bgColor: string;
    fontColor: string;
}

const StatusIndicator = styled("div")<StatusColor>(
    ({ bgColor, fontColor }) => ({
        width: "122px",
        height: "32px",
        backgroundColor: bgColor,
        color: fontColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
    }),
);

import { Grid, Typography, enerbitColors } from "@enerbit/base";
import CustomContainer from "../../../../components/Containers/CustomContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const GeneralInformation = () => {
    const { frtGeneralData } = useSelector(
        (state: RootState) => state.frontiers,
    );

    return (
        <CustomContainer>
            <Typography
                sx={{
                    color: enerbitColors.neutral[700],
                    fontWeight: 700,
                    fontSize: "20px",
                    mb: 1,
                }}
            >
                Información
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={1}>
                {frtGeneralData.map((item, index) => (
                    <Grid
                        item
                        key={index}
                        md={3}
                        xs={12}
                        sx={{ color: enerbitColors.neutral.main }}
                    >
                        <Typography>{item.label}</Typography>
                        <Typography sx={{ fontWeight: 700 }}>
                            {item.value}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </CustomContainer>
    );
};

export default GeneralInformation;

import { configureStore } from "@reduxjs/toolkit";
import { generalInfoSlice } from "./slices/generalInfo";
import { associatedMetersSlice } from "./slices/associatedMeters";
import { signalCoverageSlice } from "./slices/signalCoverage";
import { ordersHistorySilce } from "./slices/ordersHistory";
import { sbEventsSlice } from "./slices/sbEvents";

export const store = configureStore({
    reducer: {
        generalInfo: generalInfoSlice.reducer,
        associatedMeters: associatedMetersSlice.reducer,
        signalCoverage: signalCoverageSlice.reducer,
        ordersHistory: ordersHistorySilce.reducer,
        sbEvents: sbEventsSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Pagination } from "@enerbit/base";
import { FC } from "react";

interface Props {
    count: number;
    page: number;
    onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const CustomPagination: FC<Props> = ({ count, page, onChange }) => {
    return (
        <Pagination
            count={count}
            page={page}
            shape="rounded"
            onChange={onChange}
        />
    )
}

import { Skeleton, Stack } from "@enerbit/base";

const SkeletonCard = () => {
    return (
        <Stack spacing={2} direction={"column"} sx={{ mt: 2 }}>
            <Skeleton variant="rounded" width="100%" />
            <Skeleton variant="rounded" width="100%" height="200px" />
            <Skeleton variant="rounded" width="100%" height="100px" />
        </Stack>
    );
};

export default SkeletonCard;

import { dayjs } from "@enerbit/base";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
export const formatDateToText = (date: string, showYear: boolean = true) => {
    if (!date) return "No seleccionado";
    var showDate = new Date(date);
    return showDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: showYear ? "numeric" : undefined,
    });
};

export const getFirstDay = () => {
    const date = new Date();
    const inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    return inputDate.startOf("month").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const getInitDay = (date: Date, year?: number) => {
    let inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5

    if (year) {
        inputDate = inputDate.year(year);
    }

    return inputDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const getLastDayOfMonth = (date: Date, year?: number): string => {
    let inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    const currentDate = dayjs().tz("America/Bogota"); // Fecha actual en UTC-5

    if (year) {
        inputDate = inputDate.year(year);
    }

    // Verificar si es el mes actual
    if (
        inputDate.isSame(currentDate, "month") &&
        inputDate.isSame(currentDate, "year")
    ) {
        return currentDate.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"); // Fecha actual
    }

    // Si no es el mes actual, devolver el último día del mes
    return inputDate.endOf("month").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const getNextDay = (date: Date): string => {
    const now = dayjs().tz("America/Bogota"); // Hora actual en UTC-5
    const inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    if (inputDate.isSame(now, "day")) {
        return now.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    } else {
        return inputDate.endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    }
};

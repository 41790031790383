import { enerbitColors, useTheme } from "@enerbit/base";

export const customDataGrid = () => {
    const theme = useTheme();

    return {
        ".MuiDataGrid-columnSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root": {
            border: "none",
            padding: theme.spacing(1), // Usa el espaciado del tema
        },
        "& .MuiDataGrid-row": {
            cursor: "pointer",
            border: "none",
            "&:hover": {
                backgroundColor: enerbitColors.information[100],
            },
            fontSize: "13px",
        },
        "& .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: enerbitColors.information[100], // Usa el color de fondo del tema
        },
    };
};

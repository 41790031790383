import { api } from "@enerbit/base";
import moment from "moment";
import { groupMissingHours, searchMissingHours } from "../helpers";
import {
    AssociatedMeters,
    MeasuresModel,
    MeterRelationshipResponse,
    MeterStatusModel,
    MissingHoursStatus,
} from "../models/frontiers";
import { ReporAttempsResponse, XMResultModel } from "../models/reports";

export const getMetersList = async (serial: string) => {
    const res = await api.get(
        `/meter-reports/meters-latest-reports/?intervals_hours=24&size=20&meter_serial=${serial}`,
    );

    return res.data.items[0];
};

export const getMetersDetail = async (meterSerial: string) => {
    const res = await api.get(`/meters/meters/?serial=${meterSerial}`);

    return res.data.items[0];
};

export const getMeterRelationshipDevice = async (meterId: string) => {
    const relationships = await api.get<MeterRelationshipResponse>(
        `/electricity-supply-service/device/device-meters/relationship/meters/${meterId}/`,
    );

    const deviceId = relationships.data.items[0].device_meter.id;

    return deviceId;
};

export const getAssociatedMeters = async (deviceId: string) => {
    const res = await api.get<AssociatedMeters[]>(
        `/verification/smartbits/associated-meters?device_id=${deviceId}`,
    );

    return res.data;
};

export const getMeterUsages = async (
    meterSerial: string,
    since?: string,
    until?: string,
) => {
    const tempSince = since
        ? `${since}T00:00+00:00`
        : `${moment().subtract(3, "days").format("YYYY-MM-DD")}T00:00+00:00`;
    const tempUntil = until
        ? `${until}T00:00+00:00`
        : `${moment().format("YYYY-MM-DD")}T00:00+00:00`;

    const res = await api.get(
        `/meter-reports/meters-usages/measurements?meter_serial=${meterSerial}&since=${encodeURIComponent(
            tempSince,
        )}&until=${encodeURIComponent(tempUntil)}&period_number=1&period_str=day`,
    );

    return res.data;
};

export const getMeterSerial = async (id: string) => {
    const res = await api.get(`/meters/meters/${id}/`);

    return res.data.serial;
};

export const generateSicReport = async (date: string) => {
    const res = await api.post(
        `/electricity-supply-service/device/asic-report/since/${encodeURIComponent(
            date,
        )}/until/${encodeURIComponent(date)}/generator/`,
    );

    return res;
};

export const getMissingHours = async (
    meterSerial: string,
    since: string,
    until: string,
) => {
    const res = await api.get(
        `/meter-reports/meters-usages/schedules?meter_serial=${meterSerial}&since=${encodeURIComponent(
            since,
        )}&until=${encodeURIComponent(until)}`,
    );

    const tempData = res.data.map((d: MeasuresModel) => ({
        ...d,
        time_start: moment(d.time_start).format("YYYY-MM-DDTHH:mm:ssZ"),
        time_end: moment(d.time_end).format("YYYY-MM-DDTHH:mm:ssZ"),
    }));

    const groupedDates = groupMissingHours(tempData);

    const data: Record<string, Record<string, MissingHoursStatus>> = {};

    Object.keys(groupedDates).forEach((key) => {
        const missingHours = searchMissingHours(groupedDates[key], key);
        data[key] = missingHours;
    });

    return { groupedByDates: groupedDates, missingHours: data };
};

export const refustigateMeter = async (date: string, serials: string[]) => {
    const res = await api.post(
        "/electricity-supply-service/device/meters/refustigate-days",
        {
            date,
            meter_serials: serials,
        },
    );

    return res;
};

export const restoreSchedules = async (
    meterId: string,
    since: string,
    until: string,
) => {
    const res = await api.post(
        `/electricity-supply-service/device/meters/${meterId}/restore-schedules/?since=${encodeURIComponent(
            since,
        )}&until=${encodeURIComponent(until)}`,
    );

    return res;
};

export const restoreSchedulesByDeviceName = async (
    meterId: string,
    since: string,
    until: string,
) => {
    const deviceData = await api.get(
        `/electricity-supply-service/device/device-meters/meters/${meterId}/`,
    );

    const deviceName = deviceData.data.device_meter.name;

    const res = await api.post(
        `/electricity-supply-service/device/devices-name-iothub/${deviceName}/meters/restore-schedules/?since=${encodeURIComponent(
            since,
        )}&until=${encodeURIComponent(until)}`,
    );

    return res;
};

export const getMeterStatus = async (meterId: string) => {
    const res = await api.get<MeterStatusModel>(
        `/electricity-supply-service/device/meters/${meterId}/relay-status/`,
    );

    return res.data;
};

export const deleteRestoreActiveCommand = async (meterId: string) => {
    await api.delete(
        `/electricity-supply-service/device/meters/${meterId}/active-command-state/`,
    );
};

export const sendXMReport = async (reportId: string) => {
    const res = await api.post<string>(
        `/electricity-supply-service/device/asic-report/${reportId}/xm-soap-service/`,
    );
    return res.data;
};

export const getReportAttemps = async (reportId: string) => {
    const res = await api.get<ReporAttempsResponse>(
        `/electricity-supply-service/device/reports/${reportId}/report-attempts/`,
    );

    return res.data.items;
};

export const getReportState = async (processId: string) => {
    const res = await api.get<XMResultModel>(
        `/electricity-supply-service/device/asic-report/process-result/${processId}/xm-soap-service/`,
    );

    return res.data;
};

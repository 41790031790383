import { api, EnerbitPagination } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import { getRangeISO } from "../../../helpers/validationsDateTime";
import { EEntity } from "../../../models/enums/EServiceOrder";
import { ICancellationReasons } from "../../../models/interfaces/ICancellationReasons";
import {
  ICancelServiceOrder,
  ICancelServiceOrderResponse,
} from "../../../models/interfaces/IICancelServiceOrder";
import { IRespActivities } from "../../../models/interfaces/IRespActivities";
import { ItemGetOrders } from "../../../models/interfaces/ItemGetOrders";
import { IDateRange } from "../../../models/store/states/ServiceOrdersState";

export const getServicesOrders = createAsyncThunk(
  "listOrders/getServicesOrders",
  async (
    {
      id,
      entity,
      page = 0,
      size = 9,
      dateRange,
      status,
      orderTypeId,
    }: {
      id: string;
      entity: EEntity;
      page?: number;
      size?: number;
      dateRange: IDateRange;
      status?: string;
      orderTypeId?: string;
    },
    thunkAPI
  ) => {
    try {
      const { start, end } = getRangeISO(dateRange);
      const { data } = await api.get<EnerbitPagination<ItemGetOrders>>(
        `/service-orders/orders?sort_planned_date_begin=DESC&assignee_id=${id}${
          entity ? `&entity_code=${entity}` : ""
        }${status ? `&status=${status}` : ""}${
          orderTypeId ? `&order_type_id=${orderTypeId}` : ""
        }&page=${page}&size=${size}${
          dateRange.startDate ? `&planned_date_begin=${start}` : ""
        }${dateRange.endDate ? `&planned_date_end=${end}` : ""}`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

export const getServiceOrderTypes = createAsyncThunk(
  "initialForm/getServiceOrderTypes",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get<IRespActivities>(
        "/service-orders/order-types"
      );
      return data.items
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((activity) => activity.is_active);
    } catch (error) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

export const getCancelReasons = createAsyncThunk(
  "initialForm/getCancelReasons",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get<ICancellationReasons>(
        "/service-orders/cancellation-reasons?page=0&size=50"
      );
      return data.items.filter(
        (reason) => reason.is_active && reason.available_in.includes("estadium")
      );
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

export const cancelServiceOrder = createAsyncThunk(
  "initialForm/cancelServiceOrder",
  async (order: ICancelServiceOrder, thunkAPI) => {
    try {
      const body: Omit<ICancelServiceOrder, "cancellation_responsible"> = {
        cancel_by: order.cancel_by,
        cancellation_reason_id: order.cancellation_reason_id,
        form_data: order.form_data,
        observations: order.observations,
        order_id: order.order_id,
        picture_url: order.picture_url,
      };

      const { data } = await api.post<ICancelServiceOrderResponse>(
        `/service-orders/order-cancel`,
        body
      );

      return data;
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError.response?.data);
    }
  }
);

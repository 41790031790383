import { GridColDef } from "@enerbit/base";
import {
    RestoreSchedulesOptions,
    RestoreSchedulesTypes,
    SBData,
} from "../../../models/smartbit";

export const GENERAL_INFO: { key: keyof SBData; label: string }[] = [
    { key: "fWVersion", label: "Versión firmware" },
    { key: "imei", label: "IMEI" },
    { key: "macAddress", label: "MAC" },
    { key: "operator", label: "Operador" },
    { key: "iccid", label: "ICCID" },
];

export const SB_EVENTS_TABLE_HEADERS: string[] = [
    "Evento",
    "Fecha",
    "Descripción",
];

export const REFUTIGATION_TABLE_HEADERS: GridColDef[] = [
    { field: "meterSerial", headerName: "Serial", width: 430 },
];

export const SB_EVENTS: Record<string, string> = {
    "0": "Error",
    "2": "Intervalo de mensaje",
    "4": "Estado de batería",
};

const batteryStatus = "Battery Status";
const deviceError = "Error";
const lastBreath = "Last Breath";
const messageInterval = "Message Interval";
const mainSupplyReconnection = "Main Supply reconnection";
const resetTaskWdt = '{"type":"RESET","reason":"TASK_WDT"}';
const resetIntWdt = '{"type":"RESET","reason":"INT_WDT"}';
const resetExt = '{"type":"RESET","reason":"EXT"}';
const resetPowerOn = '{"type":"RESET","reason":"POWERON"}';
const resetSW = '{"type":"RESET","reason":"SW"}';

export const DEVICE_EVENT_TYPES: Record<string, string> = {
    [batteryStatus]: "Estado de la batería",
    [deviceError]: "Error de hardware",
    [lastBreath]: "Último suspiro",
    [messageInterval]: "Intervalo de mensaje",
    [mainSupplyReconnection]: "Reconexión de suministro principal",
    [resetTaskWdt]: "Error de hardware: Reset - TASK_WDT",
    [resetIntWdt]: "Error de hardware: Reset - INT_WDT",
    [resetExt]: "Error de hardware: Reset - EXT",
    [resetPowerOn]: "Error de hardware: Reset - POWERON",
    [resetSW]: "Error de hardware: Reset - SW",
};

export const RESTORE_SCHEDULES_TYPES: RestoreSchedulesOptions[] = [
    { name: "Medidor", value: RestoreSchedulesTypes.METER },
    {
        name: "smartBit",
        value: RestoreSchedulesTypes.DEVICE,
    },
];

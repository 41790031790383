import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  theme,
} from "@enerbit/base";
import React from "react";

const CustomizedSelectSkeleton: React.FC = () => {
  return (
    <FormControl
      sx={{
        width: "300px",
        boxShadow: "none",
        color: theme.palette.primary.main,
        "& .MuiSelect-select": {
          margin: "0 !important",
          padding: "16.5px 0 !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    >
      <Select
        sx={{ padding: "0 !important" }}
        value=""
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={(props) => (
          <Skeleton variant="circular" width={24} height={24} />
        )}
      >
        {Array.from(new Array(4)).map((_, index) => (
          <MenuItem
            key={index}
            value=""
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "0 !important",
                margin: "0.9rem 0 !important",
              }}
            >
              <Box
                sx={{
                  padding: "0 0 !important",
                  justifyContent: "flex-end",
                  minWidth: "1.5rem !important",
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    marginRight: "0.8rem",
                    padding: "0.3rem 0.3rem",
                    borderRadius: "15%",
                    display: "flex",
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <Skeleton variant="circular" width={24} height={24} />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="text" width={200} height={20} />
                <Skeleton variant="text" width={150} height={20} />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomizedSelectSkeleton;

import { Box, Loader, enerbitColors } from "@enerbit/base";
import consumida from "../../../assets/img/graphic/consumida.png";
import flecha1 from "../../../assets/img/graphic/flecha1.png";
import flecha2 from "../../../assets/img/graphic/flecha2.png";
import flecha3 from "../../../assets/img/graphic/flecha3.png";
import imported from "../../../assets/img/graphic/importada.png";
import paneles from "../../../assets/img/graphic/paneles.png";
import {
    ArrowSection,
    ImageCard,
    NetaEnergy,
    Tooltip,
    WrapperArrow,
} from "../../../common/Graphic/CommonEnergy";
import { useGraphicsStore } from "../../../store/Graphics";
import { getTotalEnergyConsumed } from "../../../utils/Usages";

const EnergyInfo = () => {
    const { stadistic_energy_usages, isLoading, usages } = useGraphicsStore();

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Loader message={""} />
            </div>
        );
    }

    if (!isLoading && (!usages || usages?.length === 0))
        return (
            <p
                style={{
                    textAlign: "center",
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                No hay datos para mostrar
            </p>
        );

    const totalConsumed = getTotalEnergyConsumed(
        stadistic_energy_usages?.energy_accumulate
            .active_energy_selfconsumption ?? 0,
        stadistic_energy_usages?.energy_accumulate
            .conventional_active_energy_imported ?? 0,
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: "2.5rem",
                }}
            >
                {/* Panel superior */}
                <Box>
                    <Box
                        className="tooltip-solar tooltip-solar-down"
                        sx={{
                            margin: "auto",
                        }}
                    >
                        <Tooltip
                            value={
                                stadistic_energy_usages?.energy_accumulate
                                    .solar_active_energy_exported ?? 0
                            }
                            label={"Generada"}
                            color={"#53358e"}
                        />
                    </Box>
                    <ImageCard image={paneles} />
                </Box>

                {/* Primera fila de flechas */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <WrapperArrow sx={{ justifyContent: "end" }}>
                        <ArrowSection
                            arrow={flecha1}
                            isLeft={true}
                            value={
                                stadistic_energy_usages?.energy_accumulate
                                    .active_energy_selfconsumption ?? 0
                            }
                            text={"Autoconsumida"}
                            color={"#fa7707"}
                        />
                    </WrapperArrow>

                    <NetaEnergy
                        conventionalActiveEnergyImported={
                            stadistic_energy_usages?.energy_accumulate
                                .conventional_active_energy_imported ?? 0
                        }
                        conventionalActiveEnergyExported={
                            stadistic_energy_usages?.energy_accumulate
                                .conventional_active_energy_exported ?? 0
                        }
                    />

                    <WrapperArrow sx={{ justifyContent: "start" }}>
                        <ArrowSection
                            arrow={flecha2}
                            value={
                                stadistic_energy_usages?.energy_accumulate
                                    .conventional_active_energy_exported ?? 0
                            }
                            text={"Exportada a la red"}
                            color={"#3b83f6"}
                        />
                    </WrapperArrow>
                </Box>

                {/* Segunda fila: elementos base */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Box sx={{ flex: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <ImageCard image={consumida} />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <Box
                                className="tooltip-solar tooltip-solar-up"
                                sx={{
                                    mt: "14px",
                                    position: "relative",
                                    right: "12px",
                                }}
                            >
                                <Tooltip
                                    value={totalConsumed}
                                    label={"Consumida total"}
                                    color={"#53358e"}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            ml: "4px",
                        }}
                    >
                        <Box>
                            <ArrowSection
                                arrow={flecha3}
                                value={
                                    stadistic_energy_usages?.energy_accumulate
                                        .conventional_active_energy_imported ??
                                    0
                                }
                                text={<>Importada de la red</>}
                                color={"#55d4b6"}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 2,
                        }}
                    >
                        <ImageCard image={imported} />
                        <Box
                            className="tooltip-solar tooltip-solar-up"
                            sx={{
                                mt: "10px",
                                position: "relative",
                                left: "26px",
                            }}
                        >
                            <Tooltip
                                value={
                                    stadistic_energy_usages?.energy_accumulate
                                        .conventional_active_energy_imported ??
                                    0
                                }
                                label={"Importada"}
                                color={"#53358e"}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default EnergyInfo;

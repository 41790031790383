import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

interface TableRowsLoaderSkeletonProps {
  rowsNum: number;
  SkeletonComponent?: React.ElementType;
}

export const TableRowsLoaderSkeleton = ({
  rowsNum,
  SkeletonComponent,
}: TableRowsLoaderSkeletonProps) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {[...Array(rowsNum)].map((_, index) => (
            <TableRow key={index}>
              {SkeletonComponent ? (
                <TableCell>
                  <SkeletonComponent />
                </TableCell>
              ) : (
                <>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import {
  Box,
  Button,
  CircularProgress,
  ErrorOutlineOutlinedIcon,
  FlashOnOutlinedIcon,
  Typography,
  WarningIcon,
  enerbitColors,
  styled,
  theme,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleComponent from "../../../../helpers/CardInformationGeneral/TitleComponent";
import { ButtonConnectionProps } from "../../../../models/props/ButtonConnectionProps";
import { CircleProps } from "../../../../models/props/CircleProps";
import { ConnectionStatusCardProps } from "../../../../models/props/ConnectionStatusCard";
import { SpanStatusProps } from "../../../../models/props/SpanStatus";
import { StateStorage } from "../../../../models/store/StateStorage";
import {
  getRelayStatus,
  setOpenConfirmReconnectDialog,
} from "../../../../store/slices/informationSlice";
import { AppDispatch } from "../../../../store/store";
import {
  determineBackgroundColor,
  determineStatusColor,
  isDisabledButton,
  serviceActive,
} from "../../../../utils/connectionCardUtils";

export const ConnectionCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoadingServiceReconnect,
    service,
    serviceStatus,
    isLoadingServiceStatus,
    isSuccessServiceReconnect,
    serviceErrorObject,
  } = useSelector((state: StateStorage) => state.information);

  useEffect(() => {
    if (service?.meter?.id) {
      dispatch(getRelayStatus(service?.meter?.id));
    }
  }, [dispatch, service?.meter?.id, isSuccessServiceReconnect]);

  return (
    <ConnectionStatusCard
      status={determineStatusColor({
        isLoadingServiceReconnect,
        isLoadingServiceStatus,
        serviceStatus,
        serviceErrorObject,
      })}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <TitleComponent
          color={theme.palette.primary.main}
          IconTitle={
            serviceActive(service?.ended_at!)
              ? ErrorOutlineOutlinedIcon
              : WarningIcon
          }
          titleText={"Estado del servicio"}
          showButtonTop={false}
        />
      </Box>

      <>
        <Box
          display="flex"
          justifyContent="center"
          gap="1rem"
          sx={{
            alignItems: "center",
          }}
        >
          {isLoadingServiceStatus ? (
            <>
              <CircularProgress size={20} />
              Cargando Estado...
            </>
          ) : (
            <>
              {isLoadingServiceReconnect ? (
                <TypographyStatus>Enviando Comando...</TypographyStatus>
              ) : serviceStatus.relay_status === "ON" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <ButtonConnection
                        bgcolor={determineBackgroundColor({
                          serviceStatus,
                          isLoadingServiceReconnect,
                          backgroundColor: "#E6F9F4",
                        })}
                        colortext={`${enerbitColors?.secondary_green.main} !important`}
                        size="small"
                        variant="contained"
                        fullWidth
                        sx={{ m: "0.3rem 0.5rem 0 0" }}
                        onClick={() =>
                          dispatch(setOpenConfirmReconnectDialog(true))
                        }
                        disabled={true}
                      >
                        <FlashOnOutlinedIcon
                          sx={{
                            color: `${enerbitColors?.secondary_green.main}`,
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginRight: "0.3rem",
                          }}
                        />{" "}
                        Conectado
                      </ButtonConnection>
                      <ButtonConnection
                        bgcolor={determineBackgroundColor({
                          serviceStatus,
                          isLoadingServiceReconnect,
                          backgroundColor: enerbitColors?.primary[100],
                        })}
                        colortext={
                          false ? "#FFFFFF" : `${enerbitColors?.primary[300]}`
                        }
                        size="small"
                        variant="contained"
                        fullWidth
                        sx={{
                          m: "0.3rem 0 0 0",
                          border: `1px solid ${isDisabledButton({
                            isLoadingServiceStatus,
                            isLoadingServiceReconnect,
                            serviceStatus,
                            serviceErrorObject,
                          })}`,
                        }}
                        onClick={() =>
                          dispatch(setOpenConfirmReconnectDialog(true))
                        }
                        disabled={isDisabledButton({
                          isLoadingServiceStatus,
                          isLoadingServiceReconnect,
                          serviceStatus,
                          serviceErrorObject,
                        })}
                      >
                        <FlashOnOutlinedIcon
                          sx={{
                            marginRight: "0.3rem",
                            color: `${isDisabledButton({
                              isLoadingServiceStatus,
                              isLoadingServiceReconnect,
                              serviceStatus,
                              serviceErrorObject,
                            })} `,
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        />{" "}
                        Desconectar
                      </ButtonConnection>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        marginTop: "0.8rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <span
                          style={{
                            color: enerbitColors?.neutral[700],
                            fontWeight: "bold",
                            marginLeft: "2px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ErrorOutlineOutlinedIcon
                            sx={{
                              color: enerbitColors?.neutral[700],
                              fontSize: "10px",
                              marginRight: "0.2rem",
                            }}
                          />{" "}
                          ¿Es desconectable?:
                        </span>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <span
                            style={{
                              color: enerbitColors?.neutral[700],
                              fontWeight: "400",
                              marginLeft: "0.3rem",
                            }}
                          >
                            {service?.cuttable ? "Sí" : "No"}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : serviceStatus.relay_status === "OFF" ? (
                <>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <ButtonConnection
                      bgcolor={determineBackgroundColor({
                        serviceStatus,
                        isLoadingServiceReconnect,
                        backgroundColor: `${enerbitColors.error[200]}`,
                      })}
                      colortext={`${enerbitColors.error.main} !important`}
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{ m: "0.3rem 0.5rem 0 0" }}
                      onClick={() =>
                        dispatch(setOpenConfirmReconnectDialog(true))
                      }
                      disabled={true}
                    >
                      <FlashOnOutlinedIcon
                        sx={{
                          color: `${enerbitColors.error.main}`,
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginRight: "0.3rem",
                        }}
                      />{" "}
                      Desconectado
                    </ButtonConnection>
                    <ButtonConnection
                      bgcolor={determineBackgroundColor({
                        serviceStatus,
                        isLoadingServiceReconnect,
                        backgroundColor: "#E6F9F4",
                      })}
                      colortext={`${enerbitColors?.secondary_green.main} !important`}
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{
                        m: "0.3rem 0 0 0",
                        border: `1px solid ${isDisabledButton({
                          isLoadingServiceStatus,
                          isLoadingServiceReconnect,
                          serviceStatus,
                          serviceErrorObject,
                        })}`,
                      }}
                      onClick={() =>
                        dispatch(setOpenConfirmReconnectDialog(true))
                      }
                      disabled={false}
                    >
                      <FlashOnOutlinedIcon
                        sx={{
                          marginRight: "0.3rem",
                          color: `${enerbitColors?.secondary_green.main} !important`,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      />{" "}
                      Conectar
                    </ButtonConnection>
                  </Box>
                </>
              ) : serviceErrorObject.status === "disconnected" ? (
                <>
                  {Object.entries(serviceErrorObject).length !== 0 &&
                    serviceErrorObject.message !== "" &&
                    !isLoadingServiceStatus && (
                      <WarningIcon
                        style={{ color: enerbitColors.error.main }}
                      />
                    )}
                  <TypographyStatus isred={1}>
                    {serviceErrorObject.message}
                  </TypographyStatus>
                </>
              ) : serviceErrorObject.status === "timeout" ? (
                <>
                  {Object.entries(serviceErrorObject).length !== 0 &&
                    serviceErrorObject.message !== "" &&
                    !isLoadingServiceStatus && (
                      <WarningIcon
                        style={{ color: enerbitColors.error.main }}
                      />
                    )}
                  <TypographyStatus isred={1}>
                    {serviceErrorObject.message}
                  </TypographyStatus>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Box>
      </>
    </ConnectionStatusCard>
  );
};

const TypographyStatus = styled(Typography)(({ isred }: SpanStatusProps) => ({
  fontWeight: 400,
  color: isred ? enerbitColors.error.main : "white",
  display: "inline",
}));

const ConnectionStatusCard = styled(Box)(
  ({ status }: ConnectionStatusCardProps) => ({
    backgroundColor: `#FFFFFF`,
    border: `1px solid ${status}`,
    padding: "12px 16px 12px 12px",
    margin: "20px 0px 20px",
    borderRadius: "10px",
    position: "relative",
  })
);

const ButtonConnection = styled(Button)(
  ({ bgcolor, colortext }: ButtonConnectionProps) => ({
    borderRadius: "6px !important",
    fontWeight: "normal !important",
    backgroundColor: `${bgcolor}`,
    color: `${colortext}`,
    boxShadow: "unset !important",
    width: "100%",
    height: "1.9rem",
    fontSize: "0.875rem",
    //Hover
    "&:hover": {
      backgroundColor: `${bgcolor}`,
    },
    "&:disabled": {
      backgroundColor: `${bgcolor}`,
    },
  })
);

const Circle = styled("div")(({ height, num }: CircleProps) => ({
  alignItems: "center",
  width: `${height}`,
  height: `${height}`,
  display: "flex",
  justifyContent: "center",
  backgroundColor: `rgba(255, 255, 255, 0.${num})`,
  borderRadius: "50%",
}));

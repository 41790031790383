import { BubbleChartOutlinedIcon } from "@enerbit/base";
import { CrisisAlertOutlined } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FlareIcon from "@mui/icons-material/Flare";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import SearchIcon from "@mui/icons-material/Search";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { ICustomSidebar } from "../common/models/ICustomSideBar";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

export const menuItems: ICustomSidebar[] = [
  {
    url: "/#/prisma",
    icon: <DataSaverOffIcon />,
    title: "Prisma",
  },
  {
    url: "/#/search-bitoria",
    icon: <SearchIcon />,
    title: "Bitoria",
  },
  {
    url: "/#/enki",
    icon: <AddCircleOutlineIcon />,
    title: "Enki",
  },
  {
    icon: <QueueMusicIcon />,
    title: "Ópera",
    children: [
      {
        url: "/#/users-companies",
        icon: <ApartmentOutlinedIcon />,
        title: "Usuarios y empresas",
      },
      {
        url: "/#/records",
        icon: <ListOutlinedIcon />,
        title: "Gestión campo",
      },
      {
        url: "/#/orders",
        icon: <ElectricalServicesIcon />,
        title: "Ordenes de servicio",
      },
      {
        url: "/#/documents",
        icon: <PictureAsPdfIcon />,
        title: "Biblioteca de punto de medida",
      },
      {
        url: "/#/viability-diagnostic",
        icon: <ManageSearchIcon />,
        title: "Diagnostico de viabilidad",
      },
    ],
  },
  {
    url: "/#/documentation",
    icon: <FileCopyOutlinedIcon />,
    title: "Documentacíon",
  },
  {
    icon: <RssFeedIcon />,
    title: "Fronteras y CGM",
    children: [
      {
        url: "/#/reading-records",
        icon: <SignalCellularAltIcon />,
        title: "Registros de consumo y reportes a XM",
      },
      {
        url: "/#/fustigations",
        icon: <FlareIcon />,
        title: "Futigaciones",
      },
      {
        url: "/#/solar-fustigations",
        icon: <WbSunnyIcon />,
        title: "Futigaciones de solar",
      },
      {
        url: "/#/admins-directory",
        icon: <AutoStoriesIcon />,
        title: "Directorio de administradores",
      },
      {
        url: "/#/smartbits",
        icon: <TapAndPlayIcon />,
        title: "Panel de smartBits",
      },
      {
        url: "/#/frontiers",
        icon: <BroadcastOnPersonalIcon />,
        title: "Fronteras",
      },
      {
        url: "/#/estimations",
        icon: <FormatListNumberedIcon />,
        title: "Estimaciones",
      },
      {
        url: "/#/measurement-point",
        icon: <DataUsageIcon />,
        title: "Puntos de medida",
      },
    ],
  },
  {
    url: "/#/makita",
    icon: <BubbleChartOutlinedIcon />,
    title: "Makita",
  },
  {
    url: "/#/offers",
    icon: <CrisisAlertOutlined />,
    title: "Ofertador",
  },
  {
    url: "/#/invitations",
    icon: <ChangeCircleOutlinedIcon />,
    title: "Cambio de owner",
  },
  {
    url: "/#/conversion",
    icon: <PublishedWithChangesIcon />,
    title: "Conversión",
  },
];

import { getLeadByMp } from "./assignees";
import { getCertificatesBySerial } from "./certificates";

export const dataCard = async (serial: string = "") => {
    const certificates = await getCertificatesBySerial(serial);
    const entity = await getLeadByMp(serial);
    return {
        certificates,
        entity,
    };
};

import { enerbitColors } from "@enerbit/base";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

export const CardEnerbitServiceAgreementSkeleton = () => {
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: 1,
        backgroundColor: enerbitColors.neutral[100],
        borderRadius: "16px",
        padding: "12px 24px",
        borderColor: "#D8D8D8",
        mt: "20px",
        paddingBottom: "8px",
      }}
    >
      <Typography variant="body1" fontWeight="bold" marginBottom="0.3rem">
        <Skeleton animation="wave" variant="text" width="20%" />
      </Typography>

      <Grid container sx={{ alignItems: "flex-start" }}>
        <Grid item xs={5}>
          <Typography
            variant="body1"
            fontWeight="bold"
            color={enerbitColors.primary.main}
          >
            <Skeleton animation="wave" variant="text" width="20%" />
          </Typography>
          <Typography variant="body1" color={enerbitColors.primary.main}>
            <Skeleton animation="wave" variant="text" width="50%" />
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="body1"
            fontWeight="bold"
            color={enerbitColors.primary.main}
          >
            <Skeleton animation="wave" variant="text" width="40%" />
          </Typography>
          <Box>
            {[...Array(3)].map((_, index) => (
              <Typography
                key={index}
                variant="body1"
                color={enerbitColors.primary.main}
              >
                <Skeleton animation="wave" variant="text" width="40%" />
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={2} md={2}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="65%"
            height="2.5rem"
            sx={{ borderRadius: "1rem" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import { Box, Skeleton, styled, theme } from "@enerbit/base";

export const ConnectionCardSkeleton = () => {
  return (
    <ConnectionStatusCardSkeleton>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Skeleton variant="text" width={150} height={30} />
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            width="48%"
            height={30}
            sx={{ marginRight: "4%" }}
          />
          <Skeleton variant="rectangular" width="48%" height={30} />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            marginTop: "0.8rem",
          }}
        >
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton
            variant="text"
            width={50}
            height={20}
            sx={{ marginLeft: "0.3rem" }}
          />
        </Box>
      </Box>
    </ConnectionStatusCardSkeleton>
  );
};

const ConnectionStatusCardSkeleton = styled(Box)({
  backgroundColor: `#FFFFFF`,
  border: `1px solid ${theme.palette.divider}`,
  padding: "12px 16px 12px 12px",
  margin: "20px 0px 20px",
  borderRadius: "10px",
  position: "relative",
});

export default ConnectionCardSkeleton;

import { create } from "zustand";
import {
    ExcelMeasurements,
    ICreateEstimation,
    IEstimationResults,
    IEstimationsState,
    ILoadZeroEstimation,
    IReasonTypesResponse,
    ISearchEstimationsFilter,
    SearchEstimationsParams,
    SimpleEstimation,
} from "../models";
import { api, moment } from "@enerbit/base";
import {
    createEstimation,
    getEstimationById,
    listEstimationsService,
    uploadZeroEstimationFile,
} from "../services";
import { getReasonTypes } from "../services/reason-types";
import { getMeterModels } from "../services/meters";
import { DATE_INPUT_FORMAT } from "../common";
import { convertToColombianTime } from "../helpers";

// function formatDate(dateString: string) {
//   const date = new Date(dateString);
//   // Formatear manualmente la fecha y hora usando métodos UTC
//   const year = date.getUTCFullYear();
//   const month = String(date.getUTCMonth() + 1).padStart(2, "0");
//   const day = String(date.getUTCDate()).padStart(2, "0");
//   const hours = String(date.getUTCHours()).padStart(2, "0");
//   const minutes = String(date.getUTCMinutes()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}`;
// }

export const useEstimationsState = create<IEstimationsState>()((set, get) => ({
    loading: false,
    error: "",
    reasonTypes: [],
    meterModels: [],
    estimationDetail: null,
    estimations: { items: [], currentPage: 0, totalPages: 0 },
    holes: [],
    estimationResults: [],
    searchFilters: { meter_serial: "" },
    estimationsList: null,
    estimationsListData: { observation: "", reasonId: "" },
    setFilters: (key: keyof ISearchEstimationsFilter, value: any) => {
        const { searchFilters } = get();
        const nD = { ...searchFilters };
        nD[key] = value;
        set({ searchFilters: nD });
    },
    resetFilters: () => {
        set({ searchFilters: { meter_serial: "" } });
    },
    setEstimationsList: (
        estimations: Record<string, Record<string, ExcelMeasurements[]>> | null,
    ) => {
        set({ estimationsList: estimations });
    },
    setEstimationsListData: (data: {
        observation: string;
        reasonId: string;
    }) => {
        set({ estimationsListData: data });
    },
    getReasonTypes: async () => {
        try {
            const res = await api.get<IReasonTypesResponse>(
                "/estimations/reason-types?size=100&page=0",
            );

            set({ reasonTypes: res.data.items });
        } catch (_error) {
            set({ error: "ErrGettingReasonTpes" });
        }
    },
    listEstimations: async ({
        page,
    }: Omit<SearchEstimationsParams, "filters">) => {
        try {
            const {
                meterModels: initialMeterModels,
                reasonTypes: initialReasonTypes,
                searchFilters,
            } = get();
            set({ loading: true });

            const estimationsRes = await listEstimationsService({
                page,
                filters: searchFilters,
            });

            let meterModels = initialMeterModels;
            let reasonTypes = initialReasonTypes;

            if (meterModels.length === 0) {
                meterModels = await getMeterModels();
                set({ meterModels });
            }

            if (reasonTypes.length === 0) {
                reasonTypes = await getReasonTypes();
                set({ reasonTypes });
            }

            const data: SimpleEstimation[] = estimationsRes.items.map(
                (item) => ({
                    id: item.id,
                    estimationDate: moment(item.created_at)
                        .utcOffset(0)
                        .format(DATE_INPUT_FORMAT),
                    estimationRange: `${convertToColombianTime(item.start_at, "YYYY-MM-DD HH:mm")} - ${convertToColombianTime(item.end_at, "YYYY-MM-DD HH:mm")}`,
                    meterModel:
                        meterModels.find((m) => m.code === item.model_code)
                            ?.name ?? "Desconocido",
                    meterSerial: item.meter_serial,
                    reason:
                        reasonTypes.find((r) => r.id === item.reason_type_id)
                            ?.description ?? "Desconocido",
                    startAt: item.start_at,
                    endAt: item.end_at,
                }),
            );

            // Actualizar el estado de estimaciones
            set({
                estimations: {
                    items: data,
                    currentPage: estimationsRes.currentPage,
                    totalPages: estimationsRes.totalPages,
                },
            });
        } catch (_error) {
            set({ error: "ErrListEstimations" });
        } finally {
            set({ loading: false });
        }
    },
    getEstimationById: async (id: string) => {
        try {
            const {
                meterModels: initialMeterModels,
                reasonTypes: initialReasonTypes,
            } = get();
            set({ loading: true });

            const estimation = await getEstimationById(id);

            let meterModels = initialMeterModels;
            let reasonTypes = initialReasonTypes;

            if (meterModels.length === 0) {
                meterModels = await getMeterModels();
                set({ meterModels });
            }

            if (reasonTypes.length === 0) {
                reasonTypes = await getReasonTypes();
                set({ reasonTypes });
            }

            const data: SimpleEstimation = {
                id: estimation.id,
                estimationDate: moment(estimation.created_at).format(
                    DATE_INPUT_FORMAT,
                ),
                estimationRange: `${convertToColombianTime(estimation.start_at, "YYYY-MM-DD HH:mm")} - ${convertToColombianTime(estimation.end_at, "YYYY-MM-DD HH:mm")}`,
                meterModel:
                    meterModels.find((m) => m.code === estimation.model_code)
                        ?.name ?? "Desconocido",
                meterSerial: estimation.meter_serial,
                reason:
                    reasonTypes.find((r) => r.id === estimation.reason_type_id)
                        ?.description ?? "Desconocido",
                startAt: estimation.start_at,
                endAt: estimation.end_at,
            };

            set({ estimationDetail: data });
        } catch (_error) {
            set({ error: "ErrGettingEstimationById" });
        } finally {
            set({ loading: false });
        }
    },
    uploadZeroEstimationFile: async (data: ILoadZeroEstimation) => {
        try {
            const results: IEstimationResults[] = [];
            if (data.file) {
                const holes = await uploadZeroEstimationFile(data.file);

                if (holes.length > 100) return false

                const requests = holes
                    .map(async (h) => {
                        if (!h.end_hole || !h.start_hole) {
                            results.push({
                                meter_serial: h.meter_serial,
                                model_code: h.meter_model,
                                start_at: "",
                                end_at: "",
                                fail_reason: h.observation,
                                id: "",
                            });
                            return;
                        }

                        const body: ICreateEstimation = {
                            end_at: h.end_hole,
                            start_at: h.start_hole,
                            meter_serial: h.meter_serial,
                            model_code: h.meter_model,
                            observations: data.observations,
                            reason_type_id: data.reason_type_id,
                        };

                        const res = await createEstimation(body, "file");

                        results.push(res);
                    })
                    .filter((r) => !!r);

                await Promise.all(requests);
            }

            set({ estimationResults: results });
            return true
        } catch (_error) {
            throw new Error("ErrProcessingFile");
        }
    },
    createManualEstimation: async (data: ILoadZeroEstimation) => {
        try {
            const body: ICreateEstimation = {
                end_at: data.meters.ended_at,
                start_at: data.meters.start_at,
                meter_serial: data.meters.meter_serial,
                model_code: data.meters.meter_model,
                observations: data.observations,
                reason_type_id: data.reason_type_id,
            };

            const res = await createEstimation(body, "manual");

            return res;
        } catch (_error) {
            throw new Error("ErrProcessingFile");
        }
    },
    insertEstimationsList: async (
        _estimations: Record<string, Record<string, ExcelMeasurements[]>>,
    ) => {},
}));

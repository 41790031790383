import { api } from "@enerbit/base";
import type { IBodySwap } from "../interfaces";
import { IOrder } from "../interfaces/order";

export const swapAssignees = async (body: IBodySwap) => {
    await api.post(`/service-orders/swap-assignees`, body);
};

export const getOrderById = async (id: string): Promise<IOrder> => {
    const { data } = await api.get(`service-orders/orders/${id}`);
    return data;
};

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    RemoveRedEyeOutlinedIcon,
    styled,
    Typography,
} from "@enerbit/base";
import { useState } from "react";
import { authenticateFile } from "../../services";

interface Props {
    name: string;
    url: string;
    type?: string;
    needAuth?: boolean;
}

const FileContainer = ({ name, url, type, needAuth }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const goToUrl = async () => {
        if (!needAuth) {
            window.open(url, "_blank", "noopener,noreferrer");
        } else {
            try {
                setError(false);
                setLoading(true);
                const authenticatedUrl = await authenticateFile(url);
                window.open(authenticatedUrl, "_blank", "noopener,noreferrer");
            } catch (_error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Box
            sx={{
                p: "24px",
                borderRadius: "16px",
                backgroundColor: "#f2f4f7",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PdfIcon>{type ?? "PDF"}</PdfIcon>
                <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                    {name}
                </Typography>
            </Box>
            <Button
                onClick={goToUrl}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                }}
            >
                {!loading ? (
                    <RemoveRedEyeOutlinedIcon />
                ) : (
                    <CircularProgress size={22} />
                )}
            </Button>
            {error && <Alert severity="error">Error cargando archivo</Alert>}
        </Box>
    );
};

export default FileContainer;

const PdfIcon = styled("div")`
  width: 45px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f04438;
  border-radius: 4px;
  color: white;
`;

import * as d3 from "d3";
import { DataPoint } from "../components/Tabs/GenerationVariables/GraphicHig3";
import { localeEs } from "../const/Graphic";
import { formatNumber } from "./Usages";

// Tooltip funcional
const tooltip = d3
    .select("body")
    .append("div")
    .attr("class", "tooltip")
    .style("position", "absolute")
    .style("visibility", "hidden")
    .style("background", "#fff")
    .style("border", "1px solid #ddd")
    .style("padding", "10px")
    .style("border-radius", "5px")
    .style("box-shadow", "0px 0px 6px rgba(0,0,0,0.1)");

export type StackedDataPoint = d3.SeriesPoint<DataPoint>;

export const getEjex = (d: DataPoint, type: string) => {
    if (type === "day") {
        return localeEs.format("%A %d")(new Date(d.start));
    } else if (type === "hour") {
        return formatHour(new Date(d.start));
    } else {
        return localeEs.format("%B %Y")(new Date(d.start));
    }
};

export const getTextY = (type: string) => {
    switch (type) {
        case "day":
            return "Día";
        case "hour":
            return "Hora";
        default:
            return "Mes";
    }
};

function formatHour(date: Date) {
    const hours = date.getHours();
    return hours === 0 ? "0" : hours.toString();
}

export const showTooltip = (
    event: MouseEvent,
    d: StackedDataPoint,
    isNegative: boolean,
    type: string,
    unit: string,
) => {
    tooltip.style("visibility", "visible");

    const yValue = d[1];
    const isPositive = yValue >= 0;

    const x = event.pageX - window.innerWidth * 0.056;
    const y = event.pageY - (isPositive ? 110 : -20);

    tooltip
        .classed("tooltip-up", !isPositive)
        .classed("tooltip-down", isPositive);

    const {
        active_energy_selfconsumption,
        conventional_active_energy_imported,
        conventional_active_energy_exported,
    } = d.data;

    const total_generation = isNegative
        ? active_energy_selfconsumption +
          Math.abs(conventional_active_energy_imported)
        : active_energy_selfconsumption + conventional_active_energy_exported;

    let rowDate = "";
    if (type === "day") {
        rowDate = localeEs.format("%Y-%m-%d")(new Date(d.data.start));
    } else if (type === "hour") {
        rowDate = localeEs.format("%Y-%m-%d %H:%Mhrs")(new Date(d.data.start));
    } else {
        rowDate = localeEs.format("%B %Y")(new Date(d.data.start));
    }

    tooltip
        .html(`
            <div style="font-weight: bold; text-align: center;color:#525A6A; font-size: 12px">
                ${rowDate}
            </div>
            <table style="width: 100%; font-size: 14px;color: #525A6A">
                <tr>
                    <td style="display: flex; align-items: center;background: white">
                        <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${isNegative ? "#D9D2E6" : "#FFC390"}; border: 1px solid ${isNegative ? "#8C78B4" : "#FF7705"}; margin-right: 8px;"></div>
                        <span style="font-size: 11px;color: #525A6A"> Autoconsumida: </span>
                    </td>
                    <td style="text-align: right;background: white; color: #525A6A;font-size: 11px;">${formatNumber(active_energy_selfconsumption)} ${unit}</td>
                </tr>
                <tr>
                    <td style="display: flex; align-items: center;background: white">
                        <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${isNegative ? "#B3A5CD" : "#FF7705D9"}; border: 1px solid ${isNegative ? "#53358E" : "#EF730B"}; margin-right: 8px;"></div>
                        <span style="font-size: 11px;color: #525A6A"> ${isNegative ? "Importada" : "Exportada"} : </span>
                    </td>
                    <td style="text-align: right;background: white; color: #525A6A;font-size: 11px;">${formatNumber(Math.abs(isNegative ? conventional_active_energy_imported : conventional_active_energy_exported))} ${unit}</td>
                </tr>
                <tr>
                    <td style="display: flex; align-items: center;background: white">
                        <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${isNegative ? "#53358E" : "#E26A04"}; margin-right: 8px;"></div>
                         <span style="font-size: 11px;color: #525A6A;"> ${isNegative ? "Consumo total:" : "Generación Total:"} </span>
                    </td>
                    <td style="text-align: right;background: white; color: #525A6A;font-size: 11px;">${formatNumber(Math.abs(total_generation))} ${unit}</td>
                </tr>
            </table>
        `)
        .style("opacity", 1)
        .style("left", `${x}px`)
        .style("top", `${y}px`);
};

export const hideTooltip = () => {
    tooltip.style("opacity", 0);
    tooltip.style("visibility", "hidden");
};

export const getColorStroke = (key: string) => {
    switch (key) {
        case "active_energy_selfconsumption":
            return "#FF7705";
        case "conventional_active_energy_exported":
            return "#EF730B";
        case "active_energy_selfconsumption_negative":
            return "#8C78B4";
        case "conventional_active_energy_imported":
            return "#53358E";
        default:
            return "none";
    }
};

import {
    Box,
    Button,
    CircularProgress,
    Typography,
    styled,
} from "@enerbit/base";
import BarChartIcon from "@mui/icons-material/BarChart";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useEffect, useState } from "react";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getRestoreSechedulesStatus } from "../../store/thunks/frontiers";
import { deleteRestoreActiveCommand } from "../../services";
import useMediaQuery from "../../hooks/useMediaQuery";
import StatusData from "./StatusData";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StatusDetailModal from "./StatusDetailModal";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { RestoreSchedulesStatuses } from "../../models/frontiers";

interface Props {
    meterId: string;
}

const TABLET_SCREEN_WIDTH = 1400;

const RestoreSecheduleStatus = ({ meterId }: Props) => {
    const isTablet = useMediaQuery(TABLET_SCREEN_WIDTH);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [deleteError, setDeleteError] = useState<string>();

    const restoreStatus = useSelector(
        (state: RootState) => state.frontiers.restoreScheduleStatus,
    );
    const loadingRestoreStatus = useSelector(
        (state: RootState) => state.frontiers.loadingRestoreStatus,
    );
    const errorRestoreStatus = useSelector(
        (state: RootState) => state.frontiers.errorRestoreStatus,
    );
    const statusColors = useSelector(
        (state: RootState) => state.frontiers.restoreSchedulesStatusColors,
    );

    const dispatch = useDispatch<AppDispatch>();

    const getRestoreStatus = async () => {
        await dispatch(getRestoreSechedulesStatus(meterId));
    };

    const onConfirmDeleteCommand = async () => {
        setLoadingDelete(true);
        setDeleteError(undefined);
        try {
            await deleteRestoreActiveCommand(meterId);
            setOpenModal(false);
            await getRestoreStatus();
        } catch (error: any) {
            if (error.response.status === 404) {
                setDeleteError(
                    "El medidor no tiene un comando de recuperación activo.",
                );
            } else {
                setDeleteError(
                    "Error al eliminar el comando de recuperación activo.",
                );
            }
        } finally {
            setLoadingDelete(false);
        }
    };

    useEffect(() => {
        getRestoreStatus();
    }, []);

    return (
        <>
            <StatusContainer
                bgcolor={statusColors.bgcolor}
                color={statusColors.color}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {loadingRestoreStatus && (
                        <CircularProgress
                            size={"24px"}
                            sx={{ color: statusColors.color }}
                        />
                    )}
                    {!loadingRestoreStatus && <BarChartIcon />}
                    <Typography>{statusColors.message}</Typography>
                    {!loadingRestoreStatus &&
                        !errorRestoreStatus &&
                        restoreStatus.is_active && (
                            <CountdownTimer
                                deadline={
                                    restoreStatus.finish_datetime_restore_schedules
                                }
                                meterId={meterId}
                            />
                        )}
                </Box>
                {/* {isTablet ? (
          <>
            !loadingRestoreStatus && !errorRestoreStatus &&
            <Button
              onClick={() => setOpenDetailModal(true)}
              variant='contained'
              sx={{
                width: "150px",
                backgroundColor: statusColors.color,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                "&:hover": { backgroundColor: statusColors.color },
              }}>
              Ver detalle
              <ArrowForwardIcon />
            </Button>
          </>
        ) : (
          <StatusData setOpenModal={setOpenModal} />
        )} */}
                {!loadingRestoreStatus &&
                    !errorRestoreStatus &&
                    restoreStatus.state !==
                        RestoreSchedulesStatuses.WAITING && (
                        <Button
                            variant="contained"
                            onClick={() => setOpenDetailModal(true)}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 1,
                                alignItems: "center",
                                backgroundColor: statusColors.color,
                                "&:hover": {
                                    backgroundColor: statusColors.color,
                                },
                            }}
                        >
                            Ver detalle
                            <ArrowForwardIcon />
                        </Button>
                    )}
                {!loadingRestoreStatus &&
                    !errorRestoreStatus &&
                    restoreStatus.state ===
                        RestoreSchedulesStatuses.WAITING && (
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => setOpenModal(true)}
                            sx={{
                                height: "32px",
                                borderRadius: "12px",
                                fontSize: "12px",
                                display: "flex",
                                gap: 1,
                                fontWeight: 500,
                            }}
                        >
                            Detener
                            <RemoveCircleIcon sx={{ fontSize: "14px" }} />
                        </Button>
                    )}
            </StatusContainer>
            <ConfirmationModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                onConfirm={() => onConfirmDeleteCommand()}
                loading={loadingDelete}
                setError={setDeleteError}
                errorMessage={deleteError}
                error={!!deleteError}
            />
            <StatusDetailModal
                open={openDetailModal}
                handleClose={() => setOpenDetailModal(false)}
            />
        </>
    );
};

export default RestoreSecheduleStatus;

interface StatusContainerProps {
    color: string;
    bgcolor: string;
}

const StatusContainer = styled(Box)<StatusContainerProps>(
    ({ bgcolor, color }) => ({
        marginRight: "-50px",
        marginLeft: "-50px",
        marginTop: "-40px",
        marginBottom: "22px",
        padding: "12px 12px 12px 12px",
        height: "66px",
        backgroundColor: bgcolor,
        color: color,
        display: "flex",
        justifyContent: "space-between",
    }),
);

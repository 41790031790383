import { QueryKey, UseQueryResult, useQuery } from "@tanstack/react-query";

export const useGet = <TData, TParams = undefined>(
    key: string,
    fn: (params?: TParams) => Promise<TData>,
    params?: TParams,
    enabled: boolean = true,
): UseQueryResult<TData> => {
    const query = useQuery<TData>({
        queryKey: [key, params] as QueryKey,
        queryFn: () => fn(params),
        enabled,
    });

    return query;
};

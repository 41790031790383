import { Box, Button } from "@enerbit/base";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useState } from "react";
import { ICertificate } from "../../interfaces";
import { IEntity } from "../../interfaces/swapper";

interface Props {
    entities: IEntity[];
    certificates: ICertificate[];
    onSwapEntity: () => void;
    onSwapCertificate: (index: number) => void;
    certificates2: ICertificate[];
}

const DashLine = ({
    certificates,
    onSwapEntity,
    onSwapCertificate,
    certificates2,
}: Props) => {
    const [isSwap, setIsSwap] = useState(false);
    const [certificateSwapStates, setCertificateSwapStates] = useState(
        certificates.map((_) => false),
    );

    const dashedStyle = {
        width: "2px",
        height: "100%",
        borderLeft: "2px dashed orange",
        margin: "0 auto",
        position: "relative",
    };

    const onSwapEntityFn = () => {
        onSwapEntity();
        setIsSwap(!isSwap);
    };

    const onSwapCertificateFn = (index: number) => {
        onSwapCertificate(index);
        const newSwapStates = [...certificateSwapStates];
        newSwapStates[index] = !newSwapStates[index];
        setCertificateSwapStates(newSwapStates);
    };

    const currentCertificates =
        certificates.length > certificates2.length
            ? certificates
            : certificates2;

    return (
        <Box sx={{ height: "98%", mt: 2 }}>
            <Box sx={{ height: "550px" }}>
                <Box sx={dashedStyle}>
                    <Box
                        sx={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "orange",
                            borderRadius: "50%",
                            position: "absolute",
                            top: 0,
                            left: "-6px",
                        }}
                    />

                    <Button
                        variant="contained"
                        color={isSwap ? "primary" : "secondary"}
                        sx={{
                            position: "absolute",
                            top: "300px",
                            left: "-30px",
                        }}
                        size="small"
                        onClick={onSwapEntityFn}
                    >
                        <SwapHorizIcon />
                    </Button>
                </Box>
            </Box>
            {currentCertificates.map((_, index) => (
                <Box key={index} sx={{ height: "270px" }}>
                    <Box sx={dashedStyle}>
                        <Button
                            variant="contained"
                            color={
                                certificateSwapStates[index]
                                    ? "primary"
                                    : "secondary"
                            }
                            sx={{
                                position: "absolute",
                                top: "110px",
                                left: "-30px",
                            }}
                            size="small"
                            onClick={() => onSwapCertificateFn(index)}
                        >
                            <SwapHorizIcon />
                        </Button>

                        {index === currentCertificates.length - 1 && (
                            <Box
                                sx={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor: "orange",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    bottom: 0,
                                    left: "-6px",
                                }}
                            />
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default DashLine;

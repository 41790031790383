import { api } from "@enerbit/base";
import type { ICertificate, ICertificateResponse } from "../interfaces";

import { getOrderById } from "./orders";

export const getCertificatesBySerial = async (serial: string = "") => {
    const { data } = await api.get(
        `/service-orders-certificates/certificates?meter_serial=${serial}`,
    );
    const listCode = ["INST", "NORM"];
    const items = data.items;
    const certificates = (await Promise.all(
        items
            ?.filter((certificate: ICertificateResponse) =>
                listCode.includes(certificate.certificate_type.code),
            )
            .map(async (certificate: ICertificateResponse) => {
                const order = await getOrderById(certificate.order_id);
                return {
                    name: certificate.certificate_type.name,
                    section: "service_order",
                    id: certificate.id,
                    dataToMove: {
                        assignee_id: certificate.assignee_id,
                        order_id: certificate.order_id,
                        order_entity_id: order?.order_entities[0].id,
                        certificate_id: certificate.id,
                    },
                    data: [
                        {
                            url: certificate.document_url,
                            order_id: certificate.order_id,
                            id: certificate.id,
                            order: order,
                            date: certificate.created_at,
                            assignee_id: certificate.assignee_id,
                        },
                    ],
                };
            }),
    )) as ICertificate[];
    return certificates;
};

import { Box, Loader } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConnectionCard } from "../../common/Cards/ConnectionCard";
import { getSolarDataService, getSolarTabs } from "../../const/SolarService";
import { useSolarManager } from "../../store/SolarManager";
import { mountedDisassembleAppLeftMenu } from "../../utils/Spa.helper";

export const LeftMenu = () => {
    const { idEss, idSolar } = useParams<string>();
    const { solarService, getSolarService } = useSolarManager();
    const [loadingSolarService, setLoadingService] = useState(false);

    const getSolarManager = async () => {
        setLoadingService(true);
        await getSolarService(idSolar!).finally(() => setLoadingService(false));
    };

    useEffect(() => {
        if (idSolar) getSolarManager();
    }, []);

    useEffect(() => {
        if (idEss && solarService) {
            mountedDisassembleAppLeftMenu({
                id: idEss,
                statusCard: <ConnectionCard />,
                titleText: "Información del servicio de Monitoreo Solar",
                dataServiceInfo: getSolarDataService(solarService, idEss),
                tabs: getSolarTabs(idEss),
            });
        }
    }, [solarService]);

    const renderLoader = (isLoading: boolean, message: string) => {
        return (
            isLoading && (
                <Box sx={{ ml: "3rem", mt: "3rem" }}>
                    <Loader message={message} />
                </Box>
            )
        );
    };

    const loaderService = renderLoader(
        loadingSolarService,
        "Cargando Información básica y técnica",
    );

    return (
        <Box sx={{}}>
            {loaderService}
            {!loadingSolarService && (
                <div id="single-spa-application:app-leftmenu" />
            )}
        </Box>
    );
};

export default LeftMenu;

import { Box, CircularProgress, CustomAlert, Typography } from "@enerbit/base";
import FustigationsTable from "../components/FustigationsTable/FustigationsTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getFustigations } from "../store/thunks/fustigations";
import { useEffect } from "react";
import FustigationTableActions from "../components/FustigationTableActions/FustigationTableActions";

const Fustigations = () => {
    const loading = useSelector(
        (state: RootState) => state.fustigations.loading,
    );
    const since = useSelector((state: RootState) => state.fustigations.since);
    const fustigations = useSelector(
        (state: RootState) => state.fustigations.fustigations,
    );

    const dispatch = useDispatch<AppDispatch>();

    const _getFustigations = () => {
        dispatch(getFustigations({ since }));
    };

    useEffect(() => {
        _getFustigations();
    }, []);

    return (
        <Box>
            <Typography
                mb={2}
                sx={{ fontSize: "28px", fontWeight: 700, color: "#53358E" }}
            >
                Fustigaciones
            </Typography>
            <Box mb={2}>
                <FustigationTableActions />
            </Box>
            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                    <CircularProgress />
                </Box>
            )}
            {!loading ? (
                fustigations.length > 0 ? (
                    <FustigationsTable />
                ) : (
                    <Box my={1}>
                        <CustomAlert
                            severity="info"
                            onClose={() => {}}
                            text="No se encontraron fustigaciones."
                        />
                    </Box>
                )
            ) : null}
        </Box>
    );
};

export default Fustigations;

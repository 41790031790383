import { HashRouter, Route, Routes } from "react-router-dom";

import InconsistenciesData from "../pages/InconsistenciesData";
import Lobby from "../pages/Lobby";
import SwapMeter from "../pages/SwapMeter";

export const Router = () => {
    return (
        <HashRouter basename="/records">
            <Routes>
                <Route path="/" element={<Lobby />} />
                <Route
                    path="/inconsistencies-data/:id"
                    element={<InconsistenciesData />}
                />
                <Route path="/swap-meters/:id" element={<SwapMeter />} />
            </Routes>
        </HashRouter>
    );
};

import {
  Box,
  capitalizeFirstLetter,
  CustomAlert,
  DataGrid,
  esES,
  formatTwoDecimals,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  moment,
  Pagination,
  theme,
  useGridApiContext,
  useGridSelector,
} from "@enerbit/base";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MomentHelpers } from "../../../../../../helpers/moment/MomentHelpers";
import { EnergyConversor } from "../../../../../../models/classes/Energy";
import {
  EnergyReactiveUnit,
  EnergyUnit,
} from "../../../../../../models/classes/EnergyUnit";
import { ConsumptionTableProps } from "../../../../../../models/props/ConsumptionTable";
import { StateStorage } from "../../../../../../models/store/StateStorage";
import { Period } from "../../../../../../models/usages/Period";
import { Usage } from "../../../../../../models/usages/Usage";
import { UsageRow } from "../../../../../../models/usages/UsageRow";

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      shape="rounded"
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(_event: ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
    />
  );
};

const UsageTable = ({ typeConsumption }: ConsumptionTableProps) => {
  const { usages, selectUsagesTable } = useSelector(
    (state: StateStorage) => state.usages
  );
  const [usagesRows, setUsagesRows] = useState<UsageRow[]>([]);

  const primary_color = theme.palette.primary.main;

  const convertToEnergyTitle = (
    period: Period,
    isActive: boolean
  ): EnergyUnit | EnergyReactiveUnit => {
    switch (period) {
      case "Hora":
        return isActive ? "Wh" : "VArh";
      case "Mes":
        return isActive ? "kWh" : "kVArh";
      case "Día":
        return isActive ? "kWh" : "kVArh";
      default:
        return "Wh";
    }
  };

  const getColumns = (shouldShowUsageDays: boolean): GridColDef[] => {
    const columns: GridColDef[] = [
      { field: "id", headerName: "Id", width: 70, hide: true },
      {
        field: "dateType",
        headerName:
          selectUsagesTable == "Mes"
            ? "Mes"
            : selectUsagesTable == "Día"
            ? "Dia"
            : selectUsagesTable == "Hora"
            ? "Hora"
            : "",
        width: 180,
      },

      {
        field: "active_energy_imported",
        headerName: `E. activa importada (${convertToEnergyTitle(
          typeConsumption,
          true
        )})`,
        width: 190,
        type: "number",
      },
      {
        field: "reactive_energy_imported",
        headerName: `E. reactiva importada  (${convertToEnergyTitle(
          typeConsumption,
          false
        )})`,

        width: 200,
      },
      {
        field: "reactive_energy_exported",
        headerName: `E. reactiva exportada (${convertToEnergyTitle(
          typeConsumption,
          false
        )})`,
        width: 200,
      },
      {
        field: "active_energy_exported",
        headerName: `E. activa exportada (${convertToEnergyTitle(
          typeConsumption,
          true
        )})`,
        width: 190,
      },
    ];
    if (shouldShowUsageDays) {
      columns.push({
        field: "consumption_days",
        headerName: "Dias de consumo",
        width: 180,
      });
    }
    return columns;
  };

  const [columns, setColumns] = useState<GridColDef[]>(
    getColumns(selectUsagesTable === "Mes")
  );

  useEffect(() => {
    setColumns(getColumns(selectUsagesTable === "Mes"));
  }, [selectUsagesTable]);

  //Para setear los usages en el estado de forma dinámica
  const setUsagesRowsData = () => {
    const rows: UsageRow[] = [];
    usages?.map(async (usage: Usage) => {
      return rows.push({
        id: usage?.start,
        dateType:
          typeConsumption === "Mes"
            ? capitalizeFirstLetter(
                moment(usage?.start).format(MomentHelpers.YYYYMM)
              )
            : typeConsumption === "Día"
            ? capitalizeFirstLetter(
                moment(usage?.start).format(MomentHelpers.YYYYMMDD)
              )
            : capitalizeFirstLetter(
                moment(usage?.start).format(MomentHelpers.YYYYMMDDHHmm)
              ),
        start: moment(usage?.start).format(MomentHelpers.YYYYMMDD),
        end: moment(usage?.end).format(MomentHelpers.YYYYMMDD),
        active_energy_imported:
          usage.active_energy_imported || usage.active_energy_imported == 0
            ? formatTwoDecimals.format(
                EnergyConversor.convertToEnergy({
                  value: usage.active_energy_imported,
                  fixedUnit: convertToEnergyTitle(typeConsumption, true),
                }).value
              )
            : "Sin consumos",
        reactive_energy_imported:
          usage.reactive_energy_imported || usage.reactive_energy_imported == 0
            ? formatTwoDecimals.format(
                EnergyConversor.convertToEnergy({
                  value: usage.reactive_energy_imported,
                  fixedUnit: convertToEnergyTitle(typeConsumption, false),
                }).value
              )
            : "Sin consumos",
        active_energy_exported:
          usage.active_energy_exported || usage.active_energy_exported == 0
            ? formatTwoDecimals.format(
                EnergyConversor.convertToEnergy({
                  value: usage.active_energy_exported,
                  fixedUnit: convertToEnergyTitle(typeConsumption, true),
                }).value
              )
            : "Sin consumos",
        reactive_energy_exported:
          usage.reactive_energy_exported || usage.reactive_energy_exported == 0
            ? formatTwoDecimals.format(
                EnergyConversor.convertToEnergy({
                  value: usage.reactive_energy_exported,
                  fixedUnit: convertToEnergyTitle(typeConsumption, false),
                }).value
              )
            : "Sin consumos",

        consumption_days: moment(usage.end).diff(moment(usage.start), "days"),
      });
    });
    setUsagesRows(rows);
  };

  useEffect(() => {
    setUsagesRowsData();
  }, [usages]);

  if (usagesRows.length === 0)
    return (
      <CustomAlert
        severity="info"
        text="No hay datos para mostrar"
        onClose={() => null}
        sx={{ width: "100%", my: "20px" }}
      />
    );

  return (
    <Box sx={{ width: "100%", my: "20px" }}>
      <DataGrid
        rows={usagesRows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        onSelectionModelChange={(itm: any) => {}}
        autoHeight={true}
        onCellClick={(itm: any) => {}}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pagination
        components={{
          Pagination: CustomPagination,
          Toolbar: GridToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "dateType", sort: "desc" }],
          },
        }}
        sx={{
          borderRadius: "20px",
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            "&:hover": {
              backgroundColor: primary_color,
              color: "white",
            },
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: primary_color,
            color: "white",
            "&:hover": {
              backgroundColor: primary_color,
              color: "white",
            },
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            backgroundColor: "#F2F4F7",
          },
          "& .MuiDataGrid-footerContainer": {
            justifyContent: "center",
          },
        }}
      />
    </Box>
  );
};

export default UsageTable;

import { Box } from "@enerbit/base";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
    return (
        <Box className="Container-root" sx={{ overflowY: "scroll" }}>
            <Box sx={{ p: "60px", pb: "20px" }}>{children}</Box>
        </Box>
    );
};

export default MainLayout;

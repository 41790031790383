import { Box, CircularProgress, CustomAlert, Grid } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/index.css";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import DetailTabs from "../../components/DetailTabs/DetailTabs";
import MeterDetailActions from "../../components/MeterDetailActions/MeterDetailActions";
import RefustigateModal from "../../components/RefustigateModal/RefustigateModal";
import RestoreSchedulesModal from "../../components/RestoreSchedulesModal/RestoreSchedulesModal";
import RestoreSecheduleStatus from "../../components/RestoreSecheduleStatus/RestoreSecheduleStatus";
import SmartbitsFlow from "../../components/SmartbitsFlow/SmartbitsFlow";
import LineChart from "../../components/UsagesChart/UsagesChart";
import { MeterDetail } from "../../models/frontiers";
import { getMetersDetail } from "../../services";

const ReadingRecordsDetail = () => {
    const location = useLocation();
    const { serial } = useParams();

    const [param, setParam] = useState<string>("");
    const [meterDetail, setMeterDetail] = useState<MeterDetail>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [refustigateModal, setRefustigateModal] = useState<boolean>(false);
    const [restoreDataModal, setRestoreDataModal] = useState<boolean>(false);

    const getDetail = async () => {
        if (!serial) return;

        setParam(serial);
        setLoading(true);
        setError("");

        try {
            const detail = await getMetersDetail(serial);
            setMeterDetail(detail);
        } catch (error: any) {
            if (error.response.status === 404) {
                setError("No se ha encontrado información sobre este medidor");
            } else {
                setError("Error al consultar el detalle de este medidor");
            }
        } finally {
            setLoading(false);
        }
    };

    const openRefustigateModal = () => {
        setRefustigateModal(true);
    };
    const openRestoreDateModal = () => {
        setRestoreDataModal(true);
    };

    useEffect(() => {
        getDetail();
    }, [location.pathname]);

    return (
        <>
            {!error && !loading && meterDetail && (
                <RestoreSecheduleStatus meterId={meterDetail.id} />
            )}
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <DetailHeader meterSerial={param} />
                </Box>
                {!error && !loading && meterDetail && (
                    <MeterDetailActions
                        openRefustigateModal={openRefustigateModal}
                        openRestoreDataModal={openRestoreDateModal}
                        meterId={meterDetail.id}
                    />
                )}
            </Box>
            {!loading && meterDetail && !error && (
                <>
                    <Grid mt={3} container columnSpacing={2}>
                        <Grid item xs={6}>
                            <SmartbitsFlow
                                meterId={meterDetail.id}
                                meterSerial={meterDetail.serial}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LineChart meterSerial={meterDetail.serial} />
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <DetailTabs
                            meterSerial={meterDetail.serial}
                            meterId={meterDetail.id}
                        />
                    </Box>
                </>
            )}
            {error && (
                <Box my={4}>
                    <CustomAlert
                        severity="error"
                        text={error}
                        onClose={() => setError("")}
                    />
                </Box>
            )}
            {loading && (
                <Box
                    my={4}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <RefustigateModal
                open={refustigateModal}
                handleClose={() => setRefustigateModal(false)}
                meterSerial={serial ?? ""}
                isBulk={false}
            />
            {meterDetail && (
                <RestoreSchedulesModal
                    meterId={meterDetail.id}
                    handleClose={() => setRestoreDataModal(false)}
                    open={restoreDataModal}
                />
            )}
        </>
    );
};

export default ReadingRecordsDetail;

import {
	Box,
	CardPdf,
	CustomAlert,
	DragZone,
	LinearProgress,
	LoadingButton,
	Typography,
	//@ts-ignore
} from "@enerbit/base";
import React, { useCallback, useState, useEffect } from "react";

import { onSelectDocument } from "../../../../services/uploadFile";

import bombi from "../../../../assets/cargando-enerbit.gif";
import { sendForm } from "../../../../helpers/sendForm";
import { InputInterface } from "../../../../models/FormSchema";

import {
	IExcelDataArray,
	IFormDataArray,
	IfilePath,
	IfilePathArray,
} from "./models/schemas";

import moment from "moment";
import XLSX from "xlsx";
import { read, utils } from "xlsx";
import { processFiles } from "../../../../helpers/processFile";
import { FileBlobType, MySeverityType } from "../../../../models/base";

interface IoperatorId {
	operatorId: string;
}

const MassiveMeters = ({ operatorId }: IoperatorId) => {
	const [fileInfo, setFileInfo] = useState<Blob>();
	const [filePath, setFilePath] = useState<any>([]);
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const [progress, setProgress] = useState(0);
	const [totalProgress, setTotalProgress] = useState(0);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isSuccess2, setIsSuccess2] = useState(false);
	const [severityAlert, setSeverityAlert] = useState<MySeverityType>("");
	const [textAlert, setTextAlert] = useState("");
	const [error, setError] = useState(false);
	const [excel, setExcel] = useState<IExcelDataArray | XLSX.WorkSheet>();
	const [formData, setFormData] = useState<IFormDataArray | any>([]);
	const [isLoadingForm, setIsLoadingForm] = useState(false);
	const [msgInformation, setMsgInformation] = useState("");

	const ISSUE_DATE = "Fecha de emisión certificado de calibración";
	const CALIBRATION_DATE = "Fecha de calibración";
	const SERIE = "Serie";
	const ISSUER_ENTITY = "Ente Emisor certificado de calibración";
	const CALIBRATION_IMP_ACT = "Certificado calibración importación activa";
	const CALIBRATION_EXP_ACT = "Certificado calibración exportación activa";
	const CALIBRATION_IMP_REACT = "Certificado calibración importación reactiva";
	const CALIBRATION_EXP_REACT = "Certificado calibración exportación reactiva";
	const CONFORMED_CERTIFICATE = "Número  del certificado de conformidad";

	const readUploadFile = (files: Array<Blob> | any) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const data = event?.target?.result;
			const wb = read(data, { type: "array" });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const json = utils.sheet_to_json(ws);

			setExcel(json);
		};
		reader.readAsArrayBuffer(files[0]);
		setFileInfo(files[0]);
		setIsSuccess(true);
	};

	type validateDates = {
		status: boolean;
		serie?: string;
	};

	const validateDates = (): validateDates | any => {
		let hasError = false;
		let serieError = "";
		excel?.some((field: any) => {
			const excelDate = excelDateToJSDate(field[CALIBRATION_DATE]);
			const issueDate = excelDateToJSDate(field[ISSUE_DATE]);

			if (typeof excelDate !== "string" || typeof issueDate !== "string") {
				hasError = true;
				serieError = field[SERIE];
				return true;
			}
			return false;
		});
		if (hasError) {
			return {
				status: false,
				serie: serieError,
			};
		} else {
			return { status: true };
		}
	};

	const calculateTotalProgress = (
		currentProgress: number,
		totalItems: number,
	) => {
		return (currentProgress / totalItems) * 100;
	};

	const totalItems = excel?.length || 0;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const onDrop = useCallback((e: Blob[]) => readUploadFile(e), []);

	const EXCEL_DATE_BASE = new Date(1900, 0, 1);

	const daysToSubtract = 1;

	function excelDateToJSDate(excelDate: number) {
		const days = excelDate - daysToSubtract;
		const date = new Date(
			EXCEL_DATE_BASE.getTime() + days * 24 * 60 * 60 * 1000,
		);
		return moment(date).format("YYYY-MM-DD");
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const onDropPdf = useCallback(
		async (files: InputInterface) => {
			try {
				setIsLoadingFile(true);

				const requiredFiles = [] as string[];
				const { status } = validateDates();

				if (!status) {
					setTextAlert(
						`Las fechas en el serial ${validateDates().serie} no tienen el formato esperado ("YYYY-MM-DD")`,
					);
					setSeverityAlert("error");
					throw new Error();
				} else {
					let currentProgress = 0;
					excel?.forEach((field: any) => {
						requiredFiles.push(
							field[CALIBRATION_IMP_ACT],
							field[CALIBRATION_EXP_ACT],
							field[CALIBRATION_IMP_REACT],
							field[CALIBRATION_EXP_REACT],
						);
					});

					const filteredRequiredFiles = requiredFiles.filter(
						(file: string) => file !== undefined,
					);

					const missingFiles = [] as string[];
					const updatedFilePath = [...filePath];

					for (const requiredFile of filteredRequiredFiles) {
						const existingFile = files.find(
							(f: any) =>
								f.name.split(".")[0].split(" ").join("") ===
								requiredFile.split(" ").join(""),
						);

						if (!existingFile) {
							if (!missingFiles.includes(requiredFile)) {
								missingFiles.push(requiredFile);
							}
						} else {
							const existingPath = updatedFilePath.find(
								(f: IfilePath) => f[requiredFile] !== undefined,
							);

							if (!existingPath) {
								const fileLink = await uploadFile(existingFile);
								currentProgress++;
								setProgress(currentProgress);
								setTotalProgress(
									calculateTotalProgress(currentProgress, totalItems),
								);
								if (fileLink) {
									updatedFilePath.push({ [requiredFile]: fileLink });
								}
							} else {
								updatedFilePath.push({
									[requiredFile]: existingPath[requiredFile],
								});
							}
						}
					}

					if (missingFiles.length > 0) {
						setTextAlert(`Faltan los siguientes archivos: ${missingFiles}`);
						setSeverityAlert("error");
						setIsLoadingFile(false);
						return;
					}

					setFilePath(updatedFilePath);
					setIsLoadingFile(false);
				}
			} catch (error) {
				setIsLoadingFile(false);
				return error;
			} finally {
				setIsLoadingFile(false);
			}
		},
		[excel, filePath],
	);

	const mapObject = (): void | unknown => {
		const newArray: any = [];
		try {
			excel?.map((field: XLSX.WorkSheet) => {
				const pathActivityImportCertification =
					field[CALIBRATION_IMP_ACT] || null;

				const pathActivityExportCertification =
					field[CALIBRATION_EXP_ACT] || null;
				const pathReactiveImportCertification =
					field[CALIBRATION_IMP_REACT] || null;
				const pathReactiveExportCertification =
					field[CALIBRATION_EXP_REACT] || null;

				const object = {
					issue_date: excelDateToJSDate(field[ISSUE_DATE]),
					calibration_date: excelDateToJSDate(field[CALIBRATION_DATE]),

					issuer_entity: field[ISSUER_ENTITY],
				};
				newArray.push({
					form_data: {
						meter_serial: field.Serie.toString(),
						is_grouped: false,
						active_import: pathActivityImportCertification
							? {
									...object,
									certificate_number: field[CONFORMED_CERTIFICATE]?.toString(),
									certificate_path: filePath
										? filePath?.find(
												(f: string) => f[pathActivityImportCertification],
											)[pathActivityImportCertification]
										: null,
								}
							: null,
						active_export: pathActivityExportCertification
							? {
									...object,
									certificate_number: field[CONFORMED_CERTIFICATE]?.toString(),
									certificate_path: filePath
										? filePath.find(
												(f: string) => f[pathActivityExportCertification],
											)[pathActivityExportCertification]
										: null,
								}
							: null,
						reactive_import: pathReactiveImportCertification
							? {
									...object,
									certificate_number: field[CONFORMED_CERTIFICATE]?.toString(),
									certificate_path: filePath
										? filePath.find(
												(f: string) => f[pathReactiveImportCertification],
											)[pathReactiveImportCertification]
										: null,
								}
							: null,
						reactive_export: pathReactiveExportCertification
							? {
									...object,
									certificate_number: field[CONFORMED_CERTIFICATE]?.toString(),
									certificate_path: filePath
										? filePath.find(
												(f: string) => f[pathReactiveExportCertification],
											)[pathReactiveExportCertification]
										: null,
								}
							: null,
					},
					created_by: operatorId,
				});
				setFormData(newArray);
			});
		} catch (error: unknown) {
			// setError(true);
			setSeverityAlert("error");

			setTextAlert("Ha ocurrido un error inesperado");

			return error;
		}
	};

	const onClearFile = (): void => {
		setIsSuccess(false);
		setExcel([]);
		setFormData([]);
		setFilePath("");
		setProgress(0);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		mapObject();
	}, [filePath]);

	const closeAlert = () => {
		setTextAlert("");
		setSeverityAlert("");
	};

	const handleSubmit = async (): Promise<void> => {
		setIsLoadingForm(true);
		try {
			await sendForm(
				"/inventory-documentation/meter-calibrations-certificate-massive",
				formData,
			);
			setSeverityAlert("success");
			setExcel([]);
			setFormData([]);
			setTextAlert("Certificados creados correctamente");
		} catch (error) {
			setSeverityAlert("error");
			setTextAlert("Ha ocurrido un error al generar los certificados");
			setFilePath([]);
		} finally {
			setIsLoadingForm(false);
		}
	};

	const uploadFile = async (
		file: Blob | string | any,
	): Promise<string | undefined> => {
		setError(false);
		try {
			const res = await onSelectDocument(file, setProgress, setIsSuccess2);
			return res;
		} catch (err) {
			setSeverityAlert("error");
			setTextAlert("No se pudo generar los medidores");
			setIsSuccess2(false);
			setError(true);
		}
	};

	return (
		<>
			<Typography
				color="primary"
				sx={{
					fontWeight: "500",
					my: "10px",
				}}
				variant="h5"
			>
				Creación masiva de calibración de medidores
			</Typography>

			<Typography
				color="primary"
				sx={{
					fontWeight: "500",
					my: 3,
				}}
				variant="h6"
			>
				Adjunta archivo excel
			</Typography>

			{excel?.length > 0 ? (
				<CardPdf
					typeFile="xlsx"
					fileInfo={fileInfo as FileBlobType}
					progress={progress}
					isSuccess={isSuccess}
					onClearFile={onClearFile}
					isClearFile
				/>
			) : (
				<>
					<DragZone onDrop={onDrop as FileBlobType} typeFile="xlsx" />
				</>
			)}

			<Typography
				color="primary"
				sx={{
					fontWeight: "500",
					my: 3,
				}}
				variant="h6"
			>
				Adjunta los archivos pdfs
			</Typography>

			{isLoadingFile || formData.length > 0 ? (
				<CardPdf
					// fileInfo={fileInfo}
					progress={progress}
					isSuccess={isSuccess2}
					onClearFile={onClearFile}
				/>
			) : (
				<DragZone onDrop={onDropPdf as FileBlobType} />
			)}

			{isLoadingFile && (
				<LinearProgress variant="determinate" value={totalProgress} />
			)}

			{msgInformation}

			<LoadingButton
				variant="contained"
				fullWidth
				disabled={isLoadingFile || formData.length === 0}
				sx={{ my: 4 }}
				loading={isLoadingForm}
				onClick={handleSubmit}
			>
				Enviar
			</LoadingButton>

			{severityAlert && !isLoadingFile && (
				<Box sx={{ my: 1 }}>
					<CustomAlert
						severity={severityAlert}
						text={textAlert}
						onClose={closeAlert}
					/>
				</Box>
			)}
		</>
	);
};
export default MassiveMeters;

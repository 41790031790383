import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  enerbitColors,
} from "@enerbit/base";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../../../models/store/StateStorage";
import {
  getInvoices,
  getTotalOwed,
} from "../../../../../../store/actions/billing/billing.actions";
import { setYearFilter } from "../../../../../../store/slices/billingSlice";
import { AppDispatch } from "../../../../../../store/store";

const getYearsList = (startYear: number = 2020): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  return years;
};

export const FiltersInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { essId, yearFilter } = useSelector(
    (state: StateStorage) => state.billing
  );
  const [monthFilter, setMonthFilter] = useState<string>("");

  const yearsList = getYearsList();

  const getMonthsList = () => {
    return [
      { key: "01", value: "Enero" },
      { key: "02", value: "Febrero" },
      { key: "03", value: "Marzo" },
      { key: "04", value: "Abril" },
      { key: "05", value: "Mayo" },
      { key: "06", value: "Junio" },
      { key: "07", value: "Julio" },
      { key: "08", value: "Agosto" },
      { key: "09", value: "Septiembre" },
      { key: "10", value: "Octubre" },
      { key: "11", value: "Noviembre" },
      { key: "12", value: "Diciembre" },
    ];
  };

  const months = getMonthsList();

  return (
    <Grid container sx={{ alignItems: "end" }}>
      <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
        <Box sx={{ mb: "10px" }}>Buscar por año</Box>
        <Select
          inputProps={{ "data-testid": "searchYear" }}
          sx={{ borderRadius: "14px" }}
          fullWidth
          value={yearFilter}
          onChange={(event: SelectChangeEvent<string>) =>
            dispatch(setYearFilter(event.target.value))
          }
        >
          {yearsList.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
        <Box sx={{ mb: "10px" }}>Buscar por mes</Box>
        <Select
          inputProps={{ "data-testid": "searchMonth" }}
          sx={{ borderRadius: "14px" }}
          fullWidth
          value={monthFilter}
          onChange={(event: SelectChangeEvent<string>) =>
            setMonthFilter(event.target.value)
          }
        >
          {months.map((month) => (
            <MenuItem key={month.key} value={month.key}>
              {month.value}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid
        item
        xs={12}
        md={2}
        lg={2}
        className="Grid-buttons-invoice"
        sx={{ textAlign: "center" }}
      >
        <Button
          onClick={() => {
            dispatch(
              getInvoices({
                ess_id: essId,
                page: 1,
                month_filter:
                  monthFilter != ""
                    ? yearFilter + "-" + monthFilter
                    : yearFilter,
              })
            );
            dispatch(getTotalOwed({ ess_id: essId, month_filter: "" }));
          }}
          sx={{
            color: enerbitColors.primary.main,
            alignItems: "center",
          }}
        >
          Aplicar filtro
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        md={2}
        lg={2}
        className="Grid-buttons-invoice"
        sx={{ textAlign: "center" }}
      >
        <Button
          onClick={() => {
            dispatch(setYearFilter(""));
            setMonthFilter("");
            dispatch(
              getInvoices({
                ess_id: essId,
                page: 1,
                month_filter: "",
              })
            );
            dispatch(getTotalOwed({ ess_id: essId, month_filter: "" }));
          }}
          sx={{
            color: enerbitColors.primary.main,
            alignItems: "center",
          }}
        >
          Limpiar filtro
        </Button>
      </Grid>

      {/* <Grid
        item
        xs={12}
        md={2}
        lg={2}
        className="Grid-buttons-invoice"
        sx={{ textAlign: "center" }}
      >
        <Button
          sx={{
            color: enerbitColors.primary.main,
            alignItems: "center",
          }}
        >
          <DownloadOutlinedIcon />
          Exportar CSV
        </Button>
      </Grid> */}
    </Grid>
  );
};

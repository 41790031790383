import {
    Alert,
    ArrowBackIosIcon,
    Box,
    Typography,
    capitalizeFirstLetter,
    enerbitColors,
} from "@enerbit/base";
import ErrorIcon from "@mui/icons-material/Error";

import { useNavigate, useParams } from "react-router-dom";
import CardMeter from "../components/common/CardMeter";
import { useInconsistenciesStore } from "../store/inconsistencies.store";

const InconsistenciesData = () => {
    const { id } = useParams();
    const { rows } = useInconsistenciesStore();
    const navigate = useNavigate();

    const currentData = rows?.rows?.filter((row) => row.id === id)[0];

    const clientData = currentData.data.data.assignee.lead[0];

    const certificateList = clientData.certificate.map((certificate) => ({
        section: "",
        id: "1",
        dataToMove: {
            assignee_id: "",
            order_id: "",
        },
        name: certificate.certificate_type.name,
        data: [
            {
                url: certificate.document_url,
                order_id: certificate.order_id,
                id: certificate.id,
            },
        ],
    }));

    const entity = [
        {
            entityName: "Cliente/Lead",
            id: "2",
            section: "",
            dataToMove: {
                lead_id: "",
                mp: "",
            },
            data: [
                {
                    text: "Nombre del cliente",
                    value: clientData.display_name || "No registra",
                },
                {
                    text: "Correo electrónico",
                    value: clientData.email_from || "No registra",
                },
                {
                    text: "Dirección",
                    value: clientData.address || "No registra",
                },
            ],
        },
        {
            id: "3",
            section: "",
            entityName: "Punto de medida",
            dataToMove: {
                lead_id: "",
                mp: "",
            },
            data: [
                {
                    text: "ID",
                    value: clientData.mp,
                },
            ],
        },
    ];

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <ArrowBackIosIcon
                    sx={{ mr: 1, cursor: "pointer" }}
                    onClick={() => navigate("/")}
                />
                <Typography color="primary" variant="h5" fontWeight="bold">
                    Inconsistencias de datos
                </Typography>
            </Box>

            <Alert
                icon={<ErrorIcon sx={{ fontSize: "30px" }} />}
                severity="warning"
                sx={{
                    border: `1px solid ${enerbitColors.secondary.main}`,
                    borderRadius: "10px",
                    alignItems: "center",
                }}
            >
                <Typography color="warning.main" fontWeight="bold" variant="h6">
                    Valida la información
                </Typography>

                {currentData.data.error.map((err, index) => (
                    <Typography key={index}>
                        •{" "}
                        {err.message.includes("meter not found")
                            ? "Medidor no encontrado"
                            : capitalizeFirstLetter(err.message)}
                    </Typography>
                ))}
            </Alert>

            <CardMeter
                meterSerial={currentData.serial_certificate}
                subTitle="Información"
                insideCardBorderColor={enerbitColors.warning.main}
                entityData={entity}
                certificateData={certificateList}
                type="inconsistency"
            />
        </>
    );
};

export default InconsistenciesData;

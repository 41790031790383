export interface FrontiersState {
    frontiers: MissingReadingRecords[];
    isLoading: boolean;
    totalPages: number;
    currentPage: number;
    error: boolean;
    days: string;
    selectedFrts: MissingReadingRecords[];
    includeFrt: boolean;
    frtMessage: string;
    searchValue: string;
    loadingRestoreStatus: boolean;
    errorRestoreStatus: boolean;
    restoreScheduleStatus: GetRestoreSchedulesStatusModel;
    restoreSchedulesStatusColors: RestoreSchedulesStatusModel;
}

export interface MissingReadingRecords {
    frt_code: string;
    meter_serial: string;
    count_records: number;
    max_date: string;
    max_created_at: string;
}

export interface ServicesEstaus {
    name: string;
    description: string;
    id: string;
}

export interface MeterEventConsult {
    meterSerial: string;
}

export interface ChartData {
    [key: string]: string;
}

export enum EnergyTypes {
    reactive_energy_exported = "reactive_energy_exported",
    reactive_energy_imported = "reactive_energy_imported",
    active_energy_exported = "active_energy_exported",
    active_energy_imported = "active_energy_imported",
}

export interface MeterDetail {
    serial: string;
    state_id: string;
    model_id: string;
    group_id: string;
    id: string;
    state: State;
    created_at: string;
    instalation_date: string;
    retirement_date: null;
    validated: boolean;
}

export interface MeterRelationshipResponse {
    items: MeterRelationship[];
    page: number;
    size: number;
    total: number;
    pages: number;
    next_page: null | number;
    previous_page: null | number;
}

export interface MeterRelationship {
    started_at: string;
    id: string;
    meter_id: string;
    device_meter: DeviceMeter;
    created_at: string;
    updated_at: string;
    ended_at: string;
}

export interface DeviceMeter {
    id: string;
    group_id: null;
    imei: string;
    mac_address: string;
    country_name: string;
    state_name: string;
    locality_name: string;
    organization_name: string;
    latitude: string;
    longitude: string;
    created_at: string;
    is_active: boolean;
    name: string;
}

export interface State {
    name: string;
    id: string;
}

export interface GetRecordsModel {
    days: string;
    includeFrt: boolean;
}

export interface SearchModel {
    days: string;
    value: string;
    filter: SearchFilters;
}

export interface RequestDatesModel {
    since: string;
    until: string;
}

export interface MeasuresModel {
    active_energy_exported: number;
    active_energy_imported: number;
    time_end: string;
    meter_id: string;
    reactive_energy_exported: number;
    reactive_energy_imported: number;
    time_start: string;
    is_estimated: boolean;
}

export enum SearchFilters {
    FRT_CODE = "frt_code",
    METER_SERIAL = "meter_serial",
}
export interface SearchFiltersModel {
    name: string;
    value: SearchFilters;
}

export enum RestoreSchedulesTypes {
    DEVICE = "device",
    METER = "meter",
}

export interface RestoreSchedulesModel {
    name: string;
    value: RestoreSchedulesTypes;
}

export interface DownloadFileModel {
    data: string;
    fileName: string;
    fileType: string;
}

export interface MeterStatusModel {
    relay_status: string;
    meter_serial: string;
}

export interface UsagesChartDataModel {
    label: string;
    data: string;
    borderColor: string;
    backgroundColor: string;
}

export interface RestoreSchedulesStatusModel {
    bgcolor: string;
    color: string;
    message: string;
}

export interface GetRestoreSchedulesStatusModel {
    total_periods_found: number;
    is_active: boolean;
    created_at: string;
    start_datetime_restore_schedules: string;
    end_datetime_restore_schedules: string;
    state: string;
    finish_datetime_restore_schedules: string;
    total_periods_requested: number;
    user: string;
}

export enum RestoreSchedulesStatuses {
    FAILED = "Failed",
    WAITING = "waiting",
    SUCCESS = "Success",
}

export enum MissingHoursStatus {
    MISSING = "MISSING",
    EXISTS = "EXISTS",
    ESTIMATED = "ESTIMATED",
    OUT_OF_TIME = "OUT_OF_TIME",
}

export enum RelayStatuses {
    ON = "ON",
    OFF = "OFF",
    ERROR = "ERROR",
}

export interface AssociatedMeters {
    meter_id: string;
    meter_serial: string;
    meter_model: string;
    validated: boolean;
    instalation_date: string;
}

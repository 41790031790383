import { Slice, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    FailedFustigations,
    FustigationsResponse,
    FustigationsState,
    SearchFilters,
} from "../../models/fustigations";
import { getFustigations } from "../thunks/fustigations";
import moment from "moment";

const initialState: FustigationsState = {
    fustigations: [],
    currentPage: 1,
    totalPages: 0,
    error: false,
    loading: false,
    selectedFustigations: [],
    searchValue: "",
    since: moment()
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZZ"),
    filter: SearchFilters.METER_SERIAL,
};

export const fustigationsSlice: Slice<FustigationsState> = createSlice({
    name: "fustigations",
    initialState,
    reducers: {
        setCurrentPage(state, { payload }: PayloadAction<number>) {
            state.currentPage = payload;
        },
        updateSelectedFustigations(
            state,
            { payload }: PayloadAction<FailedFustigations[]>,
        ) {
            state.selectedFustigations = payload;
        },
        appendFustigation(state, { payload }) {
            state.selectedFustigations.push(payload);
        },
        setSearchValue(state, { payload }: PayloadAction<string>) {
            state.searchValue = payload;
        },
        setSince(state, { payload }: PayloadAction<string>) {
            state.since = payload;
        },
        setFilter(state, { payload }: PayloadAction<SearchFilters>) {
            state.filter = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFustigations.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getFustigations.fulfilled,
            (state, { payload }: PayloadAction<FustigationsResponse>) => {
                state.loading = false;
                state.error = false;
                state.fustigations = payload.items;
                state.totalPages = payload.total_pages;
            },
        );
        builder.addCase(getFustigations.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export const {
    setCurrentPage,
    updateSelectedFustigations,
    appendFustigation,
    setSearchValue,
    setSince,
    setFilter,
} = fustigationsSlice.actions;

import { Box, CircularProgress, CustomAlert, Typography } from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { ReactFlow, Controls, ReactFlowProvider, Node } from "reactflow";
import "reactflow/dist/style.css";
import "./SmartbitsFlow.css";
import { customNodes } from "../../const/nodeTypes";
import { formatRelationshipTree } from "../../helpers";
import { MeterRelationship } from "../../models/frontiers";
import {
    getAssociatedMeters,
    getMeterRelationshipDevice,
} from "../../services";

interface Props {
    meterId: string;
    meterSerial: string;
}

const SmartbitsFlow = ({ meterId, meterSerial }: Props) => {
    const reactFlowWrapper = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [nodes, setNodes] = useState<any[]>([]);
    const [edges, setEdges] = useState<any[]>([]);

    const _getMeterRelations = async () => {
        setLoading(true);

        try {
            const deviceId = await getMeterRelationshipDevice(meterId);
            const associatedMeters = await getAssociatedMeters(deviceId);

            const data = formatRelationshipTree(
                deviceId,
                associatedMeters,
                meterSerial,
            );
            setNodes(data.nodes);
            setEdges(data.edges);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        _getMeterRelations();
    }, []);

    return (
        <>
            {!loading && (
                <ReactFlowProvider>
                    <Box
                        sx={{ width: "100%", height: "480px", padding: "24px" }}
                    >
                        <Typography
                            mb={1}
                            color={"primary"}
                            sx={{ fontWeight: 700, fontSize: "18px" }}
                        >
                            Ramificación de los medidores asociados
                        </Typography>
                        {!error && (
                            <Box
                                className="reactflow-wrapper"
                                ref={reactFlowWrapper}
                            >
                                <ReactFlow
                                    nodes={nodes}
                                    edges={edges}
                                    nodeTypes={customNodes}
                                    fitView
                                >
                                    <Controls />
                                </ReactFlow>
                            </Box>
                        )}
                        {error && (
                            <Box my={4}>
                                <CustomAlert
                                    severity="error"
                                    text={
                                        "Error al cargar ramificación de medidores asociados."
                                    }
                                    onClose={() => setError(false)}
                                />
                            </Box>
                        )}
                    </Box>
                </ReactFlowProvider>
            )}
            {loading && (
                <Box
                    my={4}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default SmartbitsFlow;

import { Box, Button, LoadingButton } from "@enerbit/base";
import { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { getEssByMp } from "../../services/mp";

interface Props {
    openRefustigateModal: () => void;
    openRestoreDataModal: () => void;
    meterId: string;
}

const MeterDetailActions = ({
    openRefustigateModal,
    openRestoreDataModal,
    meterId,
}: Props) => {
    const [essId, setEssId] = useState<string>("");
    const [isLoadingEss, setIsLoadingEss] = useState<boolean>(true);
    const goToOrderServices = () => {
        navigateToUrl("/#/orders");
    };

    const goToEss = () => {
        navigateToUrl(`/#/electricity-supply-service/attention/${essId}`);
    };

    const getEss = async () => {
        setIsLoadingEss(true);
        try {
            const ess = await getEssByMp(meterId);
            setEssId(ess);
        } catch (err) {
            return err;
        } finally {
            setIsLoadingEss(false);
        }
    };

    useEffect(() => {
        getEss();
    }, []);

    return (
        <Box
            sx={{
                my: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <LoadingButton
                    onClick={goToEss}
                    sx={{ width: "200px" }}
                    variant="outlined"
                    color="secondary"
                    loading={isLoadingEss}
                    disabled={!essId}
                >
                    Ver servicio de energía
                </LoadingButton>
                <Button
                    onClick={goToOrderServices}
                    sx={{ width: "200px" }}
                    variant="contained"
                    color="secondary"
                >
                    Generar OS
                </Button>
                <Button
                    sx={{ width: "200px" }}
                    variant="contained"
                    color="primary"
                    onClick={openRefustigateModal}
                >
                    Refustigar
                </Button>
                <Button
                    sx={{ width: "200px", color: "#fff" }}
                    variant="contained"
                    color="success"
                    onClick={openRestoreDataModal}
                >
                    Recuperar datos
                </Button>
            </Box>
        </Box>
    );
};

export default MeterDetailActions;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    Device,
    DeviceDetail,
    ListDeviceResponse,
    SBData,
} from "../../models/smartbit";
import { api } from "@enerbit/base";
import { RootState } from "../store";

export const getSmarbitDetail = createAsyncThunk(
    "generalInfo/getSmartbitDetail",
    async (id: string, { rejectWithValue }) => {
        try {
            const sbDetail = await api.get<DeviceDetail>(
                `/verification/smartbits/${id}`,
            );
            const singleDevice = await api.get<Device>(
                `/electricity-supply-service/device/devices/${id}/get-properties-for-panel`,
            );

            const generalInfo: SBData = {
                ip: sbDetail.data.ip,
                fWVersion: sbDetail.data.fw_version,
                imei: sbDetail.data.imei,
                macAddress: sbDetail.data.mac_address,
                operator: sbDetail.data.operator + " " + sbDetail.data.net,
                deviceName: sbDetail.data.name,
                iccid: singleDevice.data.iccid,
                status: singleDevice.data.connection_state,
            };

            return generalInfo;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const listSmartBits = createAsyncThunk(
    "generalInfo/listSmartbits",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        const { currentPage } = state.generalInfo;
        try {
            const res = await api.get<ListDeviceResponse>(
                `/electricity-supply-service/device/devices/operation-properties?page=${currentPage}`,
            );

            return {
                devices: res.data.items,
                currentPage: res.data.page,
                totalPages: res.data.pages,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const searchDevice = createAsyncThunk(
    "generalInfo/searchDevice",
    async (id: string, { rejectWithValue, getState }) => {
        try {
            const res = await api.get<Device>(
                `/electricity-supply-service/device/devices/${id}/operation-properties`,
            );

            return { devices: [res.data] };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

import { decodedJwt } from "@enerbit/base";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ICancelServiceOrder } from "../../../../models/interfaces/IICancelServiceOrder";
import { ItemGetOrders } from "../../../../models/interfaces/ItemGetOrders";
import { StateStorage } from "../../../../models/store/StateStorage";
import { cancelServiceOrder } from "../../../../store/actions/service-orders/service-orders.actions";
import { AppDispatch } from "../../../../store/store";
import CancelServiceOrderConfirmationDialog from "./CancelServiceOrderConfirmationDialog";
import CancelServiceOrderForm from "./CancelServiceOrderForm";

export interface ICancelServiceOrderForm extends ICancelServiceOrder {
  cancellationResponsibility: string;
}

export type ServiceOrderCancellationModalContainerProps = {
  orderItem: ItemGetOrders;
  handleClose?: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "submit"
  ) => void;
};

export const ServiceOrderCancellationModalContainer = ({
  handleClose,
  orderItem,
}: ServiceOrderCancellationModalContainerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    cancellationReasons,
    cancellationResponsibilities,
    loadingCancellationReasons,
  } = useSelector((state: StateStorage) => state.serviceOrders);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const initialValues: ICancelServiceOrderForm = {
    cancellationResponsibility: "",
    order_id: orderItem.id,
    cancel_by: decodedJwt()?.sub || "",
    cancellation_reason_id: "",
    form_data: {
      geolocalization: null,
    },
    observations: "",
    picture_url: null,
  };

  const schemaCancelServiceOrder = yup.object().shape({
    cancellationResponsibility: yup
      .string()
      .required("Responsable es requerido"),
    cancellation_reason_id: yup
      .string()
      .required("Motivo de cancelación es requerido"),
    observations: yup.string().required("Observaciones son requeridas"),
  });

  const onSubmit = () => {
    setConfirmOpen(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schemaCancelServiceOrder,
    onSubmit,
  });

  const handleConfirmSubmit = () => {
    const { cancellationResponsibility, ...formData } = formik.values;
    dispatch(cancelServiceOrder(formData));
    setConfirmOpen(false);
    if (handleClose) {
      handleClose({}, "submit");
    }
  };

  return (
    <>
      <CancelServiceOrderForm
        formik={formik}
        loadingCancellationReasons={loadingCancellationReasons}
        cancellationResponsibilities={cancellationResponsibilities}
        cancellationReasons={cancellationReasons}
      />
      <CancelServiceOrderConfirmationDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmSubmit}
      />
    </>
  );
};

import { api } from "@enerbit/base";
import { getMeterIdBySerial } from "./meters";

export const getMpBySerialId = async (serial: string) => {
    const serialId = await getMeterIdBySerial(serial);

    const { data } = await api.get(
        `/assignees/measurement-point/meter-relations?meter_id=${serialId}`,
    );
    return data.measurement_point_id;
};

export const getLeadByMp = async (serial: string = "") => {
    const mp = await getMpBySerialId(serial);
    const { data } = await api.get(
        `/assignees/leads?measurement_point_id=${mp}`,
    );
    const item = data.items[0];

    const entityData = [
        {
            entityName: "Cliente/Lead",
            section: "lead",
            id: item.id,
            dataToMove: {
                lead_id: item.id as string,
                mp: item.measurement_point_id as string,
            },
            data: [
                {
                    text: "Nombre del cliente",
                    value: item?.display_name || "No registra",
                },
                {
                    text: "Correo electrónico",
                    value: item?.email_from || "No registra",
                },
                {
                    text: "Dirección",
                    value: item?.address || "No registra",
                },
            ],
        },
        {
            section: "lead",
            id: item.id,
            dataToMove: {
                lead_id: item.id as string,
                mp: item.measurement_point_id as string,
            },
            entityName: "Punto de medida",
            data: [
                {
                    text: "ID",
                    value: item.measurement_point_id,
                },
            ],
        },
    ];

    return entityData;
};

export const getMpByAssignee = async (assignee_id: string) => {
    const { data } = await api.get(`/assignees/leads/${assignee_id}`);
    const mp = data.measurement_point_id;
    return mp;
};
